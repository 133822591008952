import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import RenderContentItem from './renderContentItem';

export const DumbRenderGlobalContent = ({ cmsContent = [], placement = 'Main' }) => {
  return (
    <>
      {
        cmsContent.map((item, index) => (
          <RenderContentItem cmsContentItem={item} key={index} placement={placement} />
        ))
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  cmsContent: get(state, 'cms.global'),
});

export default connect(mapStateToProps)(DumbRenderGlobalContent);

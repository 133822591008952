import isEmpty from 'lodash/isEmpty';
import React from 'react';

export function getButtonPropValue(buttonProp, index, desktopButtonProp, inheritedImage) {
  let buttonPropValue = '';
  if (!isEmpty(buttonProp)) {
    buttonPropValue = buttonProp;
  } else if (inheritedImage && desktopButtonProp.length > index
    && !isEmpty(desktopButtonProp[index])) {
    buttonPropValue = desktopButtonProp[index];
  }
  return buttonPropValue;
}

export function getButtonStyleAndLink(buttonOverlay, index, desktopMapData, inheritedImage) {
  let overlayStyle;
  let linkurl;
  let isDesktopInherited;

  if (!isEmpty(buttonOverlay.map)) {
    overlayStyle = {
      top: `${buttonOverlay.map.top}%`,
      left: `${buttonOverlay.map.left}%`,
      maxWidth: `calc(100% - ${buttonOverlay.map.left}%)`,
      maxHeight: `calc(100% - ${buttonOverlay.map.top}%)`,
    };
    linkurl = buttonOverlay.map.linkurl;
  } else if (!isEmpty(desktopMapData) && desktopMapData.length > index
    && inheritedImage && !isEmpty(desktopMapData[index])) {
    overlayStyle = {
      top: `${desktopMapData[index].top}%`,
      left: `${desktopMapData[index].left}%`,
      maxWidth: `calc(100% - ${desktopMapData[index].left}%)`,
      maxHeight: `calc(100% - ${desktopMapData[index].top}%)`,
    };
    linkurl = desktopMapData[index].linkurl;
    isDesktopInherited = inheritedImage;
  } else {
    return false;
  }
  return { overlayStyle, linkurl, isDesktopInherited };
}

export function getDesktopCustomButtonStyles(objData, buttonID) {
  const {
    borderSize,
    borderColor,
    textColor,
    buttonColor,
    hoverBorderSize,
    hoverStateBorderColor,
    hoverStateTextColor,
    hoverStateButtonColor,
  } = objData;

  const styleValue = (propKey, styleKey, prop, sizeOfBorder) => {
    let propValue = '';
    const isBordeSize = propKey === 'borderSize' || propKey === 'hoverBorderSize';
    const isBordeSizeEmpty = isBordeSize && (prop === null || prop === '' || prop === undefined);
    const addPixel = `${isBordeSize ? 'px' : ''}`;
    const isBorderColor = propKey === 'borderColor' || propKey === 'hoverStateBorderColor';
    const isBorderSizeNotEmpty = sizeOfBorder !== null || sizeOfBorder !== '' || sizeOfBorder !== undefined;

    if (prop !== null && prop !== '' && prop !== undefined) {
      propValue = `${styleKey}: ${prop}${addPixel};`;
    } else if (isBorderColor && isBorderSizeNotEmpty) {
      propValue = 'border-color: #000;';
    } else if (isBordeSizeEmpty) {
      propValue = 'border-width: 0px;';
    }
    return propValue;
  };

  const buttonStyle = `@media screen and (min-width: 1025px) {
      #${buttonID} {
        ${styleValue('borderSize', 'border-width', borderSize)}
        ${styleValue('borderColor', 'border-color', borderColor, borderSize)}
        ${styleValue('textColor', 'color', textColor)}
        ${styleValue('buttonColor', 'background-color', buttonColor)}
      }
      #${buttonID}:hover {
        ${styleValue('hoverBorderSize', 'border-width', hoverBorderSize)}
        ${styleValue('hoverStateBorderColor', 'border-color', hoverStateBorderColor, hoverBorderSize)}
        ${styleValue('hoverStateTextColor', 'color', hoverStateTextColor)}
        ${styleValue('hoverStateButtonColor', 'background-color', hoverStateButtonColor)}
      }}`;
  return (<style dangerouslySetInnerHTML={{ __html: buttonStyle }} />);
}

export function getStylePropValue(propString, prop, desktopData, inheritedImage) {
  let propValue = '';
  if (prop !== '' && prop !== null && prop !== undefined) {
    propValue = prop;
  } else if (inheritedImage && desktopData[`${propString}`] !== ''
   && desktopData[`${propString}`] !== null && desktopData[`${propString}`] !== undefined) {
    propValue = desktopData[`${propString}`];
  }

  return propValue;
}

export function getTabMobileCustomButtonStyles(objData, buttonID, desktopData,
  inheritedImage, minWidth, maxWidth) {
  const {
    borderColor,
    borderSize,
    textColor,
    buttonColor,
    hoverBorderSize,
    hoverStateBorderColor,
    hoverStateTextColor,
    hoverStateButtonColor,
  } = objData;

  const styleValue = (propKey, styleKey, prop, sizeOfBorder) => {
    let propValue = '';
    const styleProp = getStylePropValue(propKey, prop, desktopData, inheritedImage);

    const isBorderColor = propKey === 'borderColor' || propKey === 'hoverStateBorderColor';
    const isBorderSizeNotEmpty = sizeOfBorder !== null || sizeOfBorder !== '' || sizeOfBorder !== undefined;

    const isBordeSize = propKey === 'borderSize' || propKey === 'hoverBorderSize';
    const isBordeSizeEmpty = isBordeSize && (styleProp === null || styleProp === '' || styleProp === undefined);
    const addPixel = `${isBordeSize ? 'px' : ''}`;

    if (styleProp !== '' && styleProp !== null && styleProp !== undefined) {
      propValue = `${styleKey}: ${styleProp}${addPixel};`;
    } else if (isBorderColor && isBorderSizeNotEmpty) {
      propValue = 'border-color: #000;';
    } else if (isBordeSizeEmpty) {
      propValue = 'border-width: 0px;';
    }
    return propValue;
  };

  const buttonStyle = `@media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px) {
      #${buttonID} {
        ${styleValue('borderSize', 'border-width', borderSize)}
        ${styleValue('borderColor', 'border-color', borderColor)}
        ${styleValue('textColor', 'color', textColor)}
        ${styleValue('buttonColor', 'background-color', buttonColor, borderSize)}
      }
      #${buttonID}:hover {
        ${styleValue('hoverBorderSize', 'border-width', hoverBorderSize)}
        ${styleValue('hoverStateBorderColor', 'border-color', hoverStateBorderColor, hoverBorderSize)}
        ${styleValue('hoverStateTextColor', 'color', hoverStateTextColor)}
        ${styleValue('hoverStateButtonColor', 'background-color', hoverStateButtonColor)}
      }}`;
  return (<style dangerouslySetInnerHTML={{ __html: buttonStyle }} />);
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import { Minicart } from '@nmg/nmo-minicart';
import MobileMiniCart from './MobileMiniCart/MobileMiniCart';
import DesktopTabletMiniCart from './DesktopTabletMiniCart/DesktopTabletMiniCart';
import './miniCart.scss';
import { getLambdaHost } from '../../../../utilities/amplifyUtils';

const CN = 'miniCartContainer';

const MiniCart = ({
  noOfItemsInCart,
  addToCartV2Toggle,
  dtMinicartToggle,
  clientLocation,
  isNmNewHeader,
}) => {
  const showDtMinicart = addToCartV2Toggle && dtMinicartToggle;
  const [lambdaHost, setLambdaHost] = useState('');

  useEffect(() => {
    if (showDtMinicart) setLambdaHost(getLambdaHost());
  }, [showDtMinicart]);

  return (
    <div
      role="button"
      className={classnames(CN, { [`${CN}--empty`]: noOfItemsInCart === 0 })}
      aria-label="view cart"
    >
      {showDtMinicart ? (
        <Minicart
          itemCount={noOfItemsInCart}
          brand={NMConfig?.BRAND_NAME || 'NM'}
          host={lambdaHost}
          clientLocationZip={clientLocation?.zip}
          isNmNewHeader={isNmNewHeader}
        />
      ) : (
        <>
          <DesktopTabletMiniCart isNmNewHeader={isNmNewHeader} />
          <MobileMiniCart isNmNewHeader={isNmNewHeader} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    noOfItemsInCart: state?.miniCart?.noOfItemsInCart || 0,
    addToCartV2Toggle: state.toggles.ADD_TO_CART_V2 && state?.locale?.countryCode === 'US',
    dtMinicartToggle: state?.toggles?.DT_MINICART,
    clientLocation: get(state, 'api.requestContext.akamaiEdgescape', {}),
  };
};


export default connect(mapStateToProps)(MiniCart);

import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';


const TypeaheadResults = React.memo((props) => {
  const {
    items = [],
    query = '',
    urlPrefix = '',
    handleClickSuggestion = () => null,
    setUtag,
    searchItems,
    storageKey,
    arrowIndex,
  } = props;

  const handleSuggestion = (e, searchItems, item, suggestion) => {
    if (typeof (localStorage) !== 'undefined') {
      const recentSearches = JSON.parse(localStorage.getItem(storageKey)) || [];
      saveToLocalStorage(storageKey, JSON.stringify([suggestion, ...recentSearches]));
    }
    setUtag(e, searchItems);
    handleClickSuggestion(item);
  };

  const sanitizedQuery = query.toLowerCase().replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
  return (
    <div className="custom-typeAheadMenu-menu">
      <ul>
        {
          items.slice(0, 10).map(
            (item, i) => {
              const suggestion = item.parameters?.query || '';
              const re = new RegExp(`(${sanitizedQuery})`, 'gi');
              const matchedSuggestion = suggestion.trim().split(re).map((part, i) => (part === sanitizedQuery ? <span key={i} className="match">{part}</span> : part));
              const parsedUrl = `${urlPrefix + (window?.BR?.mobile?.createURLFromParams(item.toHashParameters()) || '')}&src=suggest`;
              return (
                <li
                  key={`${sanitizedQuery}_${i}`}
                  className={classnames({
                    'hightlight-arrow-updown': arrowIndex === i,
                  })}
                >
                  <Link
                    to={parsedUrl}
                    onClick={(e) => {
                      handleSuggestion(e, searchItems, item, suggestion);
                    }}
                  >
                    <p>{matchedSuggestion}</p>
                  </Link>
                </li>
              );
            }
          )
        }
      </ul>
    </div>
  );
});

export default TypeaheadResults;
TypeaheadResults.displayName = 'TypeaheadResults';

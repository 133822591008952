/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './storyRail.scss';
import { getEditorialStoriesV2 } from 'cms/actions';
import { shouldLoad } from 'universal/http-client';
import RenderLayout from 'cms/components/renderLayout';

const STORY_SUMMARY = 'Editorial-story-summary';

const getIntersectionsLength = (story, currentClassifications) => {
  const storyClassifications = get(story, 'fields.metaData.fields.classifications', []);
  return storyClassifications.filter((item) => currentClassifications.includes(item)).length;
};

const getStoryRec = (stories, newClassificationsToggle) => {
  const stories33Layout = stories.map((story) => {
    const getL1Layouts = get(story, 'fields.l1Layouts', []);
    const storySummary = newClassificationsToggle ? get(story, 'fields.l1Layout') : getL1Layouts.find((elem) => elem.fields?.placement === STORY_SUMMARY);
    const getL2Layouts = get(storySummary, 'fields.l2Layout', []);
    const targetLayout = getL2Layouts.find((elem) => elem?.fields.desktopWidth === '33');
    if (targetLayout) {
      return {
        ...targetLayout,
        contentType: 'l3Layout',
      };
    }
    return null;
  }).filter(Boolean);

  return {
    contentType: 'l2Layout',
    fields: {
      l3Layout: stories33Layout,
    },
  };
};

class StoryRailV2 extends Component {
  UNSAFE_componentWillMount() {
    const { cmsStoriesApi } = this.props;
    if (shouldLoad(cmsStoriesApi)) {
      this.props.dispatch(getEditorialStoriesV2('/editorial'));
    }
  }

  render() {
    const { editorialStories, currentEntry, newClassificationsToggle } = this.props;
    const currentStoryClassifications = get(currentEntry, 'fields.metaData.fields.classifications', []);
    let storiesWithoutCurr;
    if (newClassificationsToggle) {
      const currStoryId = get(currentEntry, 'fields.metaData.sys.id');
      storiesWithoutCurr = editorialStories.filter((story) => {
        return story.fields.metaData.sys.id !== currStoryId;
      });
    } else {
      const currStoryId = get(currentEntry, 'sys.id', '');
      storiesWithoutCurr = editorialStories.filter((story) => story.sys.id !== currStoryId);
    }

    storiesWithoutCurr.sort((story1, story2) => {
      const story1classLength = getIntersectionsLength(story1, currentStoryClassifications);
      const story2classLength = getIntersectionsLength(story2, currentStoryClassifications);

      if (story1classLength > story2classLength) return -1;
      if (story1classLength < story2classLength) return 1;
      return 0;
    });
    const storyRail = getStoryRec(storiesWithoutCurr.slice(0, 3), newClassificationsToggle);

    return (
      <div className="story-rail-container-v2 width-100">
        <div className="title">You May Also Like</div>
        <RenderLayout cmsLayout={storyRail} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cmsStoriesApi: state.api.editorial_stories,
    editorialStories: state.cms.stories,
    currentEntry: state.cms.entries[0],
    newClassificationsToggle: get(state, 'toggles.NEW_CLASSIFICATIONS_MODEL', false),
  };
};

export default connect(mapStateToProps)(StoryRailV2);

import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { dangerousProperty } from 'client-utils/utilities-html-sanitizer';

export default ({ data = {} }) => {
  return !isEmpty(data) ? (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={dangerousProperty(JSON.stringify(data))}
    />
  ) : false;
};

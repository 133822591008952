import React from 'react';
import './CustomArrow.scss';

const CustomArrow = (props) => {
  const {
    className, arrowTop, onClick, style, ariaLabel,
  } = props;
  const ariaDisabled = className.includes('slick-disabled');
  let newStyle = {
    ...style,
  };

  if (arrowTop) {
    newStyle = {
      ...style,
      top: `${arrowTop}px`,
    };
  }

  return (
    // eslint-disable-next-line max-len
    <button className={className} onClick={onClick} style={newStyle} aria-label={ariaLabel} aria-disabled={ariaDisabled} />
  );
};

export default CustomArrow;

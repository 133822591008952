/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './expandingCarousel.scss';
import get from 'lodash/get';
import classnames from 'classnames';
import RenderContentItem from 'cms/components/renderContentItem';
import Carousel from 'cms/components/carousel/carousel';

class ExpandingCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 0,
    };
  }

  hoverHandler = (index) => {
    this.setState({ activeItem: index });
  };

  render() {
    const {
      cmsContentItems,
      isMobilePhone,
      isLazyLoadEnabled,
      trackTags,
    } = this.props;
    if (!cmsContentItems?.length) return null;
    if (isMobilePhone) {
      const additionalSettings = {
        autoplay: false,
        arrows: false,
        dots: false,
        infinite: true,
        centerMode: true,
        centerPadding: '20px',
      };

      return (
        <Carousel
          trackTags={trackTags}
          addSettings={additionalSettings}
          cmsContentItems={cmsContentItems}
        />
      );
    }

    return (
      <div className="expanding-carousel__wrapper">
        {cmsContentItems.map((contentItem, index) => (
          <div
            key={index}
            className={classnames('expanding-carousel__item', {
              active: this.state.activeItem === index,
              lazy: isLazyLoadEnabled,
            })}
            onMouseOver={() => this.hoverHandler(index)}
          >
            <RenderContentItem cmsContentItem={contentItem} trackTags={trackTags} />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    isLazyLoadEnabled: get(state, 'toggles.LAZYLOAD_CMS_CONTENT'),
  };
};

export default connect(mapStateToProps)(ExpandingCarousel);

import get from 'lodash/get';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { ecmGetOverlayClasses, ecmGetTextOverlayStyles, ecmGetPositionedTextStyleMap } from '../../utilities';

const EcmTextPositionedOverlays = ({ dataObj }) => {
  const overlayClasses = ecmGetOverlayClasses(dataObj);

  const shouldInherit = (textOverlayValue) => {
    return !textOverlayValue.textPosition;
  };

  const getDesktopOverlay = (dataObjIndex) => {
    const desktopTextOverlay = get(dataObj, 'desktop', []);
    return desktopTextOverlay[dataObjIndex] ? desktopTextOverlay[dataObjIndex] : null;
  };

  return (
    <div className="ecm-container__textoverimage__textoverlays">
      {
        get(dataObj, 'desktop', []).map((textOverlay, index) => {
          if (!textOverlay.textPosition) return false;
          const overlayStyle = ecmGetPositionedTextStyleMap()[textOverlay.textPosition];
          overlayStyle.width = `${textOverlay.width}%`;
          return (
            <Fragment key={index}>
              <div
                className={
                  classNames(textOverlay.componentId,
                    overlayClasses.desktopOverlayClasses)
                }
                key={index}
                style={overlayStyle}
                dangerouslySetInnerHTML={{ __html: textOverlay.text }}
              />
              {ecmGetTextOverlayStyles(textOverlay)}
            </Fragment>
          );
        })
      }
      {
        get(dataObj, 'tablet', []).map((textOverlay, index) => {
          let textOverlayValue = textOverlay;
          if (shouldInherit(textOverlayValue)) {
            textOverlayValue = getDesktopOverlay(index);
            if (!textOverlayValue.textPosition) return false;
          }
          const overlayStyle = ecmGetPositionedTextStyleMap()[textOverlayValue.textPosition];
          overlayStyle.width = `${textOverlayValue.width}%`;
          return (
            <Fragment key={index}>
              <div
                className={
                  classNames(textOverlayValue.componentId,
                    overlayClasses.tabletOverlayClasses)
                }
                key={index}
                style={overlayStyle}
                dangerouslySetInnerHTML={{ __html: textOverlayValue.text }}
              />
              {ecmGetTextOverlayStyles(textOverlayValue)}
            </Fragment>
          );
        })
      }
      {
        get(dataObj, 'mobile', []).map((textOverlay, index) => {
          let textOverlayValue = textOverlay;
          if (shouldInherit(textOverlayValue)) {
            textOverlayValue = getDesktopOverlay(index);
            if (shouldInherit(textOverlayValue)) {
              textOverlayValue = get(dataObj, 'tablet', [])[index];
            }
            if (!textOverlayValue.textPosition) return false;
          }
          const overlayStyle = ecmGetPositionedTextStyleMap()[textOverlayValue.textPosition];
          overlayStyle.width = `${textOverlayValue.width}%`;
          return (
            <Fragment key={index}>
              <div
                className={
                  classNames(textOverlayValue.componentId,
                    overlayClasses.mobileOverlayClasses)
                }
                key={index}
                style={overlayStyle}
                dangerouslySetInnerHTML={{ __html: textOverlayValue.text }}
              />
              {ecmGetTextOverlayStyles(textOverlayValue)}
            </Fragment>
          );
        })
      }
    </div>
  );
};

export default EcmTextPositionedOverlays;

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import ConnectedAnchorWithModalKeysIfImageMapTargetIsTop from './components/AnchorWithModalKeysIfImageMapTargetIsTop';

const EcmImageMap = ({ picture }) => {
  const ecmImages = get(picture, 'properties.images', '');
  let desktopClasses = 'ecm-container__imagemap';
  if (!isEmpty(get(ecmImages, 'tablet.imagesrc'))) desktopClasses += ' hide-on-tablet';
  if (!isEmpty(get(ecmImages, 'mobile.imagesrc'))) desktopClasses += ' hide-on-mobile';
  let tabletClasses = 'ecm-container__imagemap';
  if (!isEmpty(get(ecmImages, 'desktop.imagesrc'))) tabletClasses += ' hide-on-desktop';
  if (!isEmpty(get(ecmImages, 'mobile.imagesrc'))) tabletClasses += ' hide-on-mobile';
  let mobileClasses = 'ecm-container__imagemap';
  if (!isEmpty(get(ecmImages, 'desktop.imagesrc'))) mobileClasses += ' hide-on-desktop';
  if (!isEmpty(get(ecmImages, 'tablet.imagesrc')) || !isEmpty(get(ecmImages, 'desktop.imagesrc'))) mobileClasses += ' hide-on-tablet';
  return (
    <div className="ecm-container__imagemaps">
      {!isEmpty(get(ecmImages, 'desktop.imagesrc'))
          && get(ecmImages, 'desktop.map', []).map((imageMap, index) => {
            const mapStyle = {
              top: `${imageMap.top}%`,
              left: `${imageMap.left}%`,
              width: `${imageMap.width}%`,
              height: `${imageMap.height}%`,
            };
            return (
              <ConnectedAnchorWithModalKeysIfImageMapTargetIsTop
                imageMap={imageMap}
                breakPointClasses={desktopClasses}
                mapStyle={mapStyle}
                key={index}
              />
            );
          })
        }
      {!isEmpty(get(ecmImages, 'tablet.imagesrc'))
          && get(ecmImages, 'tablet.map', []).map((imageMap, index) => {
            const mapStyle = {
              top: `${imageMap.top}%`,
              left: `${imageMap.left}%`,
              width: `${imageMap.width}%`,
              height: `${imageMap.height}%`,
            };
            return (
              <ConnectedAnchorWithModalKeysIfImageMapTargetIsTop
                imageMap={imageMap}
                breakPointClasses={tabletClasses}
                mapStyle={mapStyle}
                key={index}
              />
            );
          })
        }
      {!isEmpty(get(ecmImages, 'mobile.imagesrc'))
          && get(ecmImages, 'mobile.map', []).map((imageMap, index) => {
            const mapStyle = {
              top: `${imageMap.top}%`,
              left: `${imageMap.left}%`,
              width: `${imageMap.width}%`,
              height: `${imageMap.height}%`,
            };
            return (
              <ConnectedAnchorWithModalKeysIfImageMapTargetIsTop
                imageMap={imageMap}
                breakPointClasses={mobileClasses}
                mapStyle={mapStyle}
                key={index}
              />
            );
          })
        }
    </div>
  );
};

export default EcmImageMap;

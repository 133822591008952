import get from 'lodash/get';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

export function Quiz(props) {
  const { cmsContentItem = {} } = props;
  const { identifier } = get(cmsContentItem, 'fields', {});

  useEffect(() => {
    const element = document.getElementsByClassName('quiz-container')[0];
    const script = document.createElement('script');
    script.src = `https://api-barracuda.zoovu.com/api/v1/advisors/${identifier}/js-loader?locale=en-US`;
    script.async = true;
    if (element) {
      element.appendChild(script);
    }
    return () => {
      document.body.removeChild(script); // eslint-disable-line compat/compat
    };
  }, []);

  return (
    <div className="quiz-container">
      <div id="zoovu-assistant" />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
});

export default connect(mapStateToProps)(Quiz);

import React from 'react';
import classNames from 'classnames';

const JustForYou = () => {
  const classes = classNames(
    'grid-100 tablet-grid-100 mobile-grid-100 cms-container__recs cms-container__thirdparty jfy-five-slots'
  );
  return <div className={classes} />;
};

export default JustForYou;

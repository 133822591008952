
const [SILO_LEFT, SILO_RIGHT] = ['SILO_LEFT', 'SILO_RIGHT'];
const [NAV_LEFT, NAV_RIGHT] = ['NAV_LEFT', 'NAV_RIGHT'];
const [CENTER, FULL_WIDTH] = ['CENTER', 'FULL_WIDTH'];

export function calculateCenteredAlignments(positioning) {
  const {
    drawerWidth, navBarRight, navBarLeft, siloLeft, siloRight,
  } = positioning;

  const siloWidth = siloRight - siloLeft;
  const centerSiloDrawerOffset = (drawerWidth - siloWidth) / 2;

  const isSpaceOnLeft = navBarLeft <= siloLeft - centerSiloDrawerOffset;
  const isSpaceOnRight = siloRight + centerSiloDrawerOffset <= navBarRight;
  const isSpaceOnRightWhenLeftAligned = navBarLeft + drawerWidth <= navBarRight;
  const isSpaceOnLeftWhenRightAligned = navBarLeft <= navBarRight - drawerWidth;

  if (isSpaceOnLeft && isSpaceOnRight) {
    return CENTER;
  }
  if (isSpaceOnRight && isSpaceOnRightWhenLeftAligned) {
    // drawer flush against navLeft
    return NAV_LEFT;
  }
  if (isSpaceOnLeft && isSpaceOnLeftWhenRightAligned) {
    // drawer flush against navRight
    return NAV_RIGHT;
  }
  return FULL_WIDTH;
}

export function calculateDrawerAlignment(positioning) {
  const {
    drawerWidth, navBarRight, navBarLeft, siloLeft, siloRight,
  } = positioning;
  if (siloLeft + drawerWidth <= navBarRight) {
    return SILO_LEFT;
  }
  if (navBarLeft <= siloRight - drawerWidth) {
    return SILO_RIGHT;
  }
  return calculateCenteredAlignments(positioning);
}

export function determineAlignmentStyle(positioning) {
  const {
    drawerWidth, navBarRight, navBarLeft, siloLeft, siloRight,
  } = positioning;

  const siloWidth = siloRight - siloLeft;
  const centerSiloDrawerOffset = (drawerWidth - siloWidth) / 2;

  const windowWidth = navBarLeft + navBarRight;

  const drawerAlignment = calculateDrawerAlignment(positioning);

  // Streeter - silo_right's border alignment is off by 1 pixel without this.
  const siloBorderOffset = 1;

  let left;
  let right;

  switch (drawerAlignment) {
    case (SILO_LEFT):
      left = 'auto';
      right = 'auto';
      break;
    case (SILO_RIGHT):
      left = 'auto';
      right = (windowWidth - siloRight) + siloBorderOffset;
      break;
    case (CENTER):
      left = siloLeft - centerSiloDrawerOffset;
      right = 'auto';
      break;
    case (NAV_RIGHT):
      left = 'auto';
      right = navBarLeft;
      break;
    case (FULL_WIDTH):
      left = navBarLeft;
      right = navBarLeft;
      break;
    case (NAV_LEFT):
    default:
      left = navBarLeft;
      right = 'auto';
  }

  return {
    left,
    right,
  };
}

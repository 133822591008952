import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import ImageAsset from '../image/imageAsset';
// eslint-disable-next-line import/no-cycle
import VideoAsset from '../video/videoAsset';
// eslint-disable-next-line import/no-cycle
import RichText from '../richText/richText';
// eslint-disable-next-line import/no-cycle
import { calculateTextOverlayPositionProps, concatTrackTagsObjects, getTrackingTags } from '../utils';
import './textOverImage.scss';

const mobileViewTextPosition = 'Below';

export function DumbTextOverImage({
  cmsContentItem = {},
  isMobilePhone,
  isDesktop,
  trackTags: propsTrackTags = {},
  neverLazyload = false,
}) {
  const {
    image,
    text,
    desktopTextPosition,
    desktopTextWidth,
    desktopTextMarginHorizontal,
    desktopTextMarginVertical,
    tabletTextPosition,
    tabletTextWidth,
    tabletTextMarginHorizontal,
    tabletTextMarginVertical,
    mobileTextPosition,
    mobileTextWidth,
    mobileTextMarginHorizontal,
    mobileTextMarginVertical,
    trackingTags: ownTrackTags = [],
    enableHoverLogic = false,
  } = cmsContentItem?.fields;

  if (!image) {
    return null;
  }

  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const hasTabletProps = tabletTextPosition;
  const hasMobileProps = isMobilePhone && mobileTextPosition;

  const desktopProps = {
    position: desktopTextPosition,
    marginHorizontal: desktopTextMarginHorizontal,
    marginVertical: desktopTextMarginVertical,
    width: desktopTextWidth,
  };

  const tabletProps = hasTabletProps
    ? {
      position: tabletTextPosition,
      marginHorizontal: tabletTextMarginHorizontal,
      marginVertical: tabletTextMarginVertical,
      width: tabletTextWidth || desktopTextWidth,
    }
    : desktopProps;

  const mobileProps = hasMobileProps
    ? {
      position: mobileTextPosition,
      marginHorizontal: mobileTextMarginHorizontal,
      marginVertical: mobileTextMarginVertical,
      width: mobileTextWidth || tabletTextWidth,
    }
    : tabletProps;

  // eslint-disable-next-line object-curly-newline
  const overlayPropsToCss = ({ width, position, marginHorizontal, marginVertical }) => {
    return Object.entries({
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto',
      width: `${width}%`,
      ...calculateTextOverlayPositionProps(position, marginHorizontal, marginVertical),
    })
      .map(([k, v]) => `${k}:${v};`)
      .join('');
  };

  const assetClassName = `cms-asset-${cmsContentItem?.sys?.id}`;

  const style = (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        .${assetClassName} .cms-toi-text-overlay {
          ${overlayPropsToCss(mobileProps)}
        }

        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .${assetClassName} .cms-toi-text-overlay {
            ${overlayPropsToCss(tabletProps)}
          }
        }

        @media screen and (min-width: 1025px) {
          .${assetClassName} .cms-toi-text-overlay {
            ${overlayPropsToCss(desktopProps)};
          }
        }`,
      }}
    />
  );

  return (
    <div
      className={classNames(assetClassName, 'cms-text-over-image', {
        'enable-hover-logic': enableHoverLogic && isDesktop,
        'enable-hovered-view': enableHoverLogic && !isDesktop,
      })}
    >
      {style}
      <div className="cms-toi-image-container">
        {image && image.contentType === 'videoContentAsset'
          ? <VideoAsset cmsContentItem={image} />
          : (
            <ImageAsset
              cmsContentItem={image}
              trackTags={combinedTrackTags}
              neverLazyload={neverLazyload}
            />
          )
        }
      </div>
      <div
        className={(mobileTextPosition === mobileViewTextPosition && isMobilePhone) ? 'cms-toi-text' : 'cms-toi-text-overlay'}
      >
        <RichText cmsContentItem={text} trackTags={combinedTrackTags} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  isDesktop: get(state, 'device.isDesktop'),
});

export default connect(mapStateToProps)(DumbTextOverImage);

import React from 'react';
import { normalizeString } from '../OrderStatusLineItem/orderStatusUtils';

function MyAccountNavOld({
  name,
  active,
  specialOffersToggle,
  myStylePrefToggle,
  dtMyStoreToggle,
  unifiedPrefToggle,
}) {
  const myStoreHref = dtMyStoreToggle
    ? '/my/favorites/store'
    : '/myfavorites/myFavorites.jsp?view=favs&forOmniLN=1';
  return (
    <ul className="nav">
        <li>
          <span className="myGlobalSignedIn" tabIndex="0">
            {name
              ? (
                <>
                  {'Hello '}
                  {normalizeString(name)}
                &nbsp;
                </>
              )
              : (
                <a href="/login">
                Sign In / Register
                </a>
              )}
          </span>
        </li>
        <li className="myFav">
          <a
            id="myFavNavFI"
            className={active === 'myFavNavFI' ? 'active' : 'changeOver'}
            href="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1"
          >
            Favorite Items
          </a>
        </li>
        {
          myStylePrefToggle
            ? (
              <li className="myFav">
                <a id="myFavNavMSP" href="/my/preferences/stylepreferences" className={active === 'myFavNavMSP' ? 'active' : 'changeOver'}>My Style Preferences</a>
              </li>
            ) : (
              <li className="myFav">
                <a id="myFavNavFD" className="changeOver" href="/myfavorites/myFavoriteDesigners.jsp?view=favd&forOmniLN=1">Favorite Designers</a>
              </li>
            )
        }
        <li className={myStylePrefToggle ? 'myFavStore' : 'myFav'}>
          <a id="myFavNavFS" className="changeOver" href={myStoreHref}>My Store</a>
        </li>
        {
          !myStylePrefToggle
          && (
          <li className="myFavSizes">
            <a id="myFavNavFSI" className="changeOver" href="/myfavorites/myFavoritesSizes.jsp?view=favsizes">Favorite Sizes</a>
          </li>
          )
        }
        <li className="myFav">
          <a
            id="myAcctNavAO"
            className={active === 'myAcctNavAO' ? 'active' : 'changeOver'}
            href={active === 'myAcctNavAO' ? '/accountoverview' : `${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav`}
          >
            Account Overview
          </a>
        </li>
        <li className="myFav">
          <a
            id="myAcctNavOH"
            className={active === 'myAcctNavOH' ? 'active' : 'changeOver'}
            href={active === 'myAcctNavOH' || '/order-history'}
          >
            Order History
          </a>
        </li>
        <li className="myFav">
          <a id="myAcctNavAB" className="changeOver" href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=address&forOmniLN=1`}>Address Book</a>
        </li>
        <li className="myFav">
          <a id="myAcctNavPI" className="changeOver" href={`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav&view=pay&forOmniLN=1`}>Payment Information</a>
        </li>
        {
          specialOffersToggle
          && (
            <li className="myFav">
              <a id="myNMSpecialOffers" className={active === 'myNMSpecialOffers' ? 'active' : 'changeOver'} href="/specialoffers">Special Offers</a>
            </li>
          )
        }
        {unifiedPrefToggle && name && (
        <li className={'myFavStore'}>
          <a
            id="myCommPref"
            className="changeOver"
            href={'/my/preferences/communication'}
          >
            Communication Preferences
          </a>
        </li>
        )}
        <li className="myFav">
          <a id="myNMCreditCard" className="changeOver" href="/services/NMCreditCard">NM Credit Card</a>
        </li>
    </ul>
  );
}

export default MyAccountNavOld;

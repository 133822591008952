import React, { PureComponent } from 'react';
import classnames from 'classnames';
import arrowRight from 'assets/images/arrow-right.svg';
import { isGiftCategory, isSaleSiloInRed } from 'client/utilities/utilities-navigation';

import './MenuItem.scss';

export class MenuItem extends PureComponent {
  constructor(props) {
    super(props);
    this.clickHandlerImpl = this.clickHandlerImpl.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
  }

  handleCategoryClick = (urlToGo) => {
    window.sessionStorage.setItem('plpScrollPosition', 0);
    window.location.href = urlToGo;
  }

  clickHandlerImpl() {
    this.props.clickHandler(this.props.itemIdx);
  }

  render() {
    const {
      name,
      defaultNavOption = false,
      lastDefaultCategory = false,
      redTextFlag,
      isHighlighted,
      holdingContextToggle,
      giftFinderToggle,
      holidaySiloToggle,
      saleSiloAbTestToggle,
      bfSaleSiloAbTestToggle,
    } = this.props;
    const isHolidaySilo = isGiftCategory({ name }, holidaySiloToggle);
    const baseItemClass = `mobilenav__menuitem ${isHolidaySilo ? 'mobilenav__holiday_silo ' : ''}`;
    let menuItemClass = baseItemClass;
    if (redTextFlag) {
      menuItemClass = `${baseItemClass}red-text`;
    }

    if (isHighlighted && holdingContextToggle) {
      menuItemClass = `${baseItemClass}highlighted`;
    }

    if (this.props.numOfSubcategories > 0) {
      return (
        <li
          className={classnames(menuItemClass, {
            // eslint-disable-next-line max-len
            mobilenav__sale_silo: isSaleSiloInRed({ name }, saleSiloAbTestToggle, bfSaleSiloAbTestToggle),
          })}
          id={this.props.id}
          order={this.props.order}
          onClick={this.clickHandlerImpl}
        >
          <div role="button">
            {this.props.name}
            {this.props.textAdornment && <span className="mobilenav__menuitem-adornment">{this.props.textAdornment}</span>}
          </div>
          <img alt="" src={arrowRight} className="icon" />
        </li>
      );
    }

    return (
      <li
        className={classnames(menuItemClass, {
          'highlight-end-category': defaultNavOption,
          'show-end-category': lastDefaultCategory,
          'grey-top': giftFinderToggle,
        })}
        id={this.props.id}
        order={this.props.order}
      >
        {
          this.props.isPLPScrollPositionOn
            ? (
              <div onClick={() => { this.handleCategoryClick(this.props.url); }} role="button">
                {name !== undefined ? name : this.props.children}
                {this.props.textAdornment && <span className="mobilenav__menuitem-adornment">{this.props.textAdornment}</span>}
              </div>
            )
            : (
              <a href={this.props.url} role="button">
                {name !== undefined ? name : this.props.children}
                {this.props.textAdornment && <span className="mobilenav__menuitem-adornment">{this.props.textAdornment}</span>}
              </a>
            )
        }
      </li>
    );
  }
}

/* eslint import/no-cycle: 0 */
import get from 'lodash/get';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { getCaptionStyleProps } from 'cms/components/utils';
import Caption from 'cms/components/caption/caption';
import BadgeAsset from 'cms/components/badge/badgesAsset';
import './videoAsset.scss';
import { InView } from 'react-intersection-observer';

const CAPTION_MARGIN = '20';

export function DumbVideoAsset(props) {
  const {
    cmsContentItem = {},
    isMobilePhone,
    enableAutoPlay,
    lazyloadCmsContent,
  } = props;

  const {
    source,
    mobileSource,
    poster,
    mobilePoster,
    autoPlay,
    loop,
    mute,
    hideControls,
    caption,
    badges,
    captionBadge: [{ url: captionBadgeSrc } = {}] = [],
    credit,
    desktopCaptionColor,
    desktopCaptionPosition,
    mobileCaptionColor,
    mobileCaptionPosition,
    lazyloadVideo = true,
    videoUpload: [{ secure_url: videoUploadSource } = {}] = [],
    posterUpload: [{ secure_url: videoUploadPoster } = {}] = [],
    mobileVideoUpload: [{ secure_url: mobileVideoUploadSource } = {}] = [],
    mobilePosterUpload: [{ secure_url: mobileVideoUploadPoster } = {}] = [],
  } = cmsContentItem?.fields;

  const enableVideoAutoplay = enableAutoPlay && autoPlay;
  const videoSrc = videoUploadSource || source;
  const videoPoster = videoUploadPoster || poster;
  const mobileVideoSrc = mobileVideoUploadSource || mobileSource || videoSrc;
  const mobileVideoPoster = mobileVideoUploadPoster || mobilePoster;

  const renderBadges = (badges) => {
    return badges.map((badge, index) => <BadgeAsset cmsContentItem={badge} key={index} />);
  };

  const renderCaption = (position, color, isMobile = false) => (position && color) && (
    <Caption
      style={getCaptionStyleProps(position, color, CAPTION_MARGIN, CAPTION_MARGIN, true)}
      caption={caption}
      credit={credit}
      isMobile={isMobile}
      badgeSrc={captionBadgeSrc}
    />
  );

  const videoSettings = {
    className: 'video-content-asset',
    controls: !hideControls,
    poster: isMobilePhone ? mobileVideoPoster : videoPoster,
    loop,
    muted: mute || enableVideoAutoplay,
    autoPlay: enableVideoAutoplay && (!lazyloadVideo || !lazyloadCmsContent),
    src: isMobilePhone ? mobileVideoSrc : videoSrc,
    playsInline: true,
  };

  const videoRef = useRef();

  const handleInView = (inView) => {
    const { node } = videoRef.current;
    if (inView) {
      node?.play?.();
    } else {
      node?.pause?.();
    }
  };

  return (
    <div className="video-content-asset-container">
      {lazyloadCmsContent && lazyloadVideo ? (
        <InView
          tag="video"
          rootMargin="100px"
          onChange={(inView) => enableVideoAutoplay && handleInView(inView)}
          {...videoSettings}
          preload="none"
          ref={videoRef}
        >
          Your browser does not support the video tag.
        </InView>
      ) : (
        <video {...videoSettings}>
          Your browser does not support the video tag.
        </video>
      )}
      {renderCaption(desktopCaptionPosition, desktopCaptionColor)}
      {renderCaption(
        mobileCaptionPosition || desktopCaptionPosition,
        mobileCaptionColor || desktopCaptionColor,
        true
      )}
      {badges && renderBadges(badges)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  enableAutoPlay: get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true',
  lazyloadCmsContent: state.toggles.LAZYLOAD_CMS_CONTENT,
});

export default connect(mapStateToProps)(DumbVideoAsset);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { getSiteNavigationElement } from 'client-utils/utilities-linked-data';
import { ECMSlots } from 'ecm/constants';
import LinkedData from 'shared/components/LinkedData/linkedData';
import { getECMRequest } from 'ecm/actions/actions-ecmcontent';
import { updateNavLeftRight } from '../actions-navigation';
import Drawer from './Drawer/drawer';
import './navBar.scss';

export class NavBar extends Component {
  componentDidMount() {
    if (this.props.ecmPreview && this.props.drawerAemInContextPreviewToggle) {
      if (shouldLoad(this.props.ecmApi)) {
        const categoryIds = [...this.props.navigationSilos.map((silo) => silo.id)].join(',');
        this.props.getECMRequest(ECMSlots.DRAWER_AEM_ASSET, '', categoryIds);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.componentInFocus.match(/(drawer)/)) {
      this.props.updateNavLeftRight(this.navBar);
    }
    const retVal = this.props.navigationSilos !== nextProps.navigationSilos;

    return retVal;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isDomLoadComplete !== nextProps.isDomLoadComplete) {
      if (nextProps.isDomLoadComplete && window.picturefill) window.picturefill();
    }
  }

  render() {
    const {
      navigationSilos,
      pageId,
      globalNavigationUpdate,
      isDomestic,
      centerTopNav,
    } = this.props;
    return (
      <nav
        id="silo-navigation"
        className={classNames(
          'silo-nav',
          { 'silo-drawer-full-width': this.props.isSiloDrawerFullWidth },
          { 'delay-hover-on-silos': this.props.delayHoverOnSilos },
          { 'nav-spacing': this.props.giftSiloToggle },
          {
            'silo-link-abt-nav-update':
              isDomestic && Boolean(globalNavigationUpdate),
          },
          { 'center-top-nav': centerTopNav }
        )}
        ref={(ref) => {
          this.navBar = ref;
        }}
      >
        <ul>
          {navigationSilos.map((silo, index) => {
            // eslint-disable-next-line max-len
            return (
              <li key={index}>
                <Drawer silo={silo} router={this.props.router} />
              </li>
            );
          })}
        </ul>
        <LinkedData data={getSiteNavigationElement(navigationSilos, pageId)} />
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  ecmApi: state.api[`ecm${ECMSlots.DRAWER_AEM_ASSET.contentId.toLowerCase()}`],
  navigationSilos: state.navigation.silos,
  componentInFocus: state.app.componentInFocus,
  isSiloDrawerFullWidth: get(state, 'toggles.SILO_DRAWER_FULL_WIDTH', false),
  giftSiloToggle: state.toggles.GIFT_SILO_IMAGE,
  isDomLoadComplete: get(state, 'page.isDomLoadComplete', false),
  delayHoverOnSilos: get(state, 'toggles.DELAY_HOVER_ON_SILOS', false),
  drawerAemInContextPreviewToggle: get(state, 'toggles.DRAWER_AEM_IN_CONTEXT_PREVIEW', false),
  ecmPreview: get(state, 'api.requestContext.ECMPreview', ''),
  pageId: get(state, 'page.pageId', ''),
  globalNavigationUpdate: get(state, 'toggles.GLOBAL_NAV_UPDATE', false),
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  centerTopNav: get(state, 'toggles.TOP_NAV_CENTER', false) && get(state, 'locale.countryCode', 'US') === 'US',
});

const mapDispatchToProps = ({ updateNavLeftRight, getECMRequest });

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);

import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import './MyAccountLeftNav.scss';
import MyAccountNavOld from './MyAccountNavOld';
import MyAccountNavNew from './MyAccountNavNew';

const MyAccountLeftNav = ({
  name,
  active,
  specialOffersToggle,
  myStylePrefToggle,
  dtMyStoreToggle,
  NMO_LOYALTY_NEW,
  UNIFIED_PREFERENCES,
}) => {
  return (
    <div className="nav-links">
      {NMO_LOYALTY_NEW ? (
        <MyAccountNavNew
          name={name}
          active={active}
          myStylePrefToggle={myStylePrefToggle}
          dtMyStoreToggle={dtMyStoreToggle}
          unifiedPrefToggle={UNIFIED_PREFERENCES}
        />
      ) : (
        <MyAccountNavOld
          name={name}
          active={active}
          specialOffersToggle={specialOffersToggle}
          myStylePrefToggle={myStylePrefToggle}
          dtMyStoreToggle={dtMyStoreToggle}
          unifiedPrefToggle={UNIFIED_PREFERENCES}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    specialOffersToggle: get(
      state,
      'toggles.WKY_LINK_TO_SPECIAL_OFFERS_PAGE',
      false
    ),
    myStylePrefToggle: state?.toggles?.MY_STYLE_PREFERENCES,
    dtMyStoreToggle: state?.toggles?.DT_MY_STORE,
    NMO_LOYALTY_NEW: state?.toggles?.NMO_LOYALTY_NEW,
    UNIFIED_PREFERENCES: state?.toggles?.UNIFIED_PREFERENCES,
  };
};

export default connect(mapStateToProps)(MyAccountLeftNav);

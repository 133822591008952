import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

class ScreenReaderAnnouncer extends Component {
  constructor() {
    super();
    this.state = { message: undefined };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.message === nextProps.message) {
      const newMessage = `${nextProps.message}\u00A0`;
      this.setState({
        message: newMessage,
        toggle: true,
      });
    } else {
      this.setState({
        message: nextProps.message,
        toggle: false,
      });
    }
  }

  render() {
    return (
      <Fragment>
        <div
          aria-atomic="true"
          aria-live="assertive"
          className="sr-only"
          id="screenReaderAlert"
          tabIndex="-1"
        />
        <div
          aria-atomic="true"
          aria-live="assertive"
          className="sr-only"
          id="screenReaderError"
          tabIndex="-1"
        >
          { this.state.message && this.state.toggle
            ? (
              <p aria-atomic="true" aria-live="assertive">
                {this.state.message}
              </p>
            )
            : (this.state.message)
          }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.screenReader.message,
    messageNumber: state.screenReader.messageNumber,
  };
};

export default connect(mapStateToProps)(ScreenReaderAnnouncer);

/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Slider from 'react-slick';
import ServiceItem from './serviceItem';
import './serviceComponent.scss';
import '../carousel/carousel.scss';

function ServiceComponent(props) {
  const { isMobilePhone } = props;
  const cmsContent = get(props.cmsContentItem, 'fields', {});
  const { paddingBottom, paddingTop } = cmsContent;
  const style = { paddingBottom, paddingTop };
  const CN = 'carousel';

  const sliderSettings = {
    autoplaySpeed: 3000,
    autoplay: false,
    className: CN,
    dotsClass: `${CN}__indicators-group`,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
    speed: 300,
  };

  const services = [];
  Object.keys(cmsContent).forEach((key) => {
    if (key.indexOf('service') >= 0) services.push(cmsContent[key]);
  });
  return (
    <div className="layout-center serviceContainer" style={style}>
      {isMobilePhone && (
      <Slider {...sliderSettings}>
        {services.map((service, idx) => (
          service && <ServiceItem cmsContent={service} isMobilePhone={isMobilePhone} key={idx} />
        ))}
      </Slider>
      )}
      {!isMobilePhone && (
      <div className="serviceComponent">
        {services.map((service, idx) => (
          service && <ServiceItem cmsContent={service} isMobilePhone={isMobilePhone} index={idx} key={idx} />
        ))}
      </div>
      )
            }
    </div>
  );
}
const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
});

export default connect(mapStateToProps)(ServiceComponent);

/* eslint import/no-cycle: 0 */
import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { Tabs, Tab } from '@material-ui/core';
import ProductCarouselAsset from './productCarousel';
import './MultiProductCarousel.scss';

function MultiProductCarousel({ multiCarousel, cmsContentId }) {
  const [active, setActive] = useState(null);
  const [hideStatus, setHideStatus] = useState('hide');

  useEffect(() => {
    if (window.performance?.navigation?.type === 2) {
      setActive(Number(window.sessionStorage.getItem(cmsContentId)) || 0);
    } else {
      setActive(0);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => { setHideStatus('show'); }, 100);
  }, [hideStatus]);

  const tabs = multiCarousel.map(({ fields }) => fields.title);

  const handleChange = (event, newValue) => {
    setActive(newValue);
    window.sessionStorage.setItem(cmsContentId, newValue);
    setHideStatus('hide');
  };

  const renderTabs = () => {
    return (
      <div className="tabs-bar">
        <div className="tabs-wrapper">
          <Tabs
            classes={{
              root: 'root',
              scroller: 'scroller',
              indicator: 'indicator',
            }}
            value={active}
            onChange={handleChange}
          >
            {active !== null && tabs.map((title, index) => {
              return (
                <Tab
                  disableRipple
                  disableFocusRipple
                  value={index}
                  label={title}
                  classes={{
                    root: 'tab-root',
                    wrapper: 'tab-wrapper',
                  }}
                />
              );
            })}
          </Tabs>
        </div>
      </div>
    );
  };

  const renderMultiCarousel = useMemo(() => {
    return (
      multiCarousel.map((carousel, index) => {
        const activeClass = active === index ? 'active' : 'hide';
        return (
          <div key={`tab${index}`} className={classnames('tab_content', activeClass)}>
            <ProductCarouselAsset key={index} cmsContentItem={carousel} hideStatus={hideStatus} />
          </div>
        );
      })
    );
  }, [hideStatus, active]);

  return (
    <div className="multi-carousel">
      {renderTabs()}
      {renderMultiCarousel}
    </div>
  );
}

export default MultiProductCarousel;

const DEFAULT_VERTICAL_OVERLAP = -10;
const DEFAULT_HORIZONTAL_OVERLAP = -20;

const OVERLAP_TOP = 'Overlap-top';
const OVERLAP_BOTTOM = 'Overlap-bottom';
const OVERLAP_LEFT = 'Overlap-left';
const OVERLAP_RIGHT = 'Overlap-right';

export const FOREGROUND_COMPONENTS = {
  richTextContentAsset: 'text-overlap',
  textOverImageContentAsset: 'img-over-graphic-overlap',
  videoContentAsset: 'video-overlap',
};

export const detectMobilePosition = (originalPosition) => {
  if (
    originalPosition === OVERLAP_TOP
    || originalPosition === OVERLAP_BOTTOM
  ) {
    return originalPosition;
  }
  return originalPosition === OVERLAP_LEFT ? OVERLAP_TOP : OVERLAP_BOTTOM;
};

export const getMarginValue = (position, vPercent, hPercent, isMobile) => {
  const hp = !isNaN(hPercent) && hPercent !== '' ? -hPercent : DEFAULT_HORIZONTAL_OVERLAP;
  let vp = !isNaN(vPercent) && vPercent !== '' ? -vPercent : DEFAULT_VERTICAL_OVERLAP;

  if (isMobile) {
    vp = hp;
  }

  switch (position) {
    case OVERLAP_TOP:
      return { marginBottom: `${vp}%` };
    case OVERLAP_BOTTOM:
      return { marginTop: `${vp}%` };
    case OVERLAP_RIGHT:
      return { marginLeft: `${hp}%` };
    case OVERLAP_LEFT:
      return { marginRight: `${hp}%` };
    default:
      return {};
  }
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import { localStorage } from 'window-or-global';
import AffirmScript from 'client/common/components/AffirmScript/index';
import MiniCartContent from '../MiniCartContent/MiniCartContent';
import MiniCartErrorModal from '../MiniCartErrorModal/miniCartErrorModal';
import {
  showMiniCart,
  showMiniCartV2,
  startMiniCartCloseAnimation,
  startTimer,
  showInternationalMiniCart,
  updateMiniCartItemCount,
} from '../miniCart-actions';
import './desktopTabletMiniCart.scss';
import './miniCartATGStyles.scss';

const DesktopTabletMiniCart = (props) => {
  const {
    cartInfo,
    noOfItemsInCart,
    currentFocusedComponent,
    timerId,
    showMiniCart,
    showMiniCartV2,
    showInternationalMiniCart,
    startMiniCartCloseAnimation,
    animateClosed,
    startTimer,
    cartOpened,
    isSimplifiedminicart,
    affirmToggle = false,
    isDomestic,
    internationalToggle,
    updateMiniCartItemCount,
    currencyCode,
    countryCode,
    addToCartV2Toggle,
    securityStatus,
    clientLocation,
    isNmNewHeader = false,
  } = props;

  let checkoutUrl = (addToCartV2Toggle
    ? NMConfig.CHECKOUT_PAGE_URL_DT
    : NMConfig.CHECKOUT_PAGE_URL);
  if (securityStatus && securityStatus.toLowerCase() === 'authenticated') {
    checkoutUrl = addToCartV2Toggle
      ? NMConfig.ORDER_REVIEW_URL_DT
      : NMConfig.ORDER_REVIEW_URL;
  }
  const showMiniCartAction = addToCartV2Toggle ? showMiniCartV2 : showMiniCart;
  const isInFocus = (currentFocusedComponent === '#miniCartContainer');
  const miniCartClassName = classnames('shopping-link', noOfItemsInCart ? 'checkout-link' : 'icon-shopping-bag', { 'new-nm-cart-icon': isNmNewHeader });
  const miniCartText = noOfItemsInCart ? (`Checkout ${noOfItemsInCart}`) : 'Shopping Bag';
  const renderMiniCartContents = () => {
    if (isEmpty(cartInfo)) {
      return <MiniCartErrorModal checkoutUrl={checkoutUrl} />;
    }

    if (addToCartV2Toggle) {
      return (
        <div className="header-drop-down-v2">
          <div className="cart-close-x">×</div>
          <MiniCartContent
            isGuestUser={securityStatus && securityStatus.toLowerCase() !== 'authenticated'}
            cartData={cartInfo}
            clientLocationZip={clientLocation?.zip}
          />
        </div>
      );
    }

    return <div dangerouslySetInnerHTML={{ __html: cartInfo }} />;
  };

  const clickCloseButtonToHideMiniCart = (e) => {
    if (e.target.className === 'cart-close-x') {
      startMiniCartCloseAnimation();
    }
  };

  const hoverMiniCart = () => {
    if (isSimplifiedminicart) {
      setTimeout(() => {
        internationalToggle && !isDomestic
          ? showInternationalMiniCart()
          : showMiniCartAction();
      }, 500);
    }
  };

  useEffect(() => {
    if (internationalToggle && !isDomestic && localStorage) {
      const intlCartName = `internationalCart_${countryCode}_${currencyCode}`;
      const localInternationalCart = (() => {
        const localInternationalCartData = localStorage.getItem(intlCartName);
        return localInternationalCartData
          ? JSON.parse(localInternationalCartData)
          : [];
      })();
      const cartDataAvailable = localInternationalCart && localInternationalCart.length > 0;
      const cartQuantity = cartDataAvailable && sumBy(localInternationalCart,
        (item) => Number(item.productQty));
      updateMiniCartItemCount(cartQuantity);
    }
  }, []);

  return (
    <>
      <span id="miniCartContainer" className="miniCart-desktop-container desktop-tablet-only">
        <button
          id="miniCartButton"
          aria-expanded={cartOpened}
          className={miniCartClassName}
          onClick={() => {
            internationalToggle && !isDomestic
              ? showInternationalMiniCart()
              : showMiniCartAction();
          }}
          onMouseOver={() => {
            hoverMiniCart();
          }}
        >
          { miniCartText}
        </button>
        <div
          className={classnames('miniCartOverlay', { 'miniCartOverlay--animate': animateClosed })}
          onClick={clickCloseButtonToHideMiniCart}
          onMouseOut={startTimer}
          onMouseOver={() => clearTimeout(timerId)}
        >
          { isInFocus ? renderMiniCartContents() : undefined }
        </div>
      </span>
      {affirmToggle && <AffirmScript />}
    </>
  );
};

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';
  const affirmTest = get(state, 'abTestsOpt.nmpd0003.variation', 'a') === 'b';

  return {
    securityStatus: state.user.securityStatus,
    cartInfo: state.miniCart.cartInfo,
    noOfItemsInCart: state.miniCart.noOfItemsInCart,
    currentFocusedComponent: state.app.componentInFocus,
    timerId: state.miniCart.timerId,
    animateClosed: state.miniCart.animateClosed,
    isSimplifiedminicart: get(state, 'abTestsOpt.simplifiedminicart.variation', 'smc_off') === 'smc_on',
    cartOpened: state.miniCart.cartOpened,
    affirmToggle: isDomestic && affirmTest,
    isDomestic,
    internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
    addToCartV2Toggle: get(state, 'toggles.ADD_TO_CART_V2', false) && isDomestic,
    currencyCode: get(state, 'locale.currencyCode', 'USD'),
    countryCode: get(state, 'locale.countryCode', 'US'),
    clientLocation: get(state, 'api.requestContext.akamaiEdgescape', {}),
  };
};

const mapDispatchToProps = ({
  showMiniCart,
  showMiniCartV2,
  startMiniCartCloseAnimation,
  startTimer,
  showInternationalMiniCart,
  updateMiniCartItemCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopTabletMiniCart);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import window from 'window-or-global';
import classnames from 'classnames';
// eslint-disable-next-line import/no-cycle
import SimpleText from '../simpleText/simpleText';
import './anchorPointsSticky.scss';

const ANCHOR_NAV_HEIGHT = 48;

export class AnchorPointsSticky extends Component {
  constructor(props) {
    super(props);
    this.navRef = React.createRef();
    this.state = {
      isFixed: false,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.dispatchEvent(new window.Event('scroll'));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { isMobilePhone, isHeaderSticky, isSearchExposed } = this.props;
    const offset = window.scrollY;
    let mobileShift = 0;
    if (isMobilePhone && isHeaderSticky) {
      if (isSearchExposed) {
        mobileShift = -94;
      } else mobileShift = -52;
    }
    const navScrollTop = this.navRef.current.getBoundingClientRect().top + offset + mobileShift;
    if (offset > navScrollTop && !this.state.isFixed) {
      this.setState({
        isFixed: true,
      });
    } else if (offset <= navScrollTop && this.state.isFixed) {
      this.setState({
        isFixed: false,
      });
    }
  }

  handleOnClick(e, item) {
    if (item.fields.link) {
      if (item.fields.link.indexOf('#') === 0) {
        e.preventDefault();
        const elem = document.getElementById(item.fields.link.replace('#', ''));

        if (elem) {
          const elemOffsetTop = elem.offsetTop;

          window.scrollTo({
            top: elemOffsetTop - ANCHOR_NAV_HEIGHT,
            behavior: 'smooth',
          });
        }
      }
    } else {
      e.preventDefault();
    }
  }

  render() {
    const {
      cmsContentAssets = {},
      isMobilePhone,
      isHeaderSticky,
      isSearchExposed,
      anchorBackground = '',
      trackTags = {},
      buttonStyle,
    } = this.props;

    const itemCN = `anchor-points-sticky-nav__item${buttonStyle ? ` anchor-points-sticky-nav__button--${buttonStyle}` : ''}`;

    return (
      <section ref={this.navRef} className="anchor-points-sticky__wrapper">
        <section
          className={classnames('anchor-points-sticky-nav__wrapper', {
            'anchor-points-sticky-nav__wrapper__fixed': this.state.isFixed,
            'active-header': isMobilePhone && this.state.isFixed && isHeaderSticky,
            'active-header-search': isMobilePhone && this.state.isFixed && isHeaderSticky && isSearchExposed,
          })}
        >
          <nav
            className={classnames('anchor-points-sticky-nav', { 'anchor-points-sticky-nav__mobile': isMobilePhone })}
            style={{ background: anchorBackground }}
          >
            <ul
              className={classnames('anchor-points-sticky-nav__links', { 'anchor-points-sticky-nav__links__mobile': isMobilePhone })}
            >
              {
                cmsContentAssets.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={itemCN}
                      onClick={(e) => this.handleOnClick(e, item)}
                    >
                      <SimpleText cmsContentItem={item} key={index} trackTags={trackTags} />
                    </li>
                  );
                })
              }
            </ul>
          </nav>
          {this.state.isFixed && <div className="anchor-points-sticky__wrapper__shadow" />}
        </section>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  isHeaderSticky: get(state, 'header.isStickyActive'),
  isSearchExposed: get(state, 'header.isSearchExposed'),
});

export default connect(mapStateToProps)(AnchorPointsSticky);

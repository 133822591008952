/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import Slider from 'react-slick';
import { InView } from 'react-intersection-observer';
import { shouldLoad } from 'universal/http-client';
import { checkForICIDAndAddTags } from 'cms/components/utils';
import { openModal } from 'client/common/components/Modal/actions';
import { AB_RECENTLY_VIEWED } from 'storefront/components/constants';
import {
  getCmsProducts,
  getCategoryProducts,
  getModalContent,
  setActiveEntryId,
  getPersonalizedProducts,
  isGlobalNavUpdateToggleEnabled,
} from 'cms/actions';
import { buildRVProducts } from 'cms/components/Product/utils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { buildCookieString } from 'universal/utilities-cookies';
import ProductCarouselTile from './productCarouselTile';
import CustomArrow from './CustomArrow';
import './productCarousel.scss';

const IMAGE_PLACEMENT = {
  beginning: 'Beginning',
  end: 'End',
};
const RECENTLY_VIEWED = 'RV';
const RECENTLY_VIEWED_KEY = 'nm_recently_viewed';

const buildAbRVCustomTitle = (condition, customTitle) => {
  const RECENTLY_VIEWED_TITLE = 'RECENTLY VIEWED';
  const title = condition ? RECENTLY_VIEWED_TITLE : customTitle;
  return <div className="rich-text align-center abrv-title"><h4>{title}</h4></div>;
};
export class ProductCarousel extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      arrowTop: null,
      fetchedRVProducts: [],
      clientFetchDone: false,
    };
  }

  async componentDidMount() {
    const { cmsContentItem = {}, personalizedProductApi, carouselClientRender } = this.props;
    const contentId = cmsContentItem?.sys?.id;
    const title = cmsContentItem?.fields?.title;
    const { ids } = cmsContentItem?.fields;
    if (carouselClientRender && ids?.length === 2 && ids?.includes('Personalization') && shouldLoad(personalizedProductApi)) {
      const fallbackCategoryId = ids[1].startsWith('cat') && ids[1];
      this.props.dispatch(getPersonalizedProducts(contentId, title, fallbackCategoryId));
    }

    const imageHeight = this.imageRef?.current?.clientHeight;
    if (imageHeight) {
      const newTop = Math.round(imageHeight / 2);
      this.setState({ arrowTop: newTop });
    }

    window.addEventListener('resize', this.handleResize);
    const {
      abRecentlyViewed,
      isRVPlacement,
    } = this.props;

    if (abRecentlyViewed && isRVPlacement) {
      const {
        session,
        user,
      } = this.props;

      try {
        const localIds = window.localStorage.getItem(RECENTLY_VIEWED_KEY);
        const parsedIds = JSON.parse(localIds);
        const headers = {
          Cookie: buildCookieString({
            JSESSIONID: get(session, 'JSESSIONID', ''),
            ucid: get(user, 'ucid', ''),
            rid: get(user, 'rid', 'US'),
            DYN_USER_ID: get(session, 'DYN_USER_ID', ''),
            W2A: get(session, 'W2A', ''),
          }),
        };
        const url = `${NMConfig.API_MINIFIED_PRODUCT_DETAIL_PDP}?productIds=${parsedIds.join(',')}`;
        await axios.get(url, { headers }).then(({ data: { minifiedProductInfo } = {} }) => {
          this.setState({
            fetchedRVProducts: buildRVProducts(minifiedProductInfo),
            clientFetchDone: true,
          });
        });
      } catch (e) {
        this.setState({ clientFetchDone: true });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onClickHandler(e, linkTo) {
    e.preventDefault();

    const { modalContentApi } = this.props;
    const entryId = linkTo;

    if (entryId && shouldLoad(modalContentApi)) {
      this.props.dispatch(getModalContent(entryId));
    }

    this.props.dispatch(setActiveEntryId(entryId));

    this.props.dispatch(
      openModal({
        type: 'InfoModal',
      }),
    );
  }

  getSliderSettings(slidesInDrawer) {
    return {
      infinite: false,
      arrows: true,
      autoplay: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      nextArrow: <CustomArrow arrowTop={this.state.arrowTop} ariaLabel="next" />,
      prevArrow: <CustomArrow arrowTop={this.state.arrowTop} ariaLabel="previous" />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            infinite: false,
            slidesToShow: 2.5,
            slidesToScroll: 2,
            draggable: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            infinite: false,
            slidesToShow: slidesInDrawer || 3.5,
            slidesToScroll: 3,
            draggable: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            arrows: true,
            slidesToShow: slidesInDrawer || 5,
            slidesToScroll: 5,
          },
        },
      ],
    };
  }

  getVerticalSliderSettings(numOfProds) {
    return {
      infinite: false,
      arrows: false,
      autoplay: false,
      slidesToShow: numOfProds,
      slidesToScroll: numOfProds,
      vertical: true,
    };
  }

  handleResize = () => {
    const newImageHeight = this.imageRef?.current?.clientHeight;
    const newScreenWidth = window.innerWidth;
    if (newImageHeight && newScreenWidth > 1024) {
      const newTop = Math.round(newImageHeight / 2);
      this.setState({ arrowTop: newTop });
    }
  }

  a11y() {
    const slider = document.querySelectorAll('.product-carousel-slider-container .slick-slide');
    if (!slider.length) return;
    slider.forEach((slide) => {
      if (!slide.querySelector('button')) return;
      slide.querySelector('button').removeAttribute('tabindex');
      if (slide.getAttribute('aria-hidden') === 'true') {
        slide.querySelector('button').setAttribute('tabindex', '-1');
      }
    });
  }

  UNSAFE_componentWillMount() {
    const {
      cmsProductsApi,
      cmsContentItem = {},
      cmsCategoryProductsApi,
      personalizedProductApi,
      isRVPlacement = false,
      carouselClientRender,
      productsCacheToggle,
    } = this.props;
    const contentId = cmsContentItem?.sys?.id;
    const title = cmsContentItem?.fields?.title;
    const { ids } = cmsContentItem?.fields;

    if (ids && !isRVPlacement) {
      if (ids.length === 1 && !ids.includes('Personalization') && shouldLoad(cmsCategoryProductsApi)) {
        this.props.dispatch(getCategoryProducts(ids));
      } else if (ids.length > 1 && !ids.includes('Personalization') && shouldLoad(cmsProductsApi)) {
        this.props.dispatch(getCmsProducts(ids, contentId, productsCacheToggle));
      } else if (!carouselClientRender && ids.includes('Personalization') && shouldLoad(personalizedProductApi)) {
        this.props.dispatch(getPersonalizedProducts(contentId, title, productsCacheToggle));
      }
    }
  }

  combineProducts(cmsProducts, childProducts) {
    return cmsProducts.map((product) => (
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds ? childProducts.find((childProduct) => childProduct.id === product.childProductIds[0]) || product : product));
  }

  renderProducts(combinedProducts, isMobilePhone, cmsContentItem, inView = false, isLazyLoaded = false, hideStatus, carouselId = '', renderInDrawer, ppOpener = null, panelDescription = '') {
    if (combinedProducts?.length > 15) combinedProducts.splice(15);
    return combinedProducts.map((product, index) => {
      return (
        <ProductCarouselTile
          id={product.id}
          designer={product.designerName}
          name={product.name}
          price={product.price}
          imageUrl={product.imageUrl}
          url={product.url}
          promotions={product.promotions}
          displayable={product.displayable}
          isMobilePhone={isMobilePhone}
          displayOptions={cmsContentItem?.fields}
          inView={inView}
          isLazyLoaded={isLazyLoaded}
          hideStatus={hideStatus}
          imageRef={index === 0 ? this.imageRef : false}
          carouselId={carouselId}
          renderInDrawer={renderInDrawer}
          key={product.id}
          ppOpener={ppOpener}
          panelDescription={panelDescription}
        />
      );
    });
  }

  render() {
    const {
      cmsContentItem,
      isMobilePhone = false,
      cmsProducts = [],
      childProducts = [],
      cmsCategoryProducts = [],
      isLazyLoaded,
      trackTags: propsTrackTags = {},
      hideStatus,
      isGlobalUpdateOn,
      drawerCarouselToggle,
      isRVPlacement,
      abRecentlyViewed,
      abRVCustomTitle,
    } = this.props;
    let filteredProducts = [];
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(cmsProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts) ? filteredProducts : cmsProducts;
    const carouselDirection = cmsContentItem?.fields?.carouselDirection;
    const carouselPlacement = cmsContentItem?.fields?.carouselPlacement;
    const carouselTitle = cmsContentItem?.fields?.drawerCarouselTitle;
    const productCarouselImage = cmsContentItem?.fields?.productCarouselImage?.[0]?.['secure_url'];
    const productCarouselImagePlacement = cmsContentItem?.fields?.productCarouselImagePlacement;
    const productCarouselImageLinkTo = cmsContentItem?.fields?.productCarouselImageLinkTo;
    const openLinkInNewTab = cmsContentItem?.fields?.openLinkInNewTab;
    const displayImage = productCarouselImage && productCarouselImageLinkTo;
    const verticalCarouselHeight = (combinedProducts.length * 425);
    const isModalContent = !openLinkInNewTab && !isUndefined(openLinkInNewTab);
    const getLink = (url) => checkForICIDAndAddTags(url, propsTrackTags, [], isModalContent);
    const carouselId = cmsContentItem?.sys?.id;

    const isAbRVCase = isRVPlacement && abRecentlyViewed && this.state.clientFetchDone;
    const abProductsToRender = this.state.fetchedRVProducts.length > 0 ? [...this.state.fetchedRVProducts] : [];
    const abPanelDescription = this.state.fetchedRVProducts.length > 0 ? 'Recently Viewed' : 'Best Sellers';
    const abRVTitle = buildAbRVCustomTitle(this.state.fetchedRVProducts.length > 0, abRVCustomTitle);

    const renderProductCarouselImage = () => (
      <a
        className="carousel-image"
        href={getLink(productCarouselImageLinkTo)}
        target={openLinkInNewTab ? '_blank' : '_self'}
        rel="noreferrer noopener"
        onClick={(!openLinkInNewTab && !isUndefined(openLinkInNewTab)) ? ((e) => this.onClickHandler(e, productCarouselImageLinkTo)) : undefined}
      >
        <img src={productCarouselImage} alt="" />
      </a>
    );

    const renderInDrawer = () => {
      return isGlobalUpdateOn
         && carouselPlacement === 'Drawer'
         && drawerCarouselToggle;
    };

    const carouselContainerClass = (className) => {
      return renderInDrawer() ? `${className}__drawer` : className;
    };

    const renderFeaturedHeader = () => {
      if (renderInDrawer() && carouselTitle) {
        return <h6>{carouselTitle}</h6>;
      }
      return null;
    };

    const slidesInDrawer = renderInDrawer() ? 4 : null;

    const renderSlider = (inView = false) => (
      <Slider onReInit={this.a11y} {...carouselDirection === 'Vertical' ? this.getVerticalSliderSettings((!isEmpty(combinedProducts) && combinedProducts.length) || (!isEmpty(cmsCategoryProducts) && cmsCategoryProducts.length)) : this.getSliderSettings(slidesInDrawer)}>
        {displayImage && productCarouselImagePlacement === IMAGE_PLACEMENT.beginning && renderProductCarouselImage()}
        {!isEmpty(combinedProducts) && this.renderProducts(combinedProducts, isMobilePhone, cmsContentItem, inView, isLazyLoaded, hideStatus, carouselId, renderInDrawer())}
        {!isEmpty(cmsCategoryProducts) && this.renderProducts(cmsCategoryProducts, isMobilePhone, cmsContentItem, inView, isLazyLoaded, hideStatus, carouselId, renderInDrawer())}
        {isAbRVCase && this.renderProducts(abProductsToRender, isMobilePhone, cmsContentItem, inView, isLazyLoaded, hideStatus, carouselId, false, 'product carousel', abPanelDescription)}
        {displayImage && productCarouselImagePlacement === IMAGE_PLACEMENT.end && renderProductCarouselImage()}
      </Slider>
    );

    return (
      <>
        {(isAbRVCase && abPanelDescription !== 'Best Sellers') && abRVTitle}
        <div className={carouselContainerClass('product-carousel-slider-container')} style={carouselDirection === 'Vertical' ? { width: '100%' } : {}}>
          {renderFeaturedHeader()}
          <div style={carouselDirection === 'Vertical' ? { height: `${verticalCarouselHeight}px` } : {}}>
            {isLazyLoaded
              ? (
                <InView
                  rootMargin="100px"
                  triggerOnce
                >
                  {({ inView, ref }) => (
                    <div ref={ref}>
                      {renderSlider(inView)}
                    </div>
                  )}
                </InView>
              ) : (
                renderSlider()
              )
          }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const contentId = ownProps.cmsContentItem?.sys?.id;
  const categoryId = ownProps.cmsContentItem?.fields?.ids?.length > 1 ? ownProps.cmsContentItem?.fields?.ids?.[1] : ownProps.cmsContentItem?.fields?.ids?.[0];
  const isRVPlacement = ownProps.cmsContentItem?.fields?.carouselPlacement === RECENTLY_VIEWED;
  const abRVCustomTitle = ownProps.cmsContentItem?.fields?.abrvCustomTitle;

  return {
    carouselClientRender: get(state.toggles, 'PRODUCT_CAROUSEL_CLIENT_SIDE', false),
    isMobilePhone: get(state, 'device.isMobilePhone'),
    cmsProductsApi: state.api[`cms_products_${contentId}`.toLowerCase()],
    cmsProducts: state.cms.products?.[contentId],
    cmsCategoryProductsApi: state.api[`cms_currated_products_${categoryId}`.toLowerCase()],
    cmsCategoryProducts: state.cms.productsByCategory?.[categoryId],
    childProducts: state.cms.childProducts?.[contentId],
    isLazyLoaded: get(state, 'toggles.LAZYLOAD_CMS_CONTENT'),
    modalContentApi: state.api[`modal_content_${contentId}`.toLowerCase()],
    drawerCarouselToggle: get(state, 'toggles.DRAWER_CAROUSEL', false),
    isGlobalUpdateOn: isGlobalNavUpdateToggleEnabled(state),
    personalizedProductApi: state.api[`personalized_products_${contentId}`.toLowerCase()],
    abRecentlyViewed: get(state, `abTestsOpt.${AB_RECENTLY_VIEWED}.variation`, 'a') === 'b',
    productsCacheToggle: get(state, 'toggles.CF_CACHE_PRDS', false),
    ...(isRVPlacement && {
      session: get(state, 'session'),
      user: get(state, 'user'),
      selectedGender: getGenderWithOverride(state),
      isRVPlacement,
      abRVCustomTitle,
    }),
  };
};

export default connect(mapStateToProps)(ProductCarousel);

import { formatMoney } from 'accounting';

export function renderFormattedPriceWithDecimals(price, currencyCode = '$') {
  const currencyFormat = (currencyCode === '$' ? '%s%v' : '%s %v');
  const currencyThousand = (currencyCode === '$' ? ',' : '');
  return formatMoney(price, {
    symbol: currencyCode,
    format: currencyFormat,
    thousand: currencyThousand,
  });
}

/* eslint import/no-cycle: 0 */
import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setStoryClassification } from 'cms/actions';
import get from 'lodash/get';
import RenderLayout from 'cms/components/renderLayout';
import './editorialSilo.scss';

const getStoryFromSummary = (
  story,
  index,
  newClassificationsToggle,
  isMobile
) => {
  const getL1Layouts = get(story, 'fields.l1Layouts', []);
  const storySummary = newClassificationsToggle
    ? get(story, 'fields.l1Layout')
    : getL1Layouts.find(
      (elem) => elem.fields.placement === 'Editorial-story-summary'
    );
  const getStorySummaryL2Layouts = get(storySummary, 'fields.l2Layout', []);
  const storyLayoutType = index <= 1 || isMobile ? '50' : '33';
  return getStorySummaryL2Layouts.find(
    (elem) => elem.fields.desktopWidth === storyLayoutType
  );
};

const getLayout = (items, isMobile) => {
  const changedContentTypeItems = items.map((item) => {
    if (isMobile) {
      return {
        ...item,
        fields: { ...item.fields, mobileWidth: '50' },
        contentType: 'l3Layout',
      };
    }

    return {
      ...item,
      contentType: 'l3Layout',
    };
  });

  return {
    contentType: 'l2Layout',
    fields: {
      mobileWidth: '96',
      desktopWidth: '96',
      l3Layout: changedContentTypeItems,
    },
  };
};

const EditorialSilo = ({
  stories,
  classification,
  newClassificationsToggle,
  isMobilePhone,
  dispatch,
}) => {
  const dispatchClassification = () => {
    dispatch(setStoryClassification(window.location.hash.replace('#', '')));
  };

  useEffect(() => {
    dispatchClassification();
    window.addEventListener('hashchange', dispatchClassification);

    return () => window.removeEventListener('hashchange', dispatchClassification);
  }, []);

  if (!stories.length) return null;
  const filteredStories = () => stories.filter((item) => {
    const storyClassification = get(
      item,
      'fields.metaData.fields.classifications',
      []
    );
    return storyClassification.includes(classification);
  });

  const storiesToDisplay = classification ? filteredStories() : stories;
  const storiesLayout = storiesToDisplay
    .map((item, index) => getStoryFromSummary(item, index, newClassificationsToggle, isMobilePhone))
    .filter(Boolean);
  if (!storiesLayout.length) return null;

  const storiesLayoutL = storiesLayout.length;
  const NUMBER_OF_STORIES_TO_ATTACH_HACK = isMobilePhone ? 2 : 3;
  const addItemFlag = storiesLayoutL > 2
    && (storiesLayoutL === 3
      || storiesLayoutL % NUMBER_OF_STORIES_TO_ATTACH_HACK === 1);
  if (addItemFlag) {
    const extraElement = storiesLayout.slice(-1);
    storiesLayout.push(extraElement[0]);
  }

  const layoutToRender = [];
  const firstLayout = isMobilePhone ? storiesLayout : storiesLayout.slice(0, 2);
  layoutToRender.push(getLayout(firstLayout, isMobilePhone));
  if (!isMobilePhone && storiesLayout.length > 2) {
    layoutToRender.push(getLayout(storiesLayout.slice(2), isMobilePhone));
  }

  const wrapperCN = classnames('editorial-silo-container', {
    'mobile-view': isMobilePhone,
    'add-item': addItemFlag,
  });

  return (
    <div className={wrapperCN}>
      {layoutToRender.map((item) => (
        <RenderLayout key={uuid()} cmsLayout={item} />
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    classification: state.cms.classification,
    stories: get(state, 'cms.stories', []),
    newClassificationsToggle: get(state, 'toggles.NEW_CLASSIFICATIONS_MODEL', false),
    isMobilePhone: get(state, 'device.isMobilePhone', false),
  };
};

export default connect(mapStateToProps)(EditorialSilo);

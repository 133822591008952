import get from 'lodash/get';
import React from 'react';
import classNames from 'classnames';
import ConnectedEcmAdaptiveImage from '../EcmAdaptiveImage';
import { ecmGetAdaptiveStyleBlock, ecmGetOptimizedAdaptiveStyleBlock } from '../../utilities';
import {
  getCustomStylesForCTADesktop,
  getCustomStylesForCTATablet,
  getCustomStylesForCTAMobile,
} from '../EcmText/textComponentUtilities';

const EcmTextOverImage = ({
  dataObj,
  ecmSlot,
  improvePerformanceOfStyleToggle,
}) => {
  const ecmStyle = get(ecmSlot, 'className', '');
  const ecmComponentId = get(dataObj, 'componentId', '');
  const creativeId = get(dataObj, 'creative_id');
  const ecmProperties = get(dataObj, 'properties', {});
  const ecmObjectStyle = improvePerformanceOfStyleToggle ? `ecm-${ecmComponentId}` : `ecm-container__textoverimage__${ecmComponentId}`;
  const classes = improvePerformanceOfStyleToggle ? classNames('ecm-container__textoverimage optst', ecmStyle, ecmObjectStyle)
    : classNames('ecm-container__textoverimage', ecmStyle, ecmObjectStyle);
  const adaptiveStyleBlock = improvePerformanceOfStyleToggle
    ? ecmGetOptimizedAdaptiveStyleBlock(ecmProperties, ecmObjectStyle)
    : ecmGetAdaptiveStyleBlock(ecmProperties, ecmObjectStyle);
  const callToActionCustomStylesDesktop = get(dataObj, 'callToActionCustomStyles.desktop', []);
  const callToActionCustomStylesTablet = get(dataObj, 'callToActionCustomStyles.tablet', []);
  const callToActionCustomStylesMobile = get(dataObj, 'callToActionCustomStyles.mobile', []);
  if (improvePerformanceOfStyleToggle) {
    return (
      <div creativeid={creativeId}>
        { adaptiveStyleBlock }
        <div className={classes}>
          <ConnectedEcmAdaptiveImage
            picture={dataObj.image}
            ecmSlot={ecmSlot}
            textOverlayElements={dataObj.textoverlayelements}
            buttonOverlayElements={dataObj.calltoactionbuttons}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          />
          {
              callToActionCustomStylesDesktop
              && getCustomStylesForCTADesktop(callToActionCustomStylesDesktop)
            }
          {
              callToActionCustomStylesTablet
              && getCustomStylesForCTATablet(callToActionCustomStylesTablet)
            }
          {
              callToActionCustomStylesMobile
              && getCustomStylesForCTAMobile(callToActionCustomStylesMobile)
            }
        </div>
      </div>
    );
  }
  return (
    <div className={classes} creativeid={creativeId}>
      { adaptiveStyleBlock }
      <ConnectedEcmAdaptiveImage
        picture={dataObj.image}
        ecmSlot={ecmSlot}
        textOverlayElements={dataObj.textoverlayelements}
        buttonOverlayElements={dataObj.calltoactionbuttons}
        improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
      />
      {
            callToActionCustomStylesDesktop
            && getCustomStylesForCTADesktop(callToActionCustomStylesDesktop)
          }
      {
            callToActionCustomStylesTablet
            && getCustomStylesForCTATablet(callToActionCustomStylesTablet)
          }
      {
            callToActionCustomStylesMobile
            && getCustomStylesForCTAMobile(callToActionCustomStylesMobile)
          }
    </div>
  );
};

export default EcmTextOverImage;

/* eslint import/no-cycle: 0 */
import React from 'react';
import './buttonsGrid.scss';
import RenderContentItem from 'cms/components/renderContentItem';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';

const ButtonsGrid = ({ cmsContentItem: { fields }, trackTags: propsTrackTags = {} }) => {
  const {
    numberOfColumns,
    buttonsWidth,
    buttonsHeight,
    buttons,
    trackingTags: ownTrackTags = [],
  } = fields;
  const btnsCopy = [...buttons];
  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));

  const getLayout = (buttons) => {
    return (
      <div>
        {buttons.map((item, index) => (
          <div
            style={{ width: buttonsWidth, height: buttonsHeight }}
            className="button--wrapper"
            key={index}
          >
            <RenderContentItem trackTags={combinedTrackTags} cmsContentItem={item} />
          </div>
        ))}
      </div>
    );
  };

  const bpArray = new Array(Math.ceil(btnsCopy.length / numberOfColumns)).fill(
    numberOfColumns,
  );
  const layout = [];
  for (let i = 0; i < bpArray.length; i++) {
    if (btnsCopy.length === 0) break;
    layout.push(getLayout(btnsCopy.splice(0, bpArray[i])));
  }

  return <div className="buttons-grid--wrapper">{layout}</div>;
};

export default ButtonsGrid;

import React from 'react';

import './designerByCategory.scss';

const DesignerByCategory = ({ dataToDisplay, titleToDisplay }) => (
  <div className="designers-by-category">
    <h6>{titleToDisplay}</h6>
    <ul>
      {
        dataToDisplay.map((subCat, index) => {
          const subCatRedText = subCat.attributes && subCat.attributes.redTextFlag ? 'red-text' : '';
          const anchorProps = subCatRedText ? { className: subCatRedText } : {};
          return (
            <li key={index}>
              <a key={index} href={subCat.url} {...anchorProps}>
                {subCat.name}
              </a>
            </li>
          );
        })
      }
    </ul>
  </div>
);

export default DesignerByCategory;

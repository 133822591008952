import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import classNames from 'classnames';
import { ecmGetAdaptiveStyleBlock, ecmGetOptimizedAdaptiveStyleBlock } from '../../utilities';
import EcmTextBreakPointComponent from './textComponentBreakPoints';

const EcmText = ({
  text,
  ecmSlot,
  formatAdaptiveStyleBlock = true,
  improvePerformanceOfStyleToggle,
}) => {
  const ecmStyle = get(ecmSlot, 'className', '');
  const componentId = get(text, 'componentId', '');
  const creativeId = get(text, 'creative_id');
  const componentClass = improvePerformanceOfStyleToggle ? `ecm-${componentId}` : `ecm-container__text__${componentId}`;
  const classes = improvePerformanceOfStyleToggle ? classNames('ecm-container__text optst', ecmStyle, componentClass) : classNames('ecm-container__text', ecmStyle, componentClass);
  const divStyle = (isEmpty(get(text, 'properties.bgcolor', ''))) ? {} : { backgroundColor: get(text, 'properties.bgcolor', '') };
  const desktopContent = get(text, 'properties.text', '');
  const tabletContent = get(text, 'properties.tabletText', '');
  const mobileContent = get(text, 'properties.mobileText', '');
  const paddingProp = get(text, 'properties.padding', {});

  const adaptiveStyleBlockFunc = () => {
    if (formatAdaptiveStyleBlock) {
      return improvePerformanceOfStyleToggle
        ? ecmGetOptimizedAdaptiveStyleBlock(paddingProp, componentClass)
        : ecmGetAdaptiveStyleBlock(paddingProp, componentClass);
    }
    return '';
  };
  const adaptiveStyleBlock = adaptiveStyleBlockFunc();
  const callToActionCustomStylesDesktop = get(text, 'properties.callToActionCustomStyles.desktop', []);
  const callToActionCustomStylesTablet = get(text, 'properties.callToActionCustomStyles.tablet', []);
  const callToActionCustomStylesMobile = get(text, 'properties.callToActionCustomStyles.mobile', []);

  return (
    <EcmTextBreakPointComponent
      callToActionCustomStylesDesktop={callToActionCustomStylesDesktop}
      callToActionCustomStylesTablet={callToActionCustomStylesTablet}
      callToActionCustomStylesMobile={callToActionCustomStylesMobile}
      classes={classes}
      creativeId={creativeId}
      divStyle={divStyle}
      desktopContent={desktopContent}
      tabletContent={tabletContent}
      mobileContent={mobileContent}
      adaptiveStyleBlock={adaptiveStyleBlock}
      improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
    />
  );
};

export default EcmText;

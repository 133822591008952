import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pullAt from 'lodash/pullAt';
import { SearchSuggestions } from 'srp/components/atoms/SearchSuggestions/SearchSuggestions';
import { openCollapseSearchBoxModal } from 'client/common/actions/actions-page';
import { formatTopItems, isModalOpen, isCollapsedSearchBarOn } from 'srp/utils/srpUtils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { setGender, setTypeaheadUtag } from 'shared/components/App/app-actions';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import {
  loadTypeaheadUIConfig,
  setMobileTextBoxValue,
  setRecentlySearchedAnalytics,
} from '../search-actions';
import {
  getMobileSearchTerm, getTypeaheadUIConf, typeaheadSuggestionPayload,
} from '../search-reducers';
import { searchFormSubmit } from '../submitHelper';

import './collapseSearchBarMobileV2.scss';
import '../bloomreach.scss';

export class CollapseSearchBarMobileV2 extends Component {
  constructor(props) {
    super(props);
    const {
      dtGender,
      typeaheadUIConf,
    } = props;
    const selectedUIConfig = typeaheadUIConf;

    this.state = {
      inputIsFocused: false,
      recentSearchHover: false,
      isIconClicked: false,
      selectedGender: dtGender,
      typeaheadUIConf: selectedUIConfig,
      topProducts: {},
      typeaheadSuggestions: {},
      isSuggestionSelected: false,
      suggestions: [],
      showInput: false,
    };

    this.handleSelectionOnRecentSearch = this.handleSelectionOnRecentSearch.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);

    this.recentSearchRef = createRef(null);
    this.mobileBrSearchInput = createRef(null);
    this.modalBrSearchInput = createRef(null);
    this.searchIcon = null;

    this.setMobileBrSearchInput = (element, insideModal) => {
      if (insideModal) {
        this.modalBrSearchInput = element;
        if (this.modalBrSearchInput) {
          this.modalBrSearchInput.focus();
          this.modalBrSearchInput.style.display = 'block';
        }
      }
    };

    this.setSearchIcon = (element) => {
      this.searchIcon = element;
    };

    this.handleOnBlur = () => {
      this.setState({
        inputIsFocused: false,
        selectedGender: this.props.dtGender,
      });
      if (this.searchIcon) this.searchIcon.disabled = false;
    };

    this.setFocusAtEnd = (e) => {
      this.setState((prevState) => ({
        ...prevState,
        isIconClicked: true,
      }));

      if (e.target) {
        const tempInput = document.createElement('input');
        e.target.parentNode.insertBefore(tempInput, e.target);
        tempInput.classList.add('tempInput');
        tempInput.setAttribute('id', 'temporaryInput');
        tempInput.focus();
      }
    };

    this.onRecentSearchHover = this.onRecentSearchHover.bind(this);
    this.openRecentSearchOnTouch = this.openRecentSearchOnTouch.bind(this);
  }

  componentDidMount() {
    const {
      searchTerm,
      setRecentlySearchedAnalytics,
      searchSuggestionsToggle,
      isSearchModalOpen,
      insideModal,
      topProducts,
      typeaheadSuggestionPayload,
    } = this.props;

    const storageKey = 'SRP_SEARCH_RECENT_KEYWORDS';
    const recentSearchCount = 7;

    if (searchSuggestionsToggle) {
      try {
        const recentSearches = typeof (localStorage) !== 'undefined'
          ? (JSON.parse(localStorage.getItem(storageKey)) || [])
          : false;
        if (recentSearches?.length >= recentSearchCount) {
          recentSearches.splice(recentSearchCount, recentSearches.length);
        }

        if (searchTerm !== '') {
          if (recentSearches) {
            const repeatedSearchValue = recentSearches.indexOf(searchTerm);
            if (repeatedSearchValue > -1) {
              pullAt(recentSearches, repeatedSearchValue);
            } else {
              setRecentlySearchedAnalytics(false);
            }
            recentSearches.unshift(searchTerm);
          }
        }
      } catch (e) {
        localStorage.removeItem(storageKey);
      }
    }
    if (isSearchModalOpen) {
      setTimeout(() => {
        this.modalBrSearchInput.value !== undefined
        && this.modalBrSearchInput.focus();
      }, 20);
    }

    // Populates initial BR results if there's a previous term
    if (insideModal && searchTerm.length > 0) {
      if (typeof window !== 'undefined' && window.$ && window.BRAutosuggest) {
        window.BRAutosuggest.search(searchTerm);
      }
    }

    this.setState({
      topProducts,
      typeaheadSuggestions: typeaheadSuggestionPayload,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.typeaheadSuggestionPayload !== prevProps.typeaheadSuggestionPayload) {
      this.onSearchTermChange();
    }
  }

  onRecentSearchHover(hoverStatus) {
    this.setState((prevState) => {
      const resetGenderValue = hoverStatus === false
        ? this.props.dtGender
        : this.state.selectedGender;
      return {
        ...prevState,
        recentSearchHover: hoverStatus,
        selectedGender: resetGenderValue,
      };
    });
  }

  onSearchTermChange() {
    const { typeaheadSuggestionPayload } = this.props;
    this.setState({
      typeaheadSuggestions: { ...typeaheadSuggestionPayload },
    });
  }

  handleSelectionOnRecentSearch(value) {
    const { handleOnChange, setRecentlySearchedAnalytics } = this.props;
    const thisContext = this;
    if (value) {
      setRecentlySearchedAnalytics(true);
      handleOnChange(value);
      setTimeout(() => {
        if (thisContext.mobileSearchRef) {
          thisContext.mobileSearchRef.click();
        }
      }, 200);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        recentSearchHover: false,
        inputIsFocused: false,
      }));
    }
  }

  handleTextChange(value) {
    const { handleOnChange } = this.props;
    handleOnChange(value);
  }

  openRecentSearchOnTouch() {
    this.setState((prevState) => ({
      ...prevState,
      inputIsFocused: true,
    }));
  }

  render() {
    const {
      searchTerm,
      searchSuggestionsToggle,
      topProducts,
      isTypeaheadSuggestionEmpty,
      openCollapseSearchBoxModal,
      insideModal,
      setTypeaheadUtag,
      isCollapsedSearchBarToggle,
      isHNRightToggle,
    } = this.props;
    const {
      inputIsFocused,
      recentSearchHover,
      isIconClicked,
      selectedGender,
      typeaheadUIConf,
      typeaheadSuggestions,
      showInput,
    } = this.state;
    const selectedTopProducts = topProducts;

    if (isIconClicked && !insideModal) {
      const modalClassName = 'collapse-search-box-modal';
      const modalProps = { testProp: true };
      openCollapseSearchBoxModal(modalClassName, modalProps);
      this.setState({ isIconClicked: false });
    }

    const inputIDElement = !insideModal ? 'mobileBrSearchInput' : 'modalBrSearchInput';

    return (
      <div className={classnames(
        'mobile-only search-box-search-container',
        { 'search-icon-align': isHNRightToggle },
        { 'search-open': isIconClicked },
      )}
      >
        {!insideModal
          ? (
            <input
              type="button"
              ref={(input) => input && this.setSearchIcon(input)}
              name="search-icon"
              title="search icon"
              aria-label="Get Results"
              className="sticky-header-search-icon"
              id="sticky-search-icon"
              onClick={(e) => {
                this.setFocusAtEnd(e);
              }}
              onFocus={() => {
                if (inputIsFocused) {
                  setTimeout(() => { this.modalBrSearchInput.focus(); }, 20);
                }
              }}
              style={{ transform: 'TranslateX(5px)' }}
            />
          )
          : null
        }
        <form
          action="/s/"
          className={classnames('mobile-only search-box-mobile__text--visible-sticky')}
          onSubmit={(e) => {
            searchFormSubmit(e, searchTerm);
          }}
          method="GET"
        >
          <input title="from-input" name="from" type="hidden" value="brSearch" />
          <div className="input-search">
            <input title="l-input" name="l" type="hidden" value={searchTerm} />
            {insideModal
              ? (
                <input
                  type="button"
                  ref={(input) => input && this.setSearchIcon && this.setSearchIcon(input)}
                  name="search-icon"
                  title="search icon"
                  aria-label="Get Results"
                  className={
                  classnames(
                    { 'search-box-mobile__text__search-icon': inputIsFocused }
                  )
                }
                />
              ) : null
            }
            <input
              ref={(input) => input && this.setMobileBrSearchInput(input, insideModal)}
              title="search-term-input"
              placeholder="What are you looking for?"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              aria-label="Search Box"
              id={inputIDElement}
              name="q"
              style={{ display: showInput ? 'block' : 'none' }}
              className="search-box-mobile__text__typeahead"
              value={searchTerm}
              onChange={(e) => {
                if (e && e.target) {
                  this.handleTextChange(e.target.value);
                }
              }}
              onBlur={() => !insideModal && this.handleOnBlur()}
              onFocus={() => { this.setState({ inputIsFocused: true, isIconClicked: true }); }}
              onTouchEnd={() => { this.openRecentSearchOnTouch(); }}
            />
          </div>
          {searchSuggestionsToggle
            && (inputIsFocused || recentSearchHover)
            && (
              <div className={`search-suggestions-renderer  ${isIconClicked ? 'search-open' : ''}`}>
                <SearchSuggestions
                  closeRecentSearchOnClear={this.onRecentSearchHover}
                  handleSelection={this.handleSelectionOnRecentSearch}
                  mobileView
                  arrowIndex={-1}
                  forwardRef={this.recentSearchRef}
                  typeaheadData={typeaheadUIConf}
                  typeaheadSuggestionPayload={typeaheadSuggestions}
                  searchTerm={searchTerm}
                  topItems={
                    !isEmpty(selectedTopProducts)
                      ? formatTopItems(selectedTopProducts)
                      : []
                  }
                  noResults={isTypeaheadSuggestionEmpty}
                  selectedGender={selectedGender}
                  isGenderTypeahead={false}
                  setGender={this.props.setGender}
                  setTypeaheadUtag={setTypeaheadUtag}
                  storageKey="SRP_SEARCH_RECENT_KEYWORDS"
                  onListClick={() => {}}
                  brand="NM"
                  collapsedSearchExperience={isCollapsedSearchBarToggle}
                />
              </div>
            )
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const dtGender = getGenderWithOverride(state);
  return {
    searchTerm: getMobileSearchTerm(state),
    searchSuggestionsToggle: get(state, 'toggles.RECENTLY_SEARCHED', false),
    pageId: get(state, 'page.pageId', ''),
    routing: get(state, 'routing.locationBeforeTransitions', {}),
    typeaheadUIConf: getTypeaheadUIConf(state),
    isSearchModalOpen: isModalOpen(state, 'CollapseSearchModal'),
    typeaheadSuggestionPayload: typeaheadSuggestionPayload(state),
    isTypeaheadSuggestionEmpty: get(state, 'search.isTypeaheadSuggestionEmpty', false),
    topProducts: get(state, 'srp.search.topProducts', []),
    selectedGender: get(state, 'srp.search.ta_gender'),
    dtGender,
    isHNRightToggle: get(state, 'toggles.HN_RIGHT', false)
      || get(state, 'abTestsOpt.tl228.variation', 'a') === 'b',
    isCollapsedSearchBarToggle: isCollapsedSearchBarOn(state) && get(state, 'page.pageId', '') === 'PAGE_ID_EDITORIAL',
  };
};
const mapDispatchToProps = ({
  handleOnChange: setMobileTextBoxValue,
  setRecentlySearchedAnalytics,
  openCollapseSearchBoxModal,
  loadTypeaheadUIConfig,
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HEADER),
  setTypeaheadUtag,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseSearchBarMobileV2);

import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { UNITED_STATES } from 'storefront/components/constants';
import { isCountry } from 'client-utils/utilities-country';
import CountrySensitiveLink, { DumbCountrySensitiveLink } from '../FooterContent/FooterLinks/CountrySensitiveLink';
import './siteLinks.scss';

export const termPolicyPath = `${(process.env.BRAND_NAME === 'HC'
  ? 'https://www.horchow.com/assistance/assistance.jsp?itemId=cat16060787'
  : 'https://assistance.neimanmarcus.com/privacy')}`;
const assistanceUrlPath = `${(process.env.BRAND_NAME === 'HC'
  ? 'https://www.horchow.com/assistance/assistance.jsp'
  : 'https://assistance.neimanmarcus.com/')}`;
const urlTogglePath = 'https://assistance.neimanmarcus.com';
const urlHcTogglePath = 'https://assistance.horchow.com';
const siteMapTogglePath = '/service/sitemap';
const siteMapPath = '/service/sitemap.jsp';
const optOutLinkPath = '/CCPA';
const divider = '|';
const termPolicyStr = 'Terms & Conditions and Privacy Policy';
const optOutLinkStr = 'Do Not Sell My Personal Information';
const optOutLinkUrl = 'https://www.neimanmarcus.com/CCPA?brand=HC';
const mobileTermPolicyStr = 'Site Terms and Privacy Policy';
const hcMobileTermPolicyStr = 'Site Terms and Privacy';
const siteMapStr = 'Sitemap';
const linkClass = 'footer-site-links__brandlink';

export const DumbSiteLinks = (props) => {
  const {
    country,
    accountAccess,
    isPanel,
    assistanceToggle,
    sitemapToggle,
    brand,
    hcAssistanceToggle,
  } = props;
  const isUnitedStates = isCountry(country, UNITED_STATES);
  const shouldShowOptOutLink = () => {
    if (isUnitedStates) {
      return true;
    } return false;
  };
  const divide = (accountAccess && !shouldShowOptOutLink())
    ? null
    : <span className="footer-site-links__divider">{divider}</span>;
  const thisyear = new Date().getFullYear();
  const title = `© ${thisyear}, ${brand === 'HC' ? 'Horchow' : 'Neiman Marcus'}`;
  const countrySensitive = accountAccess && isUnitedStates ? null
    : <CountrySensitiveLink linkClass="footer-site-links__sitemap" urlPath={sitemapToggle ? siteMapTogglePath : siteMapPath} title={siteMapStr} />;
  let urlPath = '';
  let termPolicy = '';
  if (brand === 'HC') {
    urlPath = hcAssistanceToggle ? urlHcTogglePath : assistanceUrlPath;
    termPolicy = hcAssistanceToggle ? 'https://assistance.horchow.com/privacy' : termPolicyPath;
  } else {
    urlPath = assistanceToggle ? urlTogglePath : assistanceUrlPath;
    termPolicy = assistanceToggle ? 'https://assistance.neimanmarcus.com/privacy' : termPolicyPath;
  }
  const dumbProps = { linkClass, urlPath, title };
  return (
    <div className="footer-site-links">
      {
        isPanel
          ? <DumbCountrySensitiveLink {...dumbProps} countryCode={country} />
          : <CountrySensitiveLink {...dumbProps} />
      }
      <span className="footer-site-links__divider">{divider}</span>
      <span>
        <a href={termPolicy} className="footer-site-links__tclink">
          <span className="desktop-tablet-only">{accountAccess ? mobileTermPolicyStr : termPolicyStr}</span>
          <span className="mobile-only">{brand === 'HC' ? hcMobileTermPolicyStr : mobileTermPolicyStr}</span>
        </a>
        {divide}
      </span>
      {
        shouldShowOptOutLink()
        && (
          <>
            <span>
              <a href={brand === 'HC' ? optOutLinkUrl : optOutLinkPath} className="footer-site-links__optoutlink">{optOutLinkStr}</a>
              {!accountAccess
                && (
                  divide
                )
              }
            </span>


          </>
        )
      }
      {
        brand === 'HC' ? (
          <span>
            {props.route === '/login' || props.route === '/register' ? divide : ''}
            <a href={sitemapToggle ? siteMapTogglePath : siteMapPath} className="footer-site-links__optoutlink">{siteMapStr}</a>
          </span>
        ) : null
      }
      {brand === 'HC' ? '' : countrySensitive}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.locale.countryCode,
    brand: state.brand_name?.env,
    route: state.routing?.locationBeforeTransitions?.pathname,
    assistanceToggle: get(state, 'toggles.NEW_ASSISTANCE_UI', false),
    sitemapToggle: get(state, 'toggles.DT_SITEMAP', false),
    hcAssistanceToggle: get(state, 'toggles.HC_ASSISTANCE_UI', false),
  };
};

export default connect(mapStateToProps)(DumbSiteLinks);

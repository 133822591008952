/* eslint import/no-cycle: 0 */
import React from 'react';
import ProductGridComponent from '../Product/ProductGrid';
import Products from '../Product/Products';
import SingleProduct from '../Product/SingleProduct';

const ProductComponent = ({ cmsContentItem = {}, trackTags: propsTrackTags = {} }) => {
  let gridIndex;
  let promoTile;
  let promoTileIndex;
  const {
    fields,
    fields: {
      components,
      type,
      numberOfAssets,
    },
  } = cmsContentItem;
  const promoTiles = components.filter((asset) => asset.contentType === 'promoTileContentAsset');
  // If multiple item list content assets defined, discard all except first
  const itemListContentAsset = components.find((asset, index) => {
    if (asset.contentType === 'itemListContentAsset') {
      gridIndex = index;
      return asset;
    }
    return null;
  });

  switch (type) {
    case 'Product Rail 4x1':
    case 'Product Grid 4x1': {
      // If we add more than 1 promo then we are not adding any promos (bussines request)
      if (promoTiles?.length === 1) {
        promoTile = promoTiles[0];
        promoTileIndex = components.findIndex((component) => component === promoTile);
      }
      if (itemListContentAsset) {
        return (
          <Products
            productIds={itemListContentAsset.fields.ids.slice(0, promoTile ? 3 : 4)}
            contentId={itemListContentAsset.sys.id}
            displayOptions={fields}
            promoTile={promoTile}
            promoTileIndex={promoTileIndex}
            gridIndex={gridIndex}
            trackTags={propsTrackTags}
          />
        );
      }
      return (<div />);
    }
    case 'Product Rail 2x2':
    case 'Product Grid 2x2': {
      // If we add more than 1 promo then we are not adding any promos (bussines request)
      if (promoTiles?.length === 1) {
        promoTile = promoTiles[0];
        promoTileIndex = components.findIndex((component) => component === promoTile);
      }
      if (itemListContentAsset) {
        return (
          <Products
            productIds={itemListContentAsset.fields.ids.slice(0, promoTile ? 3 : 4)}
            contentId={itemListContentAsset.sys.id}
            displayOptions={fields}
            is2x2
            promoTile={promoTile}
            promoTileIndex={promoTileIndex}
            gridIndex={gridIndex}
            trackTags={propsTrackTags}
          />
        );
      }
      return (<div />);
    }
    case 'Product Grid': {
      if (itemListContentAsset) {
        return (
          <ProductGridComponent
            contentId={itemListContentAsset.sys.id}
            idsToSearch={itemListContentAsset.fields.ids}
            promoTiles={promoTiles}
            trackTags={propsTrackTags}
            numberOfAssets={numberOfAssets}
            displayOptions={fields}
          />
        );
      }
      return (<div />);
    }
    case 'Single Product': {
      if (itemListContentAsset) {
        return (
          <SingleProduct
            productId={itemListContentAsset.fields.ids.slice(0, 1)}
            contentId={itemListContentAsset.sys.id}
            displayOptions={fields}
            trackTags={propsTrackTags}
          />
        );
      }
      return (<div />);
    }
    default: {
      return (<div />);
    }
  }
};
export default ProductComponent;

import React from 'react';
import './ShoppableHotspot.scss';
import { connect } from 'react-redux';
import { showQLModalWindow } from 'plp/components/ProductListPage/actions';
import backgroundImage from 'assets/images/shoppableIconBackground.svg';
import foregroundImage from 'assets/images/shoppableIconForeground.svg';
import hotspotImage from 'assets/images/shoppableHotspotIcon.svg';
import get from 'lodash/get';

const ShoppableHotspot = ({
  url: id,
  top,
  left,
  showQLModalWindow,
  isMobilePhone,
}) => (
  <button
    className="shoppable-hotspot--wrapper"
    style={{
      top: `${top}%`,
      left: `${left}%`,
    }}
    onClick={() => showQLModalWindow(id)}
  >
    {isMobilePhone ? (
      <img className="mobile-img" src={hotspotImage} alt="" />
    ) : (
      <>
        <img className="bg-img" src={backgroundImage} alt="" />
        <img className="fg-img" src={foregroundImage} alt="" />
      </>
    )}
  </button>
);

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
});

export default connect(
  mapStateToProps,
  { showQLModalWindow },
)(ShoppableHotspot);

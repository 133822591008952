import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React, { Component } from 'react';
import classNames from 'classnames';
import ConnectedEcmAdaptiveImage from '../EcmAdaptiveImage';
import {
  ecmGetAdaptiveStyleBlock,
  ecmGetImageWidthStyleBlock,
  ecmTextHasAnchor,
  ecmImageHasImageMap,
  ecmGetOptimizedAdaptiveStyleBlock,
} from '../../utilities';
import {
  getCustomStylesForCTADesktop,
  getCustomStylesForCTATablet,
  getCustomStylesForCTAMobile,
} from '../EcmText/textComponentUtilities';
import EcmGetImageWithTextTextBlocks from './EcmGetImageWithTextTextBlocks';

export default class EcmTextWithImage extends Component {
  evaluateImageMaps(ecmDataObj, textHasAnchor, imageHasImageMap) {
    let dataObj = ecmDataObj;
    const linkUrl = (get(dataObj, 'properties.linkurl') === null || isEmpty(get(dataObj, 'properties.linkurl', ''))) ? '' : get(dataObj, 'properties.linkurl');
    if (imageHasImageMap && !textHasAnchor && linkUrl.length > 0) {
      let textObj = get(dataObj, 'text', {});
      const textProps = {
        ...textObj.properties,
        text: `<a href="${dataObj.properties.linkurl}">${textObj.properties.text}</a>`,
        mobileText: `<a href="${dataObj.properties.linkurl}">${textObj.properties.mobileText}</a>`,
        tabletText: `<a href="${dataObj.properties.linkurl}">${textObj.properties.tabletText}</a>`,
      };
      textObj = {
        ...textObj,
        properties: textProps,
      };
      dataObj = {
        ...dataObj,
        text: textObj,
      };
    }
    return dataObj;
  }

  evaluateTextAnchors(ecmDataObj, textHasAnchor, imageHasImageMap) {
    let imgObj = get(ecmDataObj, 'image', {});
    const linkUrl = (get(ecmDataObj, 'properties.linkurl') === null || isEmpty(get(ecmDataObj, 'properties.linkurl', ''))) ? '' : get(ecmDataObj, 'properties.linkurl');
    if ((textHasAnchor || imageHasImageMap) && linkUrl.length > 0) {
      const imgProps = {
        ...imgObj.properties,
        linkurl: ecmDataObj.properties.linkurl,
      };
      imgObj = {
        ...imgObj,
        properties: imgProps,
      };
    }
    return imgObj;
  }

  renderComponent(
    ecmDataObj,
    ecmSlot,
    textHasAnchor,
    imageHasImageMap,
    improvePerformanceOfStyleToggle,
  ) {
    const dataObj = this.evaluateImageMaps(ecmDataObj, textHasAnchor, imageHasImageMap);
    const imgObj = this.evaluateTextAnchors(ecmDataObj, textHasAnchor, imageHasImageMap);
    const textComponentClass = improvePerformanceOfStyleToggle ? `ecm-${get(dataObj, 'text.componentId', {})}` : `ecm-container__text__${get(dataObj, 'text.componentId', {})}`;
    const textAdaptiveStyleBlock = improvePerformanceOfStyleToggle ? ecmGetOptimizedAdaptiveStyleBlock(get(dataObj, 'text.properties.padding', {}), textComponentClass) : ecmGetAdaptiveStyleBlock(get(dataObj, 'text.properties.padding', {}), textComponentClass);
    const imageComponentClass = improvePerformanceOfStyleToggle ? `ecm-${get(dataObj, 'image.componentId', {})}` : `ecm-container__image__${get(dataObj, 'image.componentId', {})}`;
    const imageAdaptiveStyleBlock = improvePerformanceOfStyleToggle
      ? ecmGetOptimizedAdaptiveStyleBlock(get(dataObj, 'image.properties.images', {}), imageComponentClass)
      : ecmGetAdaptiveStyleBlock(get(dataObj, 'image.properties.images', {}), imageComponentClass);
    const textBlocks = EcmGetImageWithTextTextBlocks(
      dataObj,
      ecmSlot,
      improvePerformanceOfStyleToggle
    );
    const buttonOverlay = get(dataObj, 'calltoactionbuttons');
    const callToActionCustomStylesDesktop = get(dataObj, 'text.properties.callToActionCustomStyles.desktop', []);
    const callToActionCustomStylesTablet = get(dataObj, 'text.properties.callToActionCustomStyles.tablet', []);
    const callToActionCustomStylesMobile = get(dataObj, 'text.properties.callToActionCustomStyles.mobile', []);
    const classes = classNames(textBlocks.mobileTextPosition,
      textBlocks.tabletTextPosition,
      textBlocks.dektopTextPosition);
    return (
      <div className={classes}>
        { textAdaptiveStyleBlock }
        { imageAdaptiveStyleBlock }
        { textBlocks.textBefore }
        { textBlocks.mobileTextBefore }
        { textBlocks.tabletTextBefore }
        { textBlocks.desktopTextBefore }
        <div className="textwithimage-image">
          <ConnectedEcmAdaptiveImage
            picture={imgObj}
            ecmSlot={ecmSlot}
            formatAdaptiveStyleBlock={false}
            buttonOverlayElements={buttonOverlay}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          />
        </div>
        { textBlocks.textAfter }
        { textBlocks.mobileTextAfter }
        { textBlocks.tabletTextAfter }
        { textBlocks.desktopTextAfter }
        {
          callToActionCustomStylesDesktop
          && getCustomStylesForCTADesktop(callToActionCustomStylesDesktop)
        }
        {
          callToActionCustomStylesTablet
          && getCustomStylesForCTATablet(callToActionCustomStylesTablet)
        }
        {
          callToActionCustomStylesMobile
          && getCustomStylesForCTAMobile(callToActionCustomStylesMobile)
        }
      </div>
    );
  }

  render() {
    const {
      dataObj,
      ecmSlot,
      improvePerformanceOfStyleToggle,
    } = this.props;

    const divStyle = (isEmpty(get(dataObj, 'properties.bgcolor', ''))) ? {} : { backgroundColor: get(dataObj, 'properties.bgcolor', '') };
    const textHasAnchor = ecmTextHasAnchor(get(dataObj, 'text', {}));
    const imageHasImageMap = ecmImageHasImageMap(get(dataObj, 'image', {}));
    const ecmStyle = get(ecmSlot, 'className', '');
    const ecmComponentId = get(dataObj, 'componentId', '');
    const creativeId = get(dataObj, 'creative_id');
    const ecmProperties = get(dataObj, 'properties', {});
    const ecmObjectStyle = improvePerformanceOfStyleToggle ? `ecm-${ecmComponentId}` : `ecm-container__textwithimage__${ecmComponentId}`;
    const classes = improvePerformanceOfStyleToggle ? classNames('ecm-container__textwithimage optst', ecmStyle, ecmObjectStyle) : classNames('ecm-container__textwithimage', ecmStyle, ecmObjectStyle);
    const adaptiveStyleBlock = improvePerformanceOfStyleToggle
      ? ecmGetOptimizedAdaptiveStyleBlock(ecmProperties, ecmObjectStyle)
      : ecmGetAdaptiveStyleBlock(ecmProperties, ecmObjectStyle);
    const dektopImageWidthValue = get(ecmProperties, 'desktop.imageWidth') === null || isEmpty(get(ecmProperties, 'desktop.imageWidth')) ? 50 : get(ecmProperties, 'desktop.imageWidth');
    const tabletImageWidthValue = get(ecmProperties, 'tablet.imageWidth') === null || isEmpty(get(ecmProperties, 'tablet.imageWidth')) ? dektopImageWidthValue : get(ecmProperties, 'tablet.imageWidth');
    const mobileImageWidthValue = get(ecmProperties, 'mobile.imageWidth') === null || isEmpty(get(ecmProperties, 'mobile.imageWidth')) ? tabletImageWidthValue : get(ecmProperties, 'mobile.imageWidth');
    const imageWidthStyleBlock = ecmGetImageWidthStyleBlock(
      {
        desktop: { imageWidth: Number(dektopImageWidthValue) },
        tablet: { imageWidth: Number(tabletImageWidthValue) },
        mobile: { imageWidth: Number(mobileImageWidthValue) },
      },
      ecmObjectStyle, dataObj
    );
    if (improvePerformanceOfStyleToggle) {
      return (
        <div creativeid={creativeId}>
          { adaptiveStyleBlock }
          <div className={classes} style={divStyle}>
            { imageWidthStyleBlock }
            { this.renderComponent(
              dataObj,
              ecmSlot,
              textHasAnchor,
              imageHasImageMap,
              improvePerformanceOfStyleToggle
            )
              }
          </div>
        </div>
      );
    }
    return (
      <div className={classes} style={divStyle} creativeid={creativeId}>
        { adaptiveStyleBlock }
        { imageWidthStyleBlock }
        { this.renderComponent(
          dataObj,
          ecmSlot,
          textHasAnchor,
          imageHasImageMap,
          improvePerformanceOfStyleToggle
        )
            }
      </div>
    );
  }
}

import React, { PureComponent } from 'react';
import './genderSelectTA.scss';

// eslint-disable-next-line react/prefer-stateless-function
class GenderSelectTA extends PureComponent {
  render() {
    const { onGenderSelected, selectedGender, isTypeAhead = false } = this.props;
    const typeAheadMargin = { marginLeft: '20px' };
    const typeAheadBorder = !isTypeAhead ? { borderBottom: '1px solid #E0E0E0' } : {};
    const typeAheadGenderMargin = isTypeAhead ? { marginLeft: '0px' } : {};

    return (
      <div className="gender-select-container-ta" id="gender-select-container-ta">
        <ul className={isTypeAhead ? 'gender-select-ta gender-select-ta-typeahead' : 'gender-select-ta'} id="gender-select-ta" style={typeAheadBorder}>
          <li
            onClick={() => onGenderSelected('W')}
            onTouchEnd={() => onGenderSelected('W')}
            className={selectedGender === 'W' ? 'active' : undefined}
            style={typeAheadGenderMargin}
          >
            <p id="gender-select">WOMEN</p>
          </li>
          <li
            onClick={() => onGenderSelected('M')}
            onTouchEnd={() => onGenderSelected('M')}
            className={selectedGender === 'M' ? 'active' : undefined}
            style={typeAheadMargin}
          >
            <p id="gender-select">MEN</p>
          </li>
        </ul>
      </div>
    );
  }
}

export default GenderSelectTA;

import find from 'lodash/find';
import React from 'react';
import classNames from 'classnames';
import CloseIcon from 'shared/components/CloseIcon/closeIcon';
import DesignerByCategory from './DesignerByCategory/designerByCategory';
import AtoZGrid from './AtoZGrid/aToZGrid';
import FeaturedDesigners from './FeaturedDesigners/featuredDesigners';
import './designersDrawer.scss';
import ConditionalWrapper from '../Common/helpers';

function getDesignersSubDrawersByName(silo, name) {
  return find(
    silo.categories,
    (category) => {
      return category.name?.toLowerCase() === name.toLowerCase();
    }
  );
}

const DesignersDrawer = ({
  silo,
  closeFunction,
  hidden,
  delayHoverOnSilos = false,
  getPromoContent,
  globalNavUpdateToggle,
}) => {
  const byAtoZData = getDesignersSubDrawersByName(silo, 'BY A-Z');
  const byAtoZ = byAtoZData && AtoZGrid({
    key: 'byAtoZ',
    dataToDisplay: byAtoZData.categories,
    titleToDisplay: byAtoZData.name,
    closeFunction,
    globalNavUpdateToggle,
  });

  const designersByCategoryData = getDesignersSubDrawersByName(silo, 'DESIGNERS BY CATEGORY');
  const designersByCategory = designersByCategoryData && DesignerByCategory({
    key: 'designersByCategory',
    dataToDisplay: designersByCategoryData.categories,
    titleToDisplay: designersByCategoryData.name,
    closeFunction,
  });

  const featuredDesignersData = getDesignersSubDrawersByName(silo, 'FEATURED DESIGNERS');
  const promoBanner = {
    promoPath: silo.attributes.promoPath,
    siloId: silo.id,
  };
  const featuredDesigners = featuredDesignersData && FeaturedDesigners({
    key: 'featuredDesigners',
    dataToDisplay: featuredDesignersData.categories,
    titleToDisplay: featuredDesignersData.name,
    closeFunction,
    promoBanner,
    getPromoContent,
    silo,
  });
  return (
    <section className={classNames('silo-group designer-drawer', { 'drawer-hidden': hidden }, { 'delay-hover-on-silos': delayHoverOnSilos })} id="silo-section">
      <ConditionalWrapper
        condition={globalNavUpdateToggle}
        wrapper={(children) => <div className="silo-parent silo-parent-designer columns">{children}</div>}
      >
        <>
          <div className="grid-25 tablet-grid-30 left-panel">
            {byAtoZ}
            {designersByCategory}
          </div>
          {featuredDesigners}
          {!globalNavUpdateToggle && (
            <div className="drawer-close">
              {CloseIcon({ onClick: () => closeFunction() })}
            </div>
          )}
        </>
      </ConditionalWrapper>
    </section>
  );
};

export default DesignersDrawer;

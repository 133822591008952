import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { shouldLoad } from 'universal/http-client';
// eslint-disable-next-line import/no-cycle
import { getCmsProducts } from 'cms/actions';
// eslint-disable-next-line import/no-cycle
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import ProductTile from './ProductTile';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from '../renderContentItem';
import './ProductTile.scss';

export class DumbCmsProducts extends Component {
  UNSAFE_componentWillMount() {
    const { cmsProductsApi, productIds, contentId } = this.props;
    if (shouldLoad(cmsProductsApi)) {
      this.props.dispatch(getCmsProducts(productIds, contentId));
    }
  }

  combineProducts(cmsProducts, childProducts) {
    return cmsProducts.map((product) => (
      /* eslint-disable max-len */
      product.isGroup && product.childProductIds ? childProducts.find((childProduct) => childProduct.id === product.childProductIds[0]) || product : product));
  }

  shiftProductItems(products, promoTile, promoTileIndex, gridIndex) {
    if (promoTile) {
      const productsCopy = [...products];
      if (promoTileIndex < gridIndex) {
        productsCopy.unshift(promoTile);
      } else {
        productsCopy.push(promoTile);
      }
      return productsCopy;
    }
    return products;
  }

  renderProducts(
    combinedProducts,
    is2x2,
    isMobilePhone,
    displayOptions,
    promoTile,
    promoTileIndex,
    gridIndex,
    trackTags
  ) {
    return this.shiftProductItems(combinedProducts, promoTile, promoTileIndex, gridIndex).map((product) => {
      if (product.contentType === 'promoTileContentAsset') {
        const { content, trackingTags: ownTrackTags = [] } = promoTile.fields;
        const combinedTrackTags = concatTrackTagsObjects(trackTags, getTrackingTags(ownTrackTags));
        const styles = (is2x2 || isMobilePhone)
          ? classNames('product-thumbnail-list promo-tile grid-50 tablet-grid-50 mobile-grid-50')
          : classNames('product-thumbnail-list promo-tile grid-25 tablet-grid-25 mobile-grid-50');
        return (
          <button className={styles}>
            <RenderContentItem trackTags={combinedTrackTags} cmsContentItem={content} />
          </button>
        );
      }
      return (
        <ProductTile
          id={product.id}
          designer={product.designerName}
          name={product.name}
          price={product.price}
          imageUrl={product.imageUrl}
          url={product.url}
          promotions={product.promotions}
          displayable={product.displayable}
          is2x2={is2x2}
          isMobilePhone={isMobilePhone}
          displayOptions={displayOptions}
        />
      );
    });
  }

  render() {
    const {
      is2x2 = false,
      isMobilePhone = false,
      cmsProducts = [],
      childProducts = [],
      displayOptions,
      promoTile,
      promoTileIndex,
      gridIndex,
      trackTags,
    } = this.props;
    let filteredProducts = [];
    if (!isEmpty(childProducts)) {
      filteredProducts = this.combineProducts(cmsProducts, childProducts);
    }
    const combinedProducts = !isEmpty(filteredProducts) ? filteredProducts : cmsProducts;
    return (
      <div className="product-list">
        {!isEmpty(combinedProducts)
          && this.renderProducts(
            combinedProducts,
            is2x2,
            isMobilePhone,
            displayOptions,
            promoTile,
            promoTileIndex,
            gridIndex,
            trackTags
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  cmsProductsApi: state.api[`cms_products_${ownProps.contentId}`.toLowerCase()],
  cmsProducts: state.cms.products?.[ownProps.contentId],
  childProducts: state.cms.childProducts?.[ownProps.contentId],
});


export default connect(mapStateToProps)(DumbCmsProducts);

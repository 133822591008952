import { useEffect, useState } from 'react';
import cookie from 'react-cookie';
import axios from 'axios';

const DEFAULT_PRODUCT_PICKUP_MESSAGES = {
  heading: 'Delivery & Pickup',
  text: 'Select size/color to view delivery and pickup options.',
  headingDelivery: 'Delivery',
  textDelivery: 'for delivery options.',
  linkZip: 'Enter ZIP code',
  radioShip: 'Ship',
  radioDelivery: 'Delivery',
  radioPickup: 'Pickup',
  radioPickupDisabled: 'Pickup',
  deliverySameDay: 'Get it as soon as today if ordered by 12pm CST.',
  deliveryNextDay: 'Get it as soon as tomorrow if ordered by 2pm CST.',
  deliveryTwoDay: 'Get it within 2-3 days.',
  deliveryStandard: 'Get it in 2-4 Days.',
  shippingTxt: 'Free 3-5 day shipping also available at checkout.',
  pickupAvailable: 'Get it today if ordered by 12pm.',
  pickupNotAvailable: 'Get it in 2-4 Days.',
  selectStore: 'Select a store location',
  ineligible: 'Not eligible for pickup',
};

export const useDelivery = (
  serviceLevelCodes,
  skuIds,
  quantity,
  skuId,
  clientLocationZip,
  storeId,
) => {
  const getStoreSelectedCookies = () => {
    const data = cookie.load('dt_selected_zipcode');

    return data ? data.slice(1, -1) : '';
  };

  const zipCodeDelivery = getStoreSelectedCookies() || clientLocationZip;
  const [deliveryShip, setDeliveryShip] = useState();
  const [stores, setStores] = useState([]);

  const getDeliveryCTAText = (deliveryShipData, serviceLevelCodes) => {
    const NEXT_DAY = serviceLevelCodes?.includes('NEXT_DAY');
    const TWO_DAY = serviceLevelCodes?.includes('TWO_DAY');
    const STANDARD = serviceLevelCodes?.includes('STANDARD');

    if (deliveryShipData?.sameDayDelivery?.available) {
      return {
        main: DEFAULT_PRODUCT_PICKUP_MESSAGES.deliverySameDay,
        cutline: DEFAULT_PRODUCT_PICKUP_MESSAGES.shippingTxt,
      };
    }

    if (NEXT_DAY) {
      return {
        main: DEFAULT_PRODUCT_PICKUP_MESSAGES.deliveryNextDay,
        cutline: DEFAULT_PRODUCT_PICKUP_MESSAGES.shippingTxt,
      };
    }
    if (!NEXT_DAY && TWO_DAY) {
      return {
        main: DEFAULT_PRODUCT_PICKUP_MESSAGES.deliveryTwoDay,
        cutline: DEFAULT_PRODUCT_PICKUP_MESSAGES.shippingTxt,
      };
    }
    if (!NEXT_DAY && !TWO_DAY && STANDARD) {
      return {
        main: DEFAULT_PRODUCT_PICKUP_MESSAGES.deliveryStandard,
      };
    }

    return {};
  };

  const getDeliveryShipData = async () => {
    const data = await axios.post('/dt/api/deliveryShip', {
      zipCode: zipCodeDelivery,
      skuIds,
    });

    setDeliveryShip(data);
  };

  const getStoresData = async () => {
    try {
      const { data } = await axios.get(
        `/dt/api/stores?brandCode=nm&freeFormAddress=${encodeURI(
          zipCodeDelivery
        )}&skuId=${skuId}&quantity=${quantity}`,
      );
      setStores(data);
    } catch (err) {
      setStores([]);
    }
  };

  const getStorePickup = () => {
    const dtSelectedStore = cookie.load('dt_selected_store') || '';
    let cookiesStore;
    let storeIdFomCart;

    if (dtSelectedStore) {
      cookiesStore = stores.find((store) => store.storeId === dtSelectedStore);
    }

    if (storeId) {
      storeIdFomCart = stores.find((store) => store.storeNumber === storeId);
    }

    const storeToSet = storeIdFomCart || cookiesStore || stores[0];

    return storeToSet || {};
  };

  useEffect(() => {
    if (NMConfig.BRAND_NAME !== 'HC') {
      getDeliveryShipData();
      getStoresData();
    }
  }, []);

  const storePickup = getStorePickup();
  const deliveryCTAText = getDeliveryCTAText(deliveryShip, serviceLevelCodes);

  return {
    storePickup,
    deliveryCTAText,
  };
};

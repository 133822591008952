import Loadable from 'react-loadable';

const BopsModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-bops' */ './pdp/components/BopsModal/bopsModal'),
  loading: () => false,
});

const BopsModalOptimized = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-bopsOptimized' */ './pdp/components/BopsModalOptimization/bopsModal'),
  loading: () => false,
});

const AddToBagMobileModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-addToBagMobile' */ './pdp/components/AddToBagMobileModal/addToBagMobileModal'),
  loading: () => false,
});

const RegisterEmailErrorModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-registerEmailErrorModal' */ './profile/components/RegisterWithUs/RegisterEmailErrorModal'),
  loading: () => false,
});
const CountrySelectorModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-countrySelector' */ './storefront/components/CountrySelector/CountrySelectorModal/countrySelectorModal'),
  loading: () => false,
});

const GwpMultiSkuModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-gwpMultiSku' */ './pdp/components/GwpModal/gwpMultiSkuModal'),
  loading: () => false,
});

const RestrictedItemsModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-restrictedItems' */ './storefront/components/Header/RestrictedItems/RestrictedItemsModal/restrictedItemsModal'),
  loading: () => false,
});

const CutlineVideoModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-cutlineVideo' */ './pdp/components/CutlineVideoModal/cutlineVideoModal'),
  loading: () => false,
});

const BeautyReplenishmentInfoModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-beautyReplenishmentInfo' */ './pdp/components/BeautyReplenishmentInfoModal/beautyReplenishmentInfoModal'),
  loading: () => false,
});

const ProductListErrorModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-productListError' */ './plp/components/ProductListPage/components/ProductListErrorModal/productListErrorModal'),
  loading: () => false,
});

const MonogramModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-monogram' */ './pdp/components/MonogramModal/monogramModal'),
  loading: () => false,
});

const MonogramModalDropship = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-monogramDropship' */ './pdp/components/MonogramModal/monogramModalDropship'),
  loading: () => false,
});

const ModalWithoutHeader = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-withoutHeader' */ './plp/components/ProductListPage/components/MessageModals/modalWithoutHeader'),
  loading: () => false,
});

const ModalWithHeader = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-withHeader' */ './plp/components/ProductListPage/components/MessageModals/modalWithHeader'),
  loading: () => false,
});

const QLProductSummary = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-ql' */ './plp/components/ProductListPage/components/QuickLook/components/QLProductSummary/qlProductSummary'),
  loading: () => false,
});

const QLOutfittingSummary = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-qlOutfittingSummary' */ './pdp/components/QuickLook/components/QLProductSummary/qlProductSummary'),
  loading: () => false,
});

const AddToBagQLModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-addToBagQl' */ './plp/components/ProductListPage/components/QuickLook/components/AddToBagQlModal/addToBagQLModal'),
  loading: () => false,
});

const TrueFitMultiSizeModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-trueFitMultiSize' */ './pdp/components/TrueFitMultiSizeModal/trueFitMultiSizeModal'),
  loading: () => false,
});
const ForgotPasswordModalDT = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-forgotPasswordModalDT' */ './profile/components/AccountAccess/ForgotPasswordModalDTWrapper'),
  loading: () => false,
});

const AddToBagModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-addToBagModal' */ './pdp/components/AddToBagModal'),
  loading: () => false,
});

const AppointmentsModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-appointmentsModal' */ './pdp/components/Appointments/AppointmentsModal/AppointmentsModal'),
  loading: () => false,
});

const InfoModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-infoModal' */ './cms/components/infoModal/infoModal'),
  loading: () => false,
});

const SearchBoxModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-searchBoxModal' */ './storefront/components/Header/SearchBoxModal/searchBoxModal'),
  loading: () => false,
});

const CollapseSearchModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-collapseSearchModal' */ './storefront/components/Header/SearchBoxModal/collapseSearchModal'),
  loading: () => false,
});

const AppInstallModal = Loadable({
  loader: () => import(/* webpackChunkName: 'modal-appInstallModal' */ './plp/components/ProductListPage/components/MessageModals/appInstallModal'),
  loading: () => false,
});

export const modalSelection = (modalInfo) => {
  if (modalInfo && typeof modalInfo === 'object') {
    if (modalInfo.html && modalInfo.html.indexOf('jwplayer') > -1) {
      return CutlineVideoModal;
    }
    return getModalByType(modalInfo.type);
  }
  return getModalByType(modalInfo);
};

function getModalByType(modalType) {
  switch (modalType) {
    case 'BopsModal':
      return BopsModal;
    case 'BopsModalOptimized':
      return BopsModalOptimized;
    case 'AddToBagMobileModal':
      return AddToBagMobileModal;
    case 'CountrySelectorModal':
      return CountrySelectorModal;
    case 'GwpMultiSkuModal':
      return GwpMultiSkuModal;
    case 'RestrictedItemsModal':
      return RestrictedItemsModal;
    case 'ProductListErrorModal':
      return ProductListErrorModal;
    case 'QLProductSummary':
      return QLProductSummary;
    case 'QLOutfittingSummary':
      return QLOutfittingSummary;
    case 'ModalWithoutHeader':
      return ModalWithoutHeader;
    case 'ModalWithHeader':
      return ModalWithHeader;
    case 'MonogramModal':
      return MonogramModal;
    case 'MonogramModalDropship':
      return MonogramModalDropship;
    case 'AddToBagQLModal':
      return AddToBagQLModal;
    case 'TrueFitMultiSizeModal':
      return TrueFitMultiSizeModal;
    case 'ReplenishmentModal':
      return BeautyReplenishmentInfoModal;
    case 'RegisterEmailErrorModal':
      return RegisterEmailErrorModal;
    case 'ForgotPasswordModalDT':
      return ForgotPasswordModalDT;
    case 'AddToBagModal':
      return AddToBagModal;
    case 'AppointmentsModal':
      return AppointmentsModal;
    case 'InfoModal':
      return InfoModal;
    case 'SearchBoxModal':
      return SearchBoxModal;
    case 'CollapseSearchModal':
      return CollapseSearchModal;
    case 'AppInstallModal':
      return AppInstallModal;
    default:
      return null;
  }
}

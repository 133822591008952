/* eslint import/no-cycle: 0 */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import { editorialNavlinkClick } from 'cms/actions';
import SimpleText from '../simpleText/simpleText';
import './SimpleLocalNav.scss';

const SimpleLocalNav = ({
  isMobilePhone, cmsContentAssets = [], navLinkClick, buttonStyle, disableClickAnalytics,
}) => {
  const handleOnClick = (editorialClass) => {
    if (editorialClass && !disableClickAnalytics) {
      navLinkClick([editorialClass.replace('#', '')]);
    }
  };

  const navItemCN = `simple-local-nav__item${buttonStyle ? ` simple-local-nav__button--${buttonStyle}` : ''}`;

  return (
    <nav className={classnames('simple-local-nav', { 'simple-local-nav__mobile': isMobilePhone })}>
      <ul className={classnames('simple-local-nav__links', { 'simple-local-nav__links__mobile': isMobilePhone })}>
        {
          cmsContentAssets.map((item, index) => {
            return (
              <li
                key={index}
                className={navItemCN}
                onClick={() => handleOnClick(item.fields.link)}
              >
                <SimpleText cmsContentItem={item} key={index} />
              </li>
            );
          })
        }
      </ul>
    </nav>
  );
};

const mapDispatchToProps = (dispatch) => ({
  navLinkClick: (editorialClass) => {
    dispatch(editorialNavlinkClick(editorialClass));
  },
});

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleLocalNav);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Loadable from 'react-loadable';
import get from 'lodash/get';
import EcmText from 'ecm/components/EcmText';
import ConnectedEcmAdaptiveImage from 'ecm/components/EcmAdaptiveImage';
import EcmTextWithImage from 'ecm/components/EcmTextWithImage';
import EcmSiteTicker from 'ecm/components/EcmSiteTicker/ecmSiteTicker';
import EcmTextOverImage from 'ecm/components/EcmTextOverImage/ecmTextOverImage';
import EcmLayoutRow from 'ecm/components/EcmLayoutRow';
import { ecmIsHidden } from 'ecm/utilities';

const EcmRecommendations = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-Recommendations' */ 'ecm/components/EcmRecommendations/ecmRecommendations'),
  loading: () => false,
});

const ConnectedEcmCarousel = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-Carousel' */ 'ecm/components/EcmCarousel'),
  loading: () => false,
});

const EcmStoreEventsLazyLoad = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-StoreEventsLazyLoad' */ 'ecm/components/EcmStoreEvents/ecmStoreEventsLazyLoad'),
  loading: () => false,
});

const EcmVideo = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-Video' */ 'ecm/components/EcmVideo'),
  loading: () => false,
});

const EcmHostedVideo = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-HostedVideo' */ 'ecm/components/EcmHostedVideo'),
  loading: () => false,
});

const EcmSiloThirdParty = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-SiloThirdParty' */ 'ecm/components/EcmSiloThirdParty'),
  loading: () => false,
});

const ConnectedEcmCurratedProducts = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-CurratedProducts' */ 'ecm/components/EcmCurratedProducts'),
  loading: () => false,
});

const EcmIframe = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-Iframe' */ 'ecm/components/EcmIframe'),
  loading: () => false,
});

const ConnectedEcmFacets = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-Facets' */ 'ecm/components/EcmFacets/ecmFacets'),
  loading: () => false,
});

const ConnectedEcmStoryRail = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-StoryRail' */ 'ecm/components/EcmStoryRail'),
  loading: () => false,
});

const ConnectedEcmProductRail = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-ProductRail' */ 'ecm/components/EcmProductRail'),
  loading: () => false,
});

const EcmVisualNav = Loadable({
  loader: () => import(/* webpackChunkName: 'ecm-VisualNav' */ 'ecm/components/EcmVisualNav'),
  loading: () => false,
});

export class EcmColumn extends Component {
  constructor(props) {
    super(props);
    this.curratedEvent = false;
  }

  componentDidMount() {
    if (this.curratedEvent) {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', true, false);
      window.dispatchEvent(event);
    }
  }

  renderComponent() {
    const {
      ecmContent,
      ecmSlot,
      improvePerformanceOfStyleToggle,
      countryCode,
      isMobilePhone,
    } = this.props;

    if (ecmContent && ecmContent.rows) {
      return ecmContent.rows.map((row, i) => (
        <EcmLayoutRow
          key={`ecmLayoutRow_${i}_${row.componentId}`}
          ecmContent={row}
          ecmSlot={ecmSlot}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          countryCode={countryCode}
        />
      ));
    }
    if (ecmContent && ecmContent.componentType) {
      const ecmProperties = get(ecmContent, 'properties', {});
      switch (ecmContent.componentType) {
        case 'video':
          return ecmContent.properties && ecmContent.properties.type === 'hosted'
            ? (
              <EcmHostedVideo
                videoData={ecmContent}
                improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              />
            )
            : (
              <EcmVideo
                videoData={ecmContent}
                improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              />
            );
        case 'text':
          return (
            <EcmText
              text={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'adaptive-image':
          return (
            <ConnectedEcmAdaptiveImage
              picture={ecmContent}
              ecmSlot={ecmSlot}
              buttonOverlayElements={ecmContent.calltoactionbuttons}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'imagewithtext':
          return (
            <EcmTextWithImage
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'carousel':
          if (ecmContent.experience === 'bubble') {
            return (
              <EcmVisualNav
                content={ecmContent}
                ecmSlot={ecmSlot}
                improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
              />
            );
          }
          if (isMobilePhone && ecmIsHidden(get(ecmProperties, 'mobile', {}))) {
            return null;
          }
          if (!isMobilePhone && ecmIsHidden(get(ecmProperties, 'desktop', {}))) {
            return null;
          }
          return (
            <ConnectedEcmCarousel
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'storyrail':
          return (
            <ConnectedEcmStoryRail
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'siteticker':
          return (
            <EcmSiteTicker
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'textoverimage':
          return (
            <EcmTextOverImage
              dataObj={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'iframe':
          return (
            <EcmIframe
              content={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'thirdparty':
          switch (ecmContent.vendor) {
            case 'richrelevance':
              return <EcmRecommendations dataObj={ecmContent} ecmSlot={ecmSlot} />;
            case 'featuredsubcats':
              return <EcmSiloThirdParty />;
            case 'curatedproducts':
              if (countryCode !== 'US' || !ecmContent.catId) {
                return <div />;
              }
              return (
                <ConnectedEcmCurratedProducts
                  key={ecmContent.componentId}
                  navPath={ecmContent.navPath}
                  catId={ecmContent.catId}
                  componentId={ecmContent.componentId}
                />
              );

            case 'facets':
              return <ConnectedEcmFacets content={ecmContent} />;
            default:
              return <div />;
          }
        case 'productrail':
          if (countryCode !== 'US' || !ecmContent.properties.categoryId) {
            return <div />;
          }
          return (
            <ConnectedEcmProductRail
              content={ecmContent}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'storeevents':
          return <EcmStoreEventsLazyLoad dataObj={ecmContent} ecmSlot={ecmSlot} />;
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  }

  render() {
    const classes = classNames('ecm-container__col', this.props.ecmLayout);
    return (
      <div className={classes}>
        {this.renderComponent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
});

export default connect(mapStateToProps)(EcmColumn);

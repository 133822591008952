/* eslint import/no-cycle: 0 */
import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import Loadable from 'react-loadable';
import '../cmsStyles.scss';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import ExpandingCarousel from 'cms/components/expandingCarousel/expandingCarousel';
import Overlap from 'cms/components/Overlap/Overlap';
import { CMSLayouts } from '../constants';
import RenderContentItem from './renderContentItem';
import RenderEditorialSilos from './renderEditorialSilo';
import Products from './Product/Products';
import ProductGridComponent from './Product/ProductGrid';
import LocalNav from './localNav/localNavWrapper';
import SlideShow from './slideShow/slideShow';
import ConnectedAnchorPointsSticky from './anchorPointsSticky/anchorPointsSticky';

const Carousel = Loadable({
  loader: () => import(/* webpackChunkName: 'Carousel' */ './carousel/carousel'),
  loading: () => false,
});

const layoutClassName = (layoutType) => `cms-${layoutType.replace(/\W/g, '-').toLowerCase()}`;

const RenderContentSlot = ({ cmsContentSlot = {} }) => {
  const cmsContentFields = get(cmsContentSlot, 'fields', {});
  const scrollStyle = get(cmsContentSlot, 'fields.scrollStyle');
  const hasParallaxEffect = scrollStyle === 'Image Parallax Effect';

  switch (cmsContentFields.type) {
    case 'Editorial Index':
    case 'Editorial Story':
    case 'Layout Container': {
      const cmsContentSlots = get(cmsContentFields, 'contentSlots', []);
      return (
        <>
          {
            cmsContentSlots.map((item) => (
              <RenderContentSlot cmsContentSlot={item} key={item.sys.id} />
            ))
          }
        </>
      );
    }
    case 'Editorial Silo': {
      const cmsContentSlots = [...get(cmsContentFields, 'contentSlots', [])];
      return (
        <>
          {
            cmsContentSlots.map((item) => (
              <RenderContentSlot cmsContentSlot={item} key={item.sys.id} />
            ))
          }
          <RenderEditorialSilos />
        </>
      );
    }
    case 'Product Rail 4x1': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
      return (
        <>
          {
            cmsContentAssets.map((content, index) => {
              const isItemListContent = get(content, 'contentType') === 'itemListContentAsset';
              if (isItemListContent) {
                const productIds = get(content, 'fields.ids', []);
                const limitProductIds = productIds.slice(0, 4);
                return (
                  <Products
                    productIds={limitProductIds}
                    contentId={content.sys.id}
                    key={index}
                  />
                );
              } return null;
            })
          }
        </>
      );
    }
    case 'Product Rail 2x2': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
      return (
        <>
          {
            cmsContentAssets.map((content, index) => {
              const isItemListContent = get(content, 'contentType') === 'itemListContentAsset';
              if (isItemListContent) {
                const productIds = get(content, 'fields.ids', []);
                const limitProductIds = productIds.slice(0, 4);
                return (
                  <Products
                    productIds={limitProductIds}
                    contentId={content.sys.id}
                    key={index}
                    is2x2
                  />
                );
              } return null;
            })
          }
        </>
      );
    }
    case 'Product Grid': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
      const contentId = cmsContentAssets[0]?.sys?.id;
      const itemListContentAsset = cmsContentAssets
        .find((asset) => asset.contentType === 'itemListContentAsset');
      if (itemListContentAsset) {
        const promoTiles = cmsContentAssets
          .filter((asset) => asset.contentType === 'promoTileContentAsset');
        const idsToSearch = get(cmsContentAssets[0], 'fields.ids', '');
        return (
          <ProductGridComponent
            contentId={contentId}
            idsToSearch={idsToSearch}
            promoTiles={promoTiles}
          />
        );
      }
    }
      break;
    case 'Local Nav': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
      return (
        <LocalNav cmsContentAssets={cmsContentAssets} />
      );
    }
    case 'Anchor Points Sticky': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
      return (
        <ConnectedAnchorPointsSticky cmsContentAssets={cmsContentAssets} />
      );
    }
    case 'Overlap-top':
    case 'Overlap-right':
    case 'Overlap-bottom':
    case 'Overlap-left': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
      const { type } = cmsContentFields;
      return (
        <Overlap cmsContentAssets={cmsContentAssets} position={type} />
      );
    }
    case 'Quiz': {
      const cmsContentSlots = get(cmsContentFields, 'contentSlots', []);
      return (
        <>
          {
            cmsContentSlots.map((item) => (
              <RenderContentSlot cmsContentSlot={item} key={item.sys.id} />
            ))
          }
        </>
      );
    }
    case 'Carousel': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);

      return (
        <Carousel cmsContentItems={cmsContentAssets} />
      );
    }
    case 'Carousel Expanding': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);

      return (
        <ExpandingCarousel cmsContentItems={cmsContentAssets} />
      );
    }
    case 'Site Ticker': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);

      return (
        <SlideShow cmsContentItems={cmsContentAssets} type="site-ticker" />
      );
    }
    case 'Carousel Animation Slow':
    case 'Carousel Animation Fast': {
      const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);

      return (
        <SlideShow cmsContentItems={cmsContentAssets} type="carousel-animation" speedType={cmsContentFields.type} />
      );
    }
    default: {
      const cmsContentLayout = CMSLayouts[cmsContentFields.type];
      if (cmsContentLayout) {
        const cmsContentAssets = get(cmsContentFields, 'contentAssets', []);
        const isFullBleed = cmsContentFields.type.indexOf('Full Bleed') >= 0;
        const isStickyScroll = cmsContentFields.scrollStyle === 'Sticky';
        const isParallaxScroll = cmsContentFields.scrollStyle === 'Parallax';
        const styles = classNames(
          'grid-container',
          'cms-slot',
          isFullBleed ? 'full-bleed' : 'mainContent-large',
          layoutClassName(cmsContentFields.type)
        );
        const renderClone = (cmsContentItem, index) => {
          const cloneOfIndex0 = cloneDeep(cmsContentItem);
          return (
            <div className="clone">
              <RenderContentItem cmsContentItem={cloneOfIndex0} key={index} />
            </div>
          );
        };
        const { anchorName, backgroundColor: bgColor } = cmsContentFields;

        return (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */}
            {anchorName ? <a id={anchorName} className="anchor-point" /> : null}
            <div className={hasParallaxEffect ? 'parallax-item' : 'non-parallax-item'}>
              <div className={styles}>
                {
                  cmsContentLayout.map((gridElement, index) => {
                    const cmsContentItem = get(cmsContentAssets, [index], {});
                    const isSocialVideo = get(cmsContentItem, 'contentType', '') === 'socialVideoContentAsset';
                    const isVideo = get(cmsContentItem, 'contentType', '') === 'videoContentAsset';
                    const isParallax33 = cmsContentAssets.length === 3 && isParallaxScroll;
                    const isParallax50 = cmsContentAssets.length === 2 && isParallaxScroll;
                    const gridStyles = classNames(
                      gridElement,
                      (isStickyScroll || isParallaxScroll) && index === 0 ? 'sticky-scroll' : null,
                      isParallax33 && index === 1 ? 'sticky-scroll' : null,
                      isParallax33 ? 'parallax-33' : null,
                      isParallax50 ? 'parallax-50' : null,
                      isParallaxScroll ? 'parallax-scroll' : null,
                      (isParallaxScroll && isSocialVideo) ? 'social-video-asset' : null,
                      (isParallaxScroll && isVideo) ? 'video-asset' : null,
                    );
                    if (isParallaxScroll && index === 0) {
                      return (
                        <div className={gridStyles} key={uuid()}>
                          {
                            (isVideo || isSocialVideo) && isParallax33
                              ? <div className="video-33"><RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} /></div>
                              // eslint-disable-next-line max-len
                              : <RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} />
                          }
                          {renderClone(cmsContentItem, index)}
                          {isParallax33 && renderClone(cmsContentItem, index)}
                        </div>
                      );
                    }
                    if (isParallaxScroll && index === 1) {
                      return (
                        <div className={gridStyles} key={uuid()}>
                          {renderClone(cmsContentAssets[0], index)}
                          {
                            (isVideo || isSocialVideo) && isParallax33
                              ? <div className="video-33"><RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} /></div>
                              // eslint-disable-next-line max-len
                              : <RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} />
                          }
                          {isParallax33 && renderClone(cmsContentItem, index)}
                        </div>
                      );
                    }
                    if (isParallax33 && index === 2) {
                      return (
                        <div className={gridStyles} key={uuid()}>
                          {renderClone(cmsContentAssets[0], index)}
                          {renderClone(cmsContentAssets[1], index)}
                          {
                            isVideo || isSocialVideo
                              ? <div className="video-33"><RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} /></div>
                              // eslint-disable-next-line max-len
                              : <RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} />
                          }
                        </div>
                      );
                    }
                    return (
                      <div className={gridStyles} key={uuid()}>
                        <RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} />
                      </div>
                    );
                  })
                }
              </div>
            </div>
            {hasParallaxEffect
              ? (
                <div className="non-parallax-item parallax-clone">
                  <div className={styles}>
                    {
                      cmsContentLayout.map((gridElement, index) => {
                        const cmsContentItem = get(cmsContentAssets, [index], {});
                        const isSocialVideo = get(cmsContentItem, 'contentType', '') === 'socialVideoContentAsset';
                        const isVideo = get(cmsContentItem, 'contentType', '') === 'videoContentAsset';
                        const gridStyles = classNames(
                          gridElement,
                          (isStickyScroll || isParallaxScroll) && index === 0 ? 'sticky-scroll' : null,
                          isParallaxScroll ? 'parallax-scroll' : null,
                          (isParallaxScroll && isSocialVideo) ? 'social-video-asset' : null,
                          (isParallaxScroll && isVideo) ? 'video-asset' : null,
                        );
                        if (isParallaxScroll && index === 0) {
                          return (
                            <div className={gridStyles} key={uuid()}>
                              {/* eslint-disable-next-line max-len */}
                              <RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} />
                              {renderClone(cmsContentItem, index)}
                            </div>
                          );
                        }
                        if (isParallaxScroll && index === 1) {
                          return (
                            <div className={gridStyles} key={uuid()}>
                              {renderClone(cmsContentAssets[0], index)}
                              {/* eslint-disable-next-line max-len */}
                              <RenderContentItem bgColor={bgColor} cmsContentItem={cmsContentItem} />
                            </div>
                          );
                        }
                        return (
                          <div className={gridStyles} key={uuid()}>
                            <RenderContentItem
                              bgColor={bgColor}
                              cmsContentItem={cmsContentItem}
                              hasParallaxEffect={hasParallaxEffect}
                            />
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              ) : null}
          </>
        );
      }
    }
  }
  return (<div />);
};

export default RenderContentSlot;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RIGHT_ARROW_KEY, LEFT_ARROW_KEY } from 'client-utils/keyCodes';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import EcmSwitch from '../EcmSwitch';

export class EcmSiteTickerItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoIndex: 0,
      bannerInFocus: false,
      tickerStarted: false,
    };
    this.setFocusToBanner = false;
    this.switchPromo = this.switchPromo.bind(this);
    this.setBannerInFocus = this.setBannerInFocus.bind(this);
    this.checkBannerFocusAndSwitch = this.checkBannerFocusAndSwitch.bind(this);
    this.startTicker = this.startTicker.bind(this);
    this.stopTicker = this.stopTicker.bind(this);
    this.nextPromo = this.nextPromo.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad);
  }

  componentDidUpdate() {
    if (this.state.bannerInFocus
      && this.tickerController
      && this.setFocusToBanner) this.tickerController.focus();
    this.setFocusToBanner = false;
  }

  componentWillUnmount() {
    this.stopTicker();
    clearTimeout(this.timerDelayID);
    window.removeEventListener('load', this.handleLoad);
  }

  onKeyDownHandler(e) {
    if (e.which === RIGHT_ARROW_KEY) {
      this.setFocusToBanner = true;
      this.nextPromo();
    } else if (e.which === LEFT_ARROW_KEY) {
      this.setFocusToBanner = true;
      this.previousPromo();
    }
    this.setBannerInFocus(true);
  }

  setBannerInFocus(inFocus) {
    this.setState({ bannerInFocus: inFocus });
  }

  startTicker() {
    const { dataObj, enableAutoPlay } = this.props;
    const autoplaySpeed = get(dataObj, 'properties.playSpeed') === null || isEmpty(get(dataObj, 'properties.playSpeed')) ? 6000 : get(dataObj, 'properties.playSpeed');
    const { tickerStarted } = this.state;
    if (enableAutoPlay && !tickerStarted) {
      this.timerID = setInterval(this.checkBannerFocusAndSwitch, parseInt(autoplaySpeed, 10));
      this.setState({ tickerStarted: true });
    }
  }

  stopTicker() {
    const { tickerStarted } = this.state;
    if (tickerStarted) {
      clearInterval(this.timerID);
      this.setState({ tickerStarted: false });
    }
  }

  handleLoad() {
    this.timerDelayID = setTimeout(this.startTicker, 2000);
  }

  switchPromo(promoIndex) {
    const { dataObj } = this.props;
    if (dataObj.slides && dataObj.slides.length > 0) {
      let nextIndex;
      if (promoIndex >= 0) {
        nextIndex = (promoIndex) % dataObj.slides.length;
      } else {
        nextIndex = dataObj.slides.length - 1;
      }
      this.setState({
        promoIndex: nextIndex,
      });
    }
  }

  nextPromo() {
    this.switchPromo(this.state.promoIndex + 1);
  }

  previousPromo() {
    this.switchPromo(this.state.promoIndex - 1);
  }

  checkBannerFocusAndSwitch() {
    const { bannerInFocus } = this.state;
    if (!bannerInFocus) {
      this.nextPromo();
    }
  }

  render() {
    const {
      dataObj,
      ecmSlot,
      improvePerformanceOfStyleToggle,
    } = this.props;

    return (
      <div
        tabIndex="0"
        className="ecm-container__siteticker__wrapper"
        ref={(ref) => { this.tickerController = ref; }}
        onMouseEnter={() => this.setBannerInFocus(true)}
        onMouseLeave={() => this.setBannerInFocus(false)}
        onFocus={() => this.setBannerInFocus(true)}
        onBlur={() => this.setBannerInFocus(false)}
        onKeyDown={(e) => this.onKeyDownHandler(e)}
      >
        {
          dataObj.slides[this.state.promoIndex]
          && (
          <EcmSwitch
            ecmContent={dataObj.slides[this.state.promoIndex]}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          />
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enableAutoPlay: (get(state, 'page.location.query.ENABLE_AUTOPLAY', 'true') === 'true'),
});

export default connect(mapStateToProps)(EcmSiteTickerItems);

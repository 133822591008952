import React from 'react';
import closeSVG from './close.svg';
import './closeIcon.scss';

export default function CloseIcon({ onClick, addStyles }) {
  return (
    <button aria-label="Close" className="close" onClick={onClick} style={addStyles}>
      <img
        alt="Close Icon"
        aria-hidden="true"
        className="new-close-icon"
        height="32"
        src={closeSVG}
        width="32"
      />
    </button>
  );
}

import union from 'lodash/union';

export function setBodyClass(className) {
  if (typeof document !== 'undefined') {
    document.body.className = className;
  }
}

export function addBodyClass(toAdd) {
  if (typeof document !== 'undefined') {
    let bodyClasses = document.body.className.split(' ');
    bodyClasses = union(bodyClasses, [toAdd]);
    setBodyClass(bodyClasses.join(' ').trim());
  }
}

export function removeBodyClass(toRemove) {
  if (typeof document !== 'undefined') {
    let bodyClasses = document.body.className.split(' ');
    bodyClasses = bodyClasses.filter((c) => c !== toRemove);
    setBodyClass(bodyClasses.join(' ').trim());
  }
}

import React, { useRef, useState, useEffect } from 'react';
import './searchModal.scss';
import '../../bloomreach.scss';
import getLinkDomain from 'client/utilities/getlinkDomain';
import { SearchSuggestions } from 'client/srp/components/atoms/SearchSuggestions/SearchSuggestions';
import { connect } from 'react-redux';
import { setTypeaheadUtag } from 'client/common/components/App/app-actions';
import { setGenderTA } from 'client/srp/actions/actions';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import { getGenderWithOverride } from 'client/utilities/utilities-gender';
import get from 'lodash/get';
import {
  setPlaceholderValue,
  setPlaceHolderValueToEmpty,
  setRecentlySearchedAnalytics,
  setSearchTermValueFromPlaceholder,
  updateSearchTerm,
} from '../../search-actions';
import {
  getPlaceholderValue,
  getSearchTerm,
  getTypeaheadUIConf,
  typeaheadSuggestionPayload,
} from '../../search-reducers';
import { searchFormSubmit } from '../../submitHelper';
import {
  DOWN_KEY,
  ENTER_KEY,
  ESC_KEY,
  getRecentSearch,
  UP_KEY,
} from '../../../utils/HeaderUtils';
import closeSVG from '../../../../../../assets/images/close-icon.svg';

const storageKey = 'SRP_SEARCH_RECENT_KEYWORDS';
const getLink = getLinkDomain();

const SearchModal = ({
  closeSearchModal,
  collapsedSearchExperience,
  setPlaceHolderValueToEmpty,
  handleOnFocus,
  handleOnChange,
  brand,
  setPlaceholderValue,
  searchSuggestionsToggle,
  searchTerm,
  placeholder,
  setRecentlySearchedAnalytics,
  typeaheadUIConf,
  isTypeaheadSuggestionEmpty,
  setTypeaheadUtag,
  dtGender,
  setGenderTA,
  taSuggestionPayload,
}) => {
  const submitRef = useRef();
  const inputRef = useRef();
  const suggestionsRef = useRef();
  const searchEnterTypeRef = useRef();

  const [cursorIndex, setCursorIndex] = useState(-1);
  const [recentSearchHover, setRecentSearchHover] = useState(false);

  const defaultPlaceholder = 'What are you looking for?';

  const handleSelectionOnRecentSearch = (value) => {
    if (value) {
      setRecentlySearchedAnalytics(true);
      setPlaceholderValue(value);
      handleOnChange(value);
    } else {
      setRecentSearchHover(false);
      setCursorIndex(0);
    }

    setTimeout(() => {
      if (submitRef?.current) {
        submitRef.current.click();
      }
    }, 200);
  };

  const changeGender = (gender) => {
    window.sessionStorage.setItem('ta_gender', gender);
    setGenderTA(gender);
  };

  const handleClickOutside = (event) => {
    if (submitRef?.current && !submitRef.current.contains(event.target)) {
      changeGender(dtGender);
    }
  };

  const handleAnalytics = () => {
    if (brand !== 'HC' && searchEnterTypeRef?.current?.value !== 'bloomreach') {
      const recentSearches = localStorage
        ? JSON.parse(localStorage.getItem(storageKey))
        : [];

      if (inputRef?.current?.placeholder && inputRef.current.placeholder !== 'Search'
        && inputRef.current.placeholder !== defaultPlaceholder
        && recentSearches.includes(inputRef?.current?.placeholder)) {
        window.sessionStorage.setItem('recently_searched', true);
      } else {
        setRecentlySearchedAnalytics(false);
      }
    }
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current.focus();
    }

    if (!placeholder && searchTerm) {
      setPlaceholderValue(searchTerm);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('beforeunload', handleAnalytics);
    };
  }, []);

  useEffect(() => {
    if (
      placeholder !== defaultPlaceholder
      && typeof window !== 'undefined'
      && searchTerm === placeholder
      && window.$
      && window.BRAutosuggest
    ) {
      window.BRAutosuggest.search(searchTerm);
    }
  }, [searchTerm, placeholder]);


  const onSubmit = (e) => {
    searchFormSubmit(e);
  };

  const onInputFocus = (e) => {
    e.preventDefault();

    setPlaceHolderValueToEmpty();

    const { placeholder, value } = e.target;
    if (
      placeholder
      && placeholder !== 'Search'
      && placeholder !== defaultPlaceholder
      && !value
    ) {
      handleOnFocus(placeholder, value);
      setPlaceholderValue(placeholder);
    }
  };

  const onRecentSearchHover = (hoverStatus) => {
    setRecentSearchHover(hoverStatus);
  };

  const handleTextChange = (value) => {
    handleOnChange(value);
    setPlaceholderValue(value);
    setCursorIndex(-1);
  };

  const handleChange = (e) => {
    let limit = [];
    const { suggestions = [] } = taSuggestionPayload || {};

    const recentSearches = typeof localStorage !== 'undefined'
      ? JSON.parse(localStorage.getItem(storageKey)) || [] : [];
    limit = getRecentSearch(recentSearches);
    if (searchTerm !== '' && suggestions.length) {
      limit = suggestions.slice(0, 10).map((item) => {
        const suggestion = item.parameters?.query || '';
        return suggestion;
      });
    } else if (typeof localStorage !== 'undefined') {
      limit = getRecentSearch(recentSearches);
    }

    if (e.keyCode === ENTER_KEY && typeof localStorage !== 'undefined') {
      saveToLocalStorage(
        storageKey,
        JSON.stringify([placeholder, ...recentSearches])
      );
    }

    if (e.keyCode === ENTER_KEY && getLink) {
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
      window.location.href = `${getLink}/s/?from=brSearch&responsive=true&request_type=search&search_type=keyword&q=${placeholder}&l=${placeholder}&fl`;
    }

    if (e.keyCode === UP_KEY && cursorIndex > 0 && searchSuggestionsToggle) {
      if (
        document.getElementsByClassName('brm-autosuggest-menu').length > 0
        && searchSuggestionsToggle && recentSearchHover
        && searchTerm === ''
      ) {
        document
          .getElementsByClassName('brm-autosuggest-menu')[0]
          .getElementsByTagName('ul')[0].innerHTML = '';
      }
      setCursorIndex(cursorIndex - 1);
      setPlaceholderValue(limit[cursorIndex - 1]);
    } else if (e.keyCode === DOWN_KEY && limit !== null) {
      if (cursorIndex < limit.length - 1) {
        if (
          document.getElementsByClassName('brm-autosuggest-menu').length > 0
          && searchSuggestionsToggle && recentSearchHover
          && searchTerm === ''
        ) {
          document
            .getElementsByClassName('brm-autosuggest-menu')[0]
            .getElementsByTagName('ul')[0].innerHTML = '';
        }
        setCursorIndex(cursorIndex + 1);
        setPlaceholderValue(limit[cursorIndex + 1]);
      }

      if (cursorIndex === limit.length - 1) {
        setCursorIndex(0);
        setPlaceholderValue(limit[0]);
      }
    } else if (e.keyCode === ESC_KEY) {
      setRecentSearchHover(false);

      if (inputRef?.current) {
        inputRef?.current.blur();
      }
    }
  };

  const searchSuggestionsHandler = () => {
    return (
      <div
        className="recent-search-renderer modal-recent-search-container"
        onMouseEnter={() => onRecentSearchHover(true)}
        onMouseLeave={() => onRecentSearchHover(false)}
      >
        <SearchSuggestions
          closeRecentSearchOnClear={onRecentSearchHover}
          handleSelection={handleSelectionOnRecentSearch}
          arrowIndex={cursorIndex}
          forwardRef={suggestionsRef}
          typeaheadData={typeaheadUIConf}
          typeaheadSuggestionPayload={taSuggestionPayload}
          noResults={isTypeaheadSuggestionEmpty}
          searchTerm={searchTerm}
          setTypeaheadUtag={setTypeaheadUtag}
          storageKey={storageKey}
          onListClick={() => {}}
          brand={brand}
          collapsedSearchExperience={collapsedSearchExperience}
        />
      </div>
    );
  };

  return (
    <div className="search-panel-overlay desktop-tablet-only">
      <div className="search-panel-container">
        <form
          ref={submitRef}
          className="searchmodal-form"
          action="/search.jsp"
          onSubmit={(e) => onSubmit(e)}
          method="GET"
        >
          <div className="searchmodal-searchbar">
            <input
              type="button"
              name="searchmodal-icon"
              aria-label="searchmodal-icon"
              className="searchmodal-icon"
            />
            <input
              ref={inputRef}
              type="text"
              title="Search"
              autoComplete="off"
              id="brSearchInput"
              name="searchmodal-input"
              className="searchmodal-input"
              aria-label="Modal Search Input"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={searchTerm}
              placeholder={placeholder || defaultPlaceholder}
              onFocus={(e) => onInputFocus(e)}
              onKeyDown={(e) => handleChange(e)}
              onChange={(e) => handleTextChange(e.target.value)}
            />
            <input
              ref={searchEnterTypeRef}
              type="hidden"
              id="searchEnterType"
              value="bloomreach"
            />
            <button
              className="searchmodal-close"
              aria-label="searchmodal-close"
              name="searchmodal-close"
              onClick={(e) => closeSearchModal(e)}
            >
              Close
              <img
                src={closeSVG}
                alt="Close Icon"
                className="close-icon"
                height="24"
                width="24"
              />
            </button>
          </div>
          <div className="make-relative br-search-static pt-0" />
          {searchSuggestionsHandler()}
        </form>
        <div
          className="collapsible-searchbar-overlay"
          onClick={(e) => closeSearchModal(e)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    taSuggestionPayload: typeaheadSuggestionPayload(state),
    dtGender: getGenderWithOverride(state),
    isTypeaheadSuggestionEmpty: get(state, 'search.isTypeaheadSuggestionEmpty', false),
    typeaheadUIConf: getTypeaheadUIConf(state),
    placeholder: getPlaceholderValue(state),
    searchTerm: getSearchTerm(state),
    brand: state.brand_name.env,
    searchSuggestionsToggle: get(state, 'toggles.RECENTLY_SEARCHED', false),
  };
};

const mapDispatchToProps = {
  setPlaceHolderValueToEmpty,
  handleOnChange: updateSearchTerm,
  handleOnFocus: setSearchTermValueFromPlaceholder,
  setPlaceholderValue,
  setRecentlySearchedAnalytics,
  setTypeaheadUtag,
  setGenderTA,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);

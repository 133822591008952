/* eslint react/no-danger: 0 */
import React from 'react';
import classnames from 'classnames';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import './promotions.scss';

const getValidHtml = (itemHTML) => itemHTML || '';

const innerHTML = (itemHTML, promotionalPrice, currencyCode) => {
  const validHtmlText = getValidHtml(itemHTML);
  return promotionalPrice
    ? `${validHtmlText} ${renderFormattedPrice(promotionalPrice, currencyCode)}`
    : validHtmlText;
};
const getStyle = (promoColor, promotionalPrice) => ({ color: promoColor && promotionalPrice ? `#${promoColor}` : undefined });

const getClassNames = (promotionalPrice, enhancementToggle, isUIPRICTest) => classnames('product-thumbnail__promotions__element', {
  'product-thumbnail__promotions__element--priced': promotionalPrice,
}, {
  enhanced: isUIPRICTest || enhancementToggle,
});

const innerHTMLForPromoWithPrice = (promoText, promotionalPrice) => {
  const validHtmlText = getValidHtml(promoText);
  return promotionalPrice
    ? `${validHtmlText} ${promotionalPrice}`
    : validHtmlText;
};

const Promotions = ({
  promotions = [],
  currencyCode,
  promotionalPrice,
  isPLP,
  advertisePromos = [],
  promoColor,
  promoText,
  plpSRPPricingToggle,
  enhancementToggle,
  isUIPRICTest,
}) => (
  isPLP
    ? (
      <div className={classnames('product-thumbnail__promotions', { 'price-enhancement': isUIPRICTest || plpSRPPricingToggle })}>
        {(promoText || promotionalPrice) && (
        <div
          style={getStyle(promoColor, promotionalPrice)}
          className={getClassNames(promotionalPrice, enhancementToggle, isUIPRICTest)}
          dangerouslySetInnerHTML={{
            __html: innerHTMLForPromoWithPrice(promoText, promotionalPrice),
          }}
        />
        )}
        {advertisePromos.map((advPromoText, index) => (
          <div
            key={index}
            style={getStyle(promoColor, promotionalPrice)}
            className={getClassNames(promotionalPrice, enhancementToggle, isUIPRICTest)}
            dangerouslySetInnerHTML={{
              __html: innerHTMLForPromoWithPrice(advPromoText, promotionalPrice),
            }}
          />
        ))}
      </div>
    )
    : (
      <div className={classnames('product-thumbnail__promotions', { 'price-enhancement': isUIPRICTest || plpSRPPricingToggle })}>
        {promotions.map((promotion, index) => {
          const { templateHTML, thumbnailPromoColor } = promotion;
          const style = { color: thumbnailPromoColor && promotionalPrice ? `#${thumbnailPromoColor}` : undefined };
          const className = classnames('product-thumbnail__promotions__element', {
            'product-thumbnail__promotions__element--priced': promotionalPrice,
          }, {
            enhanced: isUIPRICTest || enhancementToggle,
          });
          return (
            <div
              key={index}
              style={style}
              className={className}
              dangerouslySetInnerHTML={{
                __html: innerHTML(templateHTML, promotionalPrice, currencyCode),
              }}
            />
          );
        })}
      </div>
    )
);

export default Promotions;

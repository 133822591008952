import React from 'react';
import classNames from 'classnames';
import CloseIcon from 'shared/components/CloseIcon/closeIcon';
import Column from '../Common/Column/column';
import buildArrayOfCategoriesByColumnBreakFlg from '../drawerUtility';
import './fullWidthDrawer.scss';

const StandardDrawer = ({
  silo,
  style,
  closeFunction,
  hidden,
  delayHoverOnSilos = false,
  getPromoContent,
  getPlpSinglePage,
  globalNavUpdateToggle,
  isContentTaggedForTestGroup,
}) => {
  const arrayOfCategoriesGrouped = buildArrayOfCategoriesByColumnBreakFlg(silo.categories);
  let arrayOfColumns = arrayOfCategoriesGrouped
    .map((categories, index) => Column({ key: index, categories },
      getPlpSinglePage, closeFunction));
  if (globalNavUpdateToggle && isContentTaggedForTestGroup) {
    arrayOfColumns = arrayOfColumns.slice(0, 2);
  }

  const promoBannerJsx = getPromoContent() ? <div className="silo-column"><div className="cms-frame">{getPromoContent()}</div></div> : false;
  const columnCount = promoBannerJsx ? arrayOfColumns.length + 1 : arrayOfColumns.length;
  const newPromoWidth = globalNavUpdateToggle && isContentTaggedForTestGroup && promoBannerJsx;

  if (delayHoverOnSilos) {
    return (
      <section
        className={classNames('silo-group full-width-drawer delay-hover-on-silos', { 'drawer-hidden': hidden })}
        id="silo-section"
        data-silo-columns={columnCount}
      >
        {globalNavUpdateToggle ? (
          <div className="silo-parent flex-container">

            {arrayOfColumns}

            <div className={classNames('silo-column-promo-group', newPromoWidth && 'promo-width')}>
              { getPromoContent() && <div className="cms-frame">{getPromoContent()}</div> }
            </div>
          </div>
        ) : (
          <>
            {arrayOfColumns}
            {promoBannerJsx}
            <div className="gradient-cover" />
            <div className="drawer-close">
              <CloseIcon onClick={() => closeFunction()} />
            </div>
          </>
        )}
      </section>
    );
  }
  return (
    <section
      style={style}
      className={classNames('silo-group full-width-drawer', { 'drawer-hidden': hidden })}
      id="silo-section"
      data-silo-columns={columnCount}
    >
      {globalNavUpdateToggle ? (
        <div className="silo-parent flex-container">

          {arrayOfColumns}

          <div className={classNames('silo-column-promo-group', newPromoWidth && 'promo-width')}>
            { getPromoContent() && <div className="cms-frame">{getPromoContent()}</div> }
          </div>
        </div>
      ) : (
        <>
          {arrayOfColumns}
          {promoBannerJsx}
          <div className="gradient-cover" />
          <div className="drawer-close">
            <CloseIcon onClick={() => closeFunction()} />
          </div>
        </>
      )}
    </section>
  );
};

export default StandardDrawer;

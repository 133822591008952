/* eslint import/no-cycle: 0 */
import { connect } from 'react-redux';
import get from 'lodash/get';
import React from 'react';
import './featuredSubCatComponent.scss';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import RenderContentItem from '../renderContentItem';

export const FeaturedSubCatComponent = ({
  cmsContentItem: {
    fields: { components: componentItem, trackingTags: ownTrackTags } = {},
  } = {},
  isMobilePhone,
  trackTags: propsTrackTags,
}) => {
  const L4LayoutWidth = isMobilePhone ? 'width-100' : 'width-50';
  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const renderContentLayout = (contentItems, isMobilePhone, trackTags) => (
    <>
      {contentItems.slice(1).map((item, i) => (
        <div key={i} className="l5-layout layout-center width-50 featured-image-padding">
          <div className="component">
            <RenderContentItem
              cmsContentItem={item}
              isMobilePhone={isMobilePhone}
              key={item.sys.id}
              trackTags={trackTags}
            />
          </div>
        </div>
      ))}
    </>
  );
  return (
    <>
      <div className="l3-layout layout-space">
        <div className={`l4-layout layout-space ${L4LayoutWidth}`}>
          <div className="component">
            <RenderContentItem
              cmsContentItem={componentItem[0]}
              isMobilePhone={isMobilePhone}
              key={componentItem[0].sys.id}
              trackTags={combinedTrackTags}
            />
          </div>
        </div>
        <div className={`l4-layout layout-space ${L4LayoutWidth}`}>
          {renderContentLayout(componentItem, isMobilePhone, combinedTrackTags)}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone', false),
});
export default connect(mapStateToProps)(FeaturedSubCatComponent);

import React from 'react';
import CollapseCloseIcon from 'assets/images/header-close-icon.svg';
import './closeIcon.scss';

export default function CloseIcon({ onClick }) {
  return (
    <div className="modal-view__close-icon">
      <span className="close-text" onClick={onClick}>Close</span>
      <img
        alt="Close Icon"
        aria-hidden="true"
        className="new-close-icon"
        height="24"
        src={CollapseCloseIcon}
        width="24"
        onClick={onClick}
      />
    </div>
  );
}

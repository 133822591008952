import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { logout } from '../../../utilities/amplifyUtils';

import '@nmg/ui-footer/build/main.css';

const NMFooter = Loadable({
  loader: () => import('@nmg/ui-footer/build/nmFooter'),
  loading: () => false,
});

const HCFooter = Loadable({
  loader: () => import('@nmg/ui-footer/build/hcFooter'),
  loading: () => false,
});

// eslint-disable-next-line react/prefer-stateless-function
class FooterComponent extends Component {
  render() {
    const { footerData, brand, device } = this.props;
    return brand === 'HC' ? (
      <HCFooter
        isFullWidth
        isDomLoadComplete
        toggles={footerData.toggles}
        locale={footerData.locale}
        NMConfig={NMConfig}
        brand={brand}
      />
    )
      : (
        <NMFooter
          isFullWidth
          isDomLoadComplete
          toggles={footerData.toggles}
          locale={footerData.locale}
          NMConfig={NMConfig}
          brand={brand}
          logout={() => logout(footerData.toggles?.DISABLE_ATG_LOGIN)}
          device={device}
          isIOS={device?.isIOSDevice}
        />
      );
  }
}

const mapStateToProps = (state) => {
  const footerData = {
    locale: {
      countryCode: get(state, 'locale.countryCode'),
      localeUrl: get(state, 'locale.localeUrl'),
      currencyCode: get(state, 'locale.currencyCode'),
    },
    toggles: get(state, 'toggles'),
  };
  const brand = get(state, 'brand_name.env');
  const device = get(state, 'device');

  return {
    footerData,
    brand,
    device,
  };
};

export default connect(mapStateToProps)(FooterComponent);

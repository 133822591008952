import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import classnames from 'classnames';
import window from 'window-or-global';
import { setGender } from 'shared/components/App/app-actions';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { ABTEST_EXPOSE_TOP_NAV } from 'client/common/actions/actions-page';
import './genderSwitcher.scss';

const genderSwitcher = ({
  setGender,
  genderToggle,
  selectedGender,
  brand,
  mobileScrollMenu,
  rebrand = false,
}) => {
  const onGenderSelected = (gender) => {
    setGender(gender);
    window.location.href = gender === 'W' ? '/' : '/mens';
  };

  if (brand !== 'HC' && genderToggle && !mobileScrollMenu) {
    return (
      <ul className={classnames('gender-switcher-container', { rebrand })}>
        <li
          onClick={() => onGenderSelected('W')}
          className={selectedGender === 'W' ? 'active' : undefined}
        >
          WOMEN
        </li>
        <li
          onClick={() => onGenderSelected('M')}
          className={selectedGender === 'M' ? 'active' : undefined}
        >
          MEN
        </li>
      </ul>
    );
  }

  return null;
};

const mapDispatchToProps = ({
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HOME),
});

const mapStateToProps = (state) => {
  return {
    genderToggle: get(state, 'toggles.HP_GENDER'),
    selectedGender: getGenderWithOverride(state),
    brand: state.brand_name.env,
    mobileScrollMenu: (get(state, 'toggles.EXPOSE_TOP_NAV', false) || get(state, `abTestsOpt.${ABTEST_EXPOSE_TOP_NAV}.variation`, 'a') === 'b') && get(state, 'locale.countryCode') === 'US' && get(state, 'page.pageId', '') === 'PAGE_ID_HOME',
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(genderSwitcher);

/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import cookie from 'react-cookie';
import axios from 'axios';
import get from 'lodash/get';
import find from 'lodash/find';
import { unescape } from 'he';
import { referralSourceClicked } from 'shared/actions/actions-page';
import { openProductPanel } from 'cms/actions';
import { countryCurrencyData } from 'client/storefront/components/CountrySelector/CountryChooser/countryCurrencyData';
import './stylyzeCarouselComponent.scss';
import { checkForICIDAndAddTags } from '../utils';


export class StylyzeCarouselComponent extends Component {
  constructor(props) {
    super(props);
    this.observer = null;
    this.stylyzeRef = React.createRef();
    this.state = {
      stylyzeProducts: null,
      stylyzeTitle: null,
      stylyzeSubTitle: null,
      isFallback: false,
    };
  }

  async componentDidMount() {
    const options = {
      root: null,
      rootMargin: '0px',
    };
    /* eslint-disable compat/compat */
    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !this.state.isFallback) {
        this.fetchStylyzeProducts();
      }
    }, options);
    this.observer.observe(this.stylyzeRef.current);
  }

  onClickHandler(e, product) {
    e.preventDefault();
    const componentId = get(this.props, 'cmsContentItem.sys.id') || '';
    const { cmsContentItem } = this.props;
    const useStylyzeTitle = get(cmsContentItem, 'fields.useStylyzeTitle');
    const carouselName = useStylyzeTitle || this.state.isFallback
      ? this.state.stylyzeTitle : cmsContentItem?.fields?.title || '';
    try {
      referralSourceClicked('Product_Rail', 'product', '', carouselName);
      this.props.dispatch(openProductPanel({
        ids: this.state.isFallback ? product.id : product.subProductId,
        componentId,
        ppOpener: null,
        panelDescription: '',
        carouselName,
      }));
    } catch (e) {
      const url = this.state.isFallback ? product.url : product.canonicalUrl;
      window.location.href = url;
    }
  }

  getCountryName(countryCode) {
    const defaultCountry = {
      countryName: 'United States',
    };
    const country = find(
      countryCurrencyData,
      (country) => country.countryCode === countryCode
    ) || defaultCountry;
    return country.countryName;
  }

  fetchStylyzeProducts = async () => {
    const placement = get(this.props, 'cmsContentItem.fields.placement');
    const gender = get(this.props, 'cmsContentItem.fields.gender');
    if (this.observer) this.observer.unobserve(this.stylyzeRef.current);
    const ucaProfileDataCookie = cookie.load('ucaProfileData') || {};
    const cstmr = cookie.load('cstmr') || {};
    /* eslint-disable camelcase */
    const { universal_customer_id, logged_in_status } = ucaProfileDataCookie;
    const groups = cookie.load('_optanalytics') || '';
    const reqParams = {
      channel: 'NMO',
      placement,
      ucaId: universal_customer_id,
      cmdId: cstmr?.cmdId,
      isGuest: !logged_in_status,
      customerId: universal_customer_id,
      groups,
      gender,
    };
    try {
      const { data } = await axios.get(NMConfig.API_STYLYZE_RECS,
        { params: reqParams });
      if (data?.products && data?.products.length) {
        this.setState({ stylyzeTitle: data.masterApiLabel });
        const displayableProducts = data.products.filter((p) => p.displayable);
        if (displayableProducts.length > 4) {
          this.setState({ stylyzeProducts: displayableProducts });
        } else this.fetchCategoryProducts();
      } else {
        this.fetchCategoryProducts();
      }
    } catch (error) {
      this.fetchCategoryProducts();
    }
  };

  async fetchCategoryProducts() {
    try {
      const { data } = await axios.get(`${NMConfig.API_CURATED_PRODUCT_LIST}?categoryId=${this.props.categoryId}&fetchSize=10&mainShotType=m`);
      const { products } = data;
      if (products.length) {
        const categoryProducts = products.filter((prod) => prod.displayable && prod);
        categoryProducts.splice(10);

        this.setState({
          stylyzeProducts: categoryProducts,
          stylyzeTitle: 'Best Sellers',
          stylyzeSubTitle: 'SHOP ALL BEST SELLERS',
          isFallback: true,
        });
      }
    } catch (error) {
      console.error('Unable to fetch category products', error);
    }
  }

  render() {
    const { cmsContentItem, key, trackTags } = this.props;
    const useStylyzeTitle = get(cmsContentItem, 'fields.useStylyzeTitle');
    const cta = get(cmsContentItem, 'fields.cta') || null;
    const bgColor = get(cmsContentItem, 'fields.backgroundColor');
    const ownTrackTags = get(cmsContentItem, 'fields.trackingTags', []);

    const carouselSettings = () => {
      return {
        arrows: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              infinite: false,
              slidesToShow: 2.5,
              slidesToScroll: 2,
              draggable: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              arrows: false,
              infinite: false,
              slidesToShow: 3.5,
              slidesToScroll: 3,
              draggable: true,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              arrows: true,
              slidesToShow: 5,
              slidesToScroll: 5,
            },
          },
        ],
      };
    };

    const displayPrice = (product) => {
      const promotionalDollarOff = product.promotions?.length && product.promotions[0].dollarOff;
      const promotionalPercentOff = product.promotions?.length
        && product.promotions[0].percentOff;
      const itemPrice = product.price?.promotionalPrice;
      const priceAdornments = product.price?.adornments;
      const onSale = product.onSale;
      const retailPrice = product.price?.retailPrice;
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      let formattedRetailPrice = formatter.format(retailPrice);
      let formattedItemPrice = formatter.format(itemPrice);
      if (parseInt(formattedRetailPrice.split('.')[1], 10) === 0) formattedRetailPrice = formattedRetailPrice.split('.')[0];
      if (parseInt(formattedItemPrice.split('.')[1], 10) === 0) formattedItemPrice = formattedItemPrice.split('.')[0];

      /* eslint-disable no-nested-ternary */
      const promoPrices = () => (promotionalDollarOff > 0 && promotionalPercentOff > 0 ? (
        <>
          {Number(promotionalPercentOff).toFixed()}
          <span>% Off: </span>
          <span>$ Off: </span>
        </>
      ) : promotionalDollarOff > 0 ? (
        <>
          <span>$ Off: </span>
        </>
      ) : promotionalPercentOff > 0 ? (
        <>
          {Number(promotionalPercentOff).toFixed()}
          <span>% Off: </span>
        </>
      ) : (<></>));

      const adornmentPrice = () => {
        priceAdornments.reverse();
        return priceAdornments.map((priceAdornment, index) => (
          <div className={`price ${index !== 0 ? 'discount' : 'new'}`}>
            {parseInt(formatter.format(priceAdornment.price).split('.')[1], 10) === 0 ? formatter.format(priceAdornment.price).split('.')[0] : formatter.format(priceAdornment.price)}
          </div>
        ));
      };

      if (promotionalDollarOff > 0 || promotionalPercentOff > 0) {
        return (
          <>
            <div>
              {' '}
              {priceAdornments?.length ? adornmentPrice() : formattedRetailPrice}
              {' '}
            </div>
            <div className="price-discount">
              <div>
                {promoPrices()}
                {' '}
                {formattedItemPrice}
              </div>
            </div>
          </>
        );
      }

      if (priceAdornments?.length) {
        return adornmentPrice();
      }

      if (!onSale) {
        return (
          <div className="price">
            {formattedRetailPrice}
          </div>
        );
      }

      return <></>;
    };

    const displayStylyzePrice = (product) => {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
      const displayPrice = product.price?.displayPrice;
      const regularPrice = product.price?.regularPrice;
      const salePrice = product.price?.salePrice;
      const promotionalPrice = product.price?.promotionalPrice;
      let formattedDisplayPrice = formatter.format(displayPrice);
      let formattedPromoPrice = formatter.format(promotionalPrice);
      if (parseInt(formattedDisplayPrice.split('.')[1], 10) === 0) formattedDisplayPrice = formattedDisplayPrice.split('.')[0];
      if (parseInt(formattedPromoPrice.split('.')[1], 10) === 0) formattedPromoPrice = formattedPromoPrice.split('.')[0];

      const promo = product.price?.promotions?.filter((p) => {
        return p?.lineItemHtml;
      });

      const promoPrices = () => {
        if (promo) {
          return (
            <>
              {promo.map((p) => {
                const promoText = unescape(p.lineItemHtml);
                if (isHTML(promoText)) return (<div className="promo-text" dangerouslySetInnerHTML={{ __html: promoText }} />);
                return (
                  <div className="promo-text">
                    $
                    {promoText}
                  </div>
                );
              })}
            </>
          );
        }
        return <></>;
      };

      const adornmentPrice = (newPrice) => {
        return (
          <>
            <div className="price new">
              {parseInt(formatter.format(newPrice).split('.')[1], 10) === 0 ? formatter.format(newPrice).split('.')[0] : formatter.format(newPrice)}
            </div>
            <div className="price discount">
              {parseInt(formatter.format(regularPrice).split('.')[1], 10) === 0 ? formatter.format(regularPrice).split('.')[0] : formatter.format(regularPrice)}
            </div>
          </>
        );
      };

      if (promotionalPrice && promo && regularPrice) {
        return (
          <>
            <div>
              {' '}
              {adornmentPrice(promotionalPrice)}
              {' '}
            </div>
            <div className="price-discount">
              {promoPrices()}
            </div>
          </>
        );
      }

      if (salePrice && regularPrice) {
        return adornmentPrice(salePrice);
      }

      if (displayPrice && promo) {
        return (
          <>
            <div className="price">
              {formattedDisplayPrice}
            </div>
            <div className="price-discount">
              {promoPrices()}
            </div>
          </>
        );
      }

      if (displayPrice) {
        return (
          <div className="price">
            {formattedDisplayPrice}
          </div>
        );
      }

      return <></>;
    };

    const renderProduct = (product, index) => {
      const productImage = this.state.isFallback ? product.media?.main?.dynamic?.url
        : product.imageUrl;
      const designerName = this.state.isFallback ? product.designer.name : product.brand;
      const maxChar = this.props.isMobilePhone ? 20 : this.props.isTablet ? 35 : 50;
      let productName = this.state.isFallback ? product.name : product.title;
      productName = productName.length > maxChar ? `${productName.slice(0, maxChar - 3)}...` : productName;
      const linkTo = checkForICIDAndAddTags(product.canonicalUrl,
        trackTags, ownTrackTags);

      return (
        <div className="cart-item-tile" role="group" key={`tile-${index}`} aria-label={`${index} / ${this.state?.stylyzeProducts?.length}`}>
          <a href={linkTo} onClick={(e) => this.onClickHandler(e, product)} tabIndex="0" className="cart-item-tile__link">
            <img className="cart-item-tile__img" src={productImage} alt={productName} />
          </a>
          <div className="cart-item-tile__designer">{designerName}</div>
          <div className="cart-item-tile__product">{productName}</div>
          <div className="cart-item-tile__price">
            {this.state.isFallback ? displayPrice(product) : displayStylyzePrice(product)}
          </div>
        </div>
      );
    };

    return (
      <div className="stylyze-carousel" style={{ backgroundColor: bgColor }} ref={this.stylyzeRef} key={key}>
        <div className="title">
          <h5>
            {useStylyzeTitle || this.state.isFallback
              ? this.state.stylyzeTitle : cmsContentItem?.fields?.title || ''}
          </h5>
          {!useStylyzeTitle && !this.state.isFallback && !cta && <p>{cmsContentItem?.fields?.subTitle || ''}</p>}
          {!useStylyzeTitle && !this.state.isFallback && cta && <a href={cta} aria-label={`View ${cmsContentItem?.fields?.subTitle}`}>{cmsContentItem?.fields?.subTitle}</a>}
          {this.state.isFallback && this.state.stylyzeSubTitle && <a href={`/c/${this.props.categoryId}`}>{this.state.stylyzeSubTitle}</a>}
        </div>
        <div
          className="carousel-section"
        >
          {/* eslint-disable prefer-spread */}
          <Slider {...carouselSettings()}>
            {this.state.stylyzeProducts?.map((product, index) => {
              return renderProduct(product, index);
            })}
            {!this.state.stylyzeProducts && Array.apply(null, Array(5)).map((v, i) => <div className="carousel-section__skeleton" key={i} />)}
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const categoryId = ownProps.cmsContentItem?.fields?.fallbackCategoryId;
  return {
    cmsCategoryProducts: state.cms.productsByCategory,
    isMobilePhone: get(state, 'device.isMobilePhone'),
    isTablet: get(state, 'device.isTablet'),
    categoryId,
    cmsCategoryProductsApi: state.api[`cms_currated_products_${categoryId}`.toLowerCase()],
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(StylyzeCarouselComponent);

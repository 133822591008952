import React, { Component } from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
// eslint-disable-next-line import/no-cycle
import RenderContentSlot from './renderContentSlot';
// eslint-disable-next-line import/no-cycle
import { setStoryClassification } from '../actions';
import { CMSSiloLayoutTypes } from '../constants';


export class RenderEditorialSilo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.layout = null;
  }

  // eslint-disable-next-line react/sort-comp
  dispatchClassification = () => {
    this.props.dispatch(setStoryClassification(window.location.hash.replace('#', '')));
  };

  componentDidMount() {
    this.dispatchClassification();
    this.setState({ loading: false });
    window.addEventListener('hashchange', this.dispatchClassification);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.dispatchClassification);
  }

  render() {
    const { editorialStories = [], classification, entryId } = this.props;

    const filteredStories = () => editorialStories.filter((item) => {
      const editorialStoryMetaContentAsset = get(item, 'fields.metaContentAsset', []);
      const editorialStoryWithClassification = get(editorialStoryMetaContentAsset, 'fields.classification', []); // [beauty]
      return editorialStoryWithClassification.includes(classification);
    });

    const sortedStories = (stories) => {
      if (entryId) {
        return sortBy(stories, (item) => {
          return item.sys.id === entryId ? 0 : 1;
        });
      }

      return stories;
    };

    const storiesToDisplay = classification ? filteredStories() : editorialStories;

    const renderEditorialStory = ((item, index) => {
      const editorialStoryContentSlots = get(item, 'fields.contentSlots', []);
      const editorialStorySummary = find(editorialStoryContentSlots, { fields: { type: 'Editorial Summary' } });
      const editorialStorySummaryContentSlots = get(editorialStorySummary, 'fields.contentSlots', []);
      const CMSSiloLayoutType = CMSSiloLayoutTypes[index % CMSSiloLayoutTypes.length];
      const editorialStorySummaryContent = find(editorialStorySummaryContentSlots,
        { fields: { type: CMSSiloLayoutType.layout } });
      const editorialStorySummaryContentElement = { ...get(editorialStorySummaryContent, 'fields.contentAssets[0]', {}) };

      if (editorialStorySummaryContent) {
        if (!this.layout) {
          this.layout = {
            ...editorialStorySummaryContent,
            fields: { ...editorialStorySummaryContent.fields },
          };
          this.layout.fields.contentAssets = [];
        }

        this.layout.fields.contentAssets.push(editorialStorySummaryContentElement);

        if (CMSSiloLayoutType.write || storiesToDisplay.length === index + 1) {
          const renderContentSlot = <RenderContentSlot cmsContentSlot={this.layout} key={index} />;
          this.layout = null;
          return (
            <>
              {renderContentSlot}
            </>
          );
        }
        return (
          null
        );
      }

      return (
        null
      );
    });

    return (
      <>
        {!this.state.loading
          && sortedStories(storiesToDisplay).map((item, index) => {
            return (
              <>
                {renderEditorialStory(item, index)}
              </>
            );
          })
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editorialStories: state.cms.stories,
    classification: state.cms.classification,
    entryId: state.page.location.query.entryid,
  };
};

export default connect(mapStateToProps)(RenderEditorialSilo);

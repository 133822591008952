import React, { Component } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { ECMLayouts } from 'ecm/constants';
import { dynamicGridLayout } from 'ecm/utilities';
import ConnectedEcmColumn from '../EcmColumn';

export default class EcmRow extends Component {
  renderColumns() {
    const {
      ecmContent,
      ecmSlot,
      improvePerformanceOfStyleToggle,
      countryCode,
    } = this.props;

    let grid;
    if (ecmContent.layout === 'Z') {
      grid = dynamicGridLayout(ecmContent);
    } else if (isEmpty(ECMLayouts[ecmContent.layout])) {
      grid = ECMLayouts.A;
    } else {
      grid = ECMLayouts[ecmContent.layout];
    }

    while (grid.length < ecmContent.columns.length) grid.push(ECMLayouts.A[0]);
    return (
      ecmContent.columns.map((col, index) => (
        <ConnectedEcmColumn
          ecmContent={col}
          ecmSlot={ecmSlot}
          key={index}
          ecmLayout={grid[index]}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          countryCode={countryCode}
        />
      ))
    );
  }

  render() {
    const { ecmContent } = this.props;
    const layoutClass = `ecm-container__layout-${get(ecmContent, 'layout', 'A')}`;
    const classes = classNames(layoutClass, 'ecm-container__row grid-100 mobile-grid-100 tablet-grid-100 grid-parent');
    return (
      <div className={classes}>
        { this.renderColumns() }
      </div>
    );
  }
}

/* eslint import/no-cycle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import Marquee from 'react-fast-marquee';
import RichText from 'cms/components/richText/richText';
import './TextMarquee.scss';
import { connect } from 'react-redux';

const devices = {
  d: 'desktop',
  t: 'tablet',
  m: 'mobile',
};
const charWidthInPx = 7;
const defaultWrapperStyles = {
  overflow: 'hidden',
};

const defaultMarqueeOptions = {
  gradient: false,
  direction: 'left',
  speed: 100,
};

const calcMaxTextLength = (device, item) => {
  const getDeviceTextL = (device) => get(item, `fields.${device}Text.content[0].content[0].value`, '').length;

  if (device === 'tablet') return getDeviceTextL(devices.t) || getDeviceTextL(devices.d);
  if (device === 'mobile') return getDeviceTextL(devices.m) || getDeviceTextL(devices.t) || getDeviceTextL(devices.d);
  return getDeviceTextL(devices.d);
};

const TextMarquee = (props) => {
  const {
    cmsContentAssets: [item],
    options = defaultMarqueeOptions,
    wrapperStyles = defaultWrapperStyles,
    device,
  } = props;

  const maxTextLength = calcMaxTextLength(device, item);
  const ref = useRef();
  const [numberOfItemsToAttach, setNumberOfItemsToAttach] = useState(1);

  useEffect(() => {
    if (ref.current) {
      const numberOfItemsToAttach = ref.current.offsetWidth / (maxTextLength * charWidthInPx);
      if (numberOfItemsToAttach > 0) setNumberOfItemsToAttach(Math.ceil(numberOfItemsToAttach));
    }
  }, [ref.current]);

  const itemsToRender = Array.from({ length: numberOfItemsToAttach }, (i, idx) => (
    <RichText key={idx} cmsContentItem={item} />
  ));

  return (
    <div ref={ref} className={`rich-text-marquee-wrapper${device === 'mobile' ? ' mobile' : ''}`}>
      <Marquee style={wrapperStyles} {...options}>
        {itemsToRender}
      </Marquee>
    </div>
  );
};

const mapStateToProps = (state) => {
  const isDesktop = get(state, 'device.isDesktop');
  const isTablet = get(state, 'device.isTablet');
  const isMobile = get(state, 'device.isMobilePhone');

  let device;
  if (isDesktop) device = 'desktop';
  if (isTablet) device = 'tablet';
  if (isMobile) device = 'mobile';

  return {
    device,
  };
};

export default connect(mapStateToProps)(TextMarquee);

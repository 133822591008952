/* eslint import/no-cycle: 0 */
import React from 'react';
import VideoAsset from '../video/videoAsset';
import ImageWithText from '../imageWithText/imageWithText';
import { concatTrackTagsObjects, getTrackingTags } from '../utils';
import './VideoBackground.scss';

function VideoBackground({
  cmsContentItem = {},
  trackTags: propsTrackTags = {},
}) {
  const {
    video,
    textWithImage = [],
    trackingTags: ownTrackTags = [],
    contentWidth,
  } = cmsContentItem?.fields;
  const combinedTrackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const containerStyleObj = contentWidth ? { width: `${contentWidth}%`, 'justify-content': 'space-between' } : null;
  const numberOfElements = textWithImage.length ? textWithImage.length : 1;
  const elementWidth = parseInt((100 - ((numberOfElements - 1) * 5)) / numberOfElements, 10);
  const elementStyleObj = { width: `${elementWidth}%` };
  return (
    <div className="cms-video-background">
      <div className="cms-video-container">
        <VideoAsset cmsContentItem={video} />
      </div>
      <div
        style={containerStyleObj}
        className="cms-iwt-container"
      >
        {textWithImage.map((item, index) => (
          <div
            style={elementStyleObj}
            key={index}
            className="cms-iwt-item"
          >
            <ImageWithText
              cmsContentItem={item}
              trackTags={combinedTrackTags}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoBackground;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as reactCookie from 'react-cookie';
import { isCountry } from 'client-utils/utilities-country';
import { UNITED_STATES } from 'storefront/components/constants';
import { setRevisitUser } from 'shared/components/App/app-actions';

class WelcomeMatModal extends Component {
  static welcomeMatUrl(countryCode) {
    return NMConfig.WELCOME_MAT_BASE_URL.replace('{country_code}', countryCode);
  }

  componentDidMount() {
    if (this.showWelcomeMat(this.props.countryCode)) {
      this.attachScript();
    }
    this.userVisited();
  }

  shouldComponentUpdate(nextProps) {
    return this.props.countryCode !== nextProps.countryCode;
  }

  isFirstTimeUser() {
    return !this.props.revisitUser;
  }

  showWelcomeMat(countryCode) {
    return this.isFirstTimeUser() && !isCountry(countryCode, UNITED_STATES);
  }

  userVisited() {
    reactCookie.save('revisitUser', true, { path: '/' });
    this.props.setRevisitUser(true, this.props.toggles);
  }

  attachScript() {
    const welcomeMatScript = document.createElement('script');
    welcomeMatScript.src = WelcomeMatModal.welcomeMatUrl(this.props.countryCode);
    welcomeMatScript.async = true;
    document.body.appendChild(welcomeMatScript); // eslint-disable-line compat/compat
  }


  render() {
    const { countryCode } = this.props;
    if (this.showWelcomeMat(countryCode)) {
      return (
        <div id="welcome-mat" />
      );
    }
    return false;
  }
}

const mapStateToProps = (state) => ({
  revisitUser: state.user.revisitUser,
  countryCode: state.locale.countryCode,
  toggles: state.toggles,
});

const mapDispatchToProps = ({
  setRevisitUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeMatModal);

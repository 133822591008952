import React from 'react';
import './miniCartErrorModal.scss';

export default ({ checkoutUrl }) => (
  <div id="cartlinkspandivgutter" className="error-modal header-drop-down layer m-popUp-minicart">
    <button className="cart-close-x" title="Close" aria-label="Close">&times;</button>
    <div className="header-drop-down-content" id="miniCartDisplayDiv">
      <p>To review your items, please visit your shopping bag.</p>
      <a id="shopping-bag-link" href={checkoutUrl || NMConfig.CHECKOUT_PAGE_URL}>
        {' '}
        View shopping bag
      </a>
    </div>
  </div>
);

import React, { useState, useEffect, useMemo } from 'react';
import window from 'window-or-global';
import classnames from 'classnames';
import { referralSourceClicked } from 'shared/actions/actions-page';
import styleAdvisor from 'assets/images/style_advisor.svg';
import signInIcon from 'assets/images/Signin_Icon.svg';
import Notification from '../../../../common/components/YourNeimans/components/Account/Notification';
import './YourNeimansCTA.scss';

const YourNeimansCTA = ({
  brand,
  firstName,
  isMobilePhone,
  numNotifications,
  hcAssistanceToggle,
  specialOffersPrompt,
  offersData,
  minimalGlobalNav,
  isHNRightToggle,
  isDRToggleOn,
  isNmNewHeader,
}) => {
  let result;
  const sessionKey = useMemo(() => !window.sessionStorage.getItem('specialOffers'));
  const [loadOffersPrompt, setLoadOffersPrompt] = useState(sessionKey);
  const signInStyleIcon = isNmNewHeader ? signInIcon : styleAdvisor;
  const signInText = `Sign ${isNmNewHeader ? 'in' : 'In'}`;

  const toggleSpecialOffers = () => {
    setLoadOffersPrompt(false);
    window.sessionStorage.setItem('specialOffers', true);
  };
  useEffect(() => {
    if (specialOffersPrompt && loadOffersPrompt && (!firstName || offersData?.message)) {
      window.addEventListener('click', () => {
        toggleSpecialOffers();
      });
    }
    if (window.location.pathname === '/specialoffers') {
      setLoadOffersPrompt(false);
    }
  }, [specialOffersPrompt, loadOffersPrompt, firstName, offersData?.message]);

  const sendOffersAnalytics = (messageCode = '') => {
    const analyticsObj = {
      prev_page_link: 'Personalized Offer',

      prev_link_type: 'header message',

      message_code: messageCode || 'SOM-001',
    };
    referralSourceClicked(
      'Personalized Offer',
      'header message',
      analyticsObj
    );
  };

  if (brand === 'HC') {
    result = (!isMobilePhone
      && (
        <li className="your-neimans-cta">
          <a
            className="your_assistance_hc"
            href={
              hcAssistanceToggle
                ? 'https://assistance.horchow.com'
                : 'https://www.horchow.com/assistance/assistance.jsp'
            }
          >
            ASSISTANCE
          </a>
        </li>
      ));
  } else {
    result = (
      <div className={classnames('your-neimans-cta-main', isHNRightToggle && 'menu-alignment')}>
        {
          !isDRToggleOn && (
          <button
            id="your-neimans-cta"
            aria-label={
            firstName
              ? `Welcome ${firstName} - Your Neiman's Account Panel`
              : "Sign In and Register - Your Neiman's Account Panel"
          }
            className="your-neimans-cta"
            onClick={() => window.YourNeimans.toggle()}
          >
            <div className="your-neimans-cta-img-container">
              {numNotifications > 0 && (
              <Notification
                num={numNotifications}
                overrideClass="your-neimans-notification"
              />
              )}
              <img
                alt="Your Neiman's"
                aria-hidden="true"
                src={signInStyleIcon}
                data-tip
                data-for="specialOfferTip"
              />
            </div>
            {!(minimalGlobalNav && isMobilePhone) && (firstName ? (
              <>
                <span className="your-neimans-welcome">Welcome,</span>
              &nbsp;
                {firstName}
              </>
            ) : (signInText)
            )}
          </button>
          )
        }

        {specialOffersPrompt && loadOffersPrompt && !firstName && (
          <div className="tooltip">
            <div
              role="button"
              className="close-tooltip"
              onClick={toggleSpecialOffers}
            />

            <div
              role="button"
              onClick={() => {
                sendOffersAnalytics();
                window.location.href = '/login?navpath=specialOffers';
              }}
            >
              <span className="underlined">Sign In</span>
              <span> to see offers just for you.</span>
            </div>
          </div>
        )}
        {specialOffersPrompt
          && loadOffersPrompt
          && firstName
          && offersData?.message && (
            <div className="tooltip">
              <div
                role="button"
                className="close-tooltip"
                onClick={toggleSpecialOffers}
              />
              <div
                role="button"
                onClick={() => {
                  sendOffersAnalytics(offersData?.analyticsCode);
                  window.location.href = '/specialoffers';
                }}
              >
                <span>
                  {' '}
                  {offersData?.message}
                </span>
              </div>
            </div>
        )}
      </div>
    );
  }
  return result;
};
export default YourNeimansCTA;

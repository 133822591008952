import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { openModal } from 'shared/components/Modal/actions';
import {
  getModalContent, setActiveEntryId,
} from 'client/cms/actions/index';

export function getLinkTarget(target, siteTickerToggle) {
  let elIterator = 0;
  let anchorElement;
  let currentElement = target;

  if (
    target.getAttribute('data-modal-url')
    || target.getAttribute('data-modal-entry-id')
  ) { return target; }

  if (siteTickerToggle) {
    while (currentElement && elIterator < 5) {
      if (currentElement.tagName && currentElement.tagName.toLowerCase() === 'a') {
        anchorElement = currentElement;
        break;
      }
      elIterator += 1;
      currentElement = currentElement.parentElement;
    }
    if (anchorElement && anchorElement.getAttribute('data-modal-url')) {
      return anchorElement;
    }
  }
  return target.parentElement;
}

export function getLinkUrl(args) {
  const { aemAbTestUsergroup = '', session = {}, el = {} } = args;
  let abTestUserGroup = get(aemAbTestUsergroup, 'variation', '');
  if (session.personalize && session.dt_personalize_preview) {
    const dtPersonalizePreview = JSON.parse(session.dt_personalize_preview);
    if (!isEmpty(dtPersonalizePreview.aemAbTestUsergroup)) {
      abTestUserGroup = dtPersonalizePreview.aemAbTestUsergroup;
    }
  }
  return (
    abTestUserGroup === 'Test' && el.getAttribute('data-modal-url-alt')
      ? el.getAttribute('data-modal-url-alt') : el.getAttribute('data-modal-url')
  );
}

export function getLinkEntryId(el) {
  return el.getAttribute('data-modal-entry-id');
}

export function onModalAnchorClick(e, props) {
  const { cpToggle, siteTickerToggle } = props;
  let creativeId;
  let href;
  let hrefElement;
  let currentElement;
  let i = 0;
  const el = getLinkTarget(e.target, siteTickerToggle);
  if (el) {
    if (cpToggle) {
      currentElement = el;
      while (currentElement && !creativeId && i < 10) {
        if (currentElement.tagName && currentElement.tagName.toLowerCase() === 'a') {
          hrefElement = currentElement;
          href = hrefElement.getAttribute('href');
        }
        if (currentElement.getAttribute('creativeid')) {
          creativeId = currentElement.getAttribute('creativeid');
        }
        i += 1;
        currentElement = currentElement.parentElement;
      }
      if (hrefElement && creativeId) {
        if (href.indexOf('?') > -1) {
          hrefElement.setAttribute('href', `${href}&creative_id=${creativeId}`);
        } else {
          hrefElement.setAttribute('href', `${href}?creative_id=${creativeId}`);
        }
      }
    }
    const linkEntryId = getLinkEntryId(el);
    const url = getLinkUrl({ ...props, el });
    const width = el.getAttribute('data-modal-width');
    if (linkEntryId) {
      props.dispatch(getModalContent(linkEntryId));
      props.dispatch(setActiveEntryId(linkEntryId));
      props.dispatch(openModal({
        type: 'InfoModal',
        width,
      }));
    } else if (url) {
      if (url.indexOf('timetrade.com') >= 0) {
        if (!get(props.device, 'isMobile')) e.preventDefault();
        el.setAttribute('href', url);
        el.setAttribute('target', '_blank');
        if (typeof tt === 'undefined') {
          const b = document;
          const c = 'script';
          const d = document.createElement(c);
          d.src = '//cdn.timetrade.com/scripts/lightbox/latest/lightbox.js';
          d.async = true;
          const a = b.getElementsByTagName(c)[0];
          a.parentNode.insertBefore(d, a);
          d.addEventListener('load', () => window.tt && window.tt.launchWorkflow(el));
        } else {
          window.tt && window.tt.launchWorkflow(el);
        }
      } else {
        e.preventDefault();
        props.dispatch(openModal({ url, width }));
      }
    }
  }
}

export function getPageTitle(title, seoTitle) {
  const trimmedSeoTitle = seoTitle?.trim();
  const trimmedTitle = title?.trim();
  return trimmedSeoTitle || trimmedTitle || null;
}

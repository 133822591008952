import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  getECMRequest,
  getECMRequestWithCPPersonalization,
} from '../../actions/actions-ecmcontent';
import EcmSimple from '../EcmSimple';
import EcmComplex from '../EcmComplex';
import EcmFallback from '../EcmFallback/ecmFallback';
import './styles.scss';
import './inline-styles.scss';

class EcmGet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fallback: false,
    };
  }

  componentDidMount() {
    const { ecmContent, ecmSlot } = this.props;
    if (isEmpty(ecmContent[ecmSlot.contentId]) && ecmSlot.fallback.length > 0) {
      this.setState({ fallback: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const apiProp = `ecm${this.props.ecmSlot.contentId.toLowerCase()}`;
    const api = this.props.api[apiProp];

    return (this.state.fallback !== nextState.fallback)
      || !isEqual(api, nextProps.api[apiProp]);
  }

  UNSAFE_componentWillMount() {
    const {
      api,
      dispatch,
      ecmSlot,
      catId = '',
      coherentPathPersonalization,
      ecmPreview,
      productId = '',
    } = this.props;

    if (ecmSlot.personalize
      && coherentPathPersonalization
      && isEmpty(ecmPreview)
      && shouldLoad(api[`ecm${ecmSlot.contentId.toLowerCase()}`])) {
      dispatch(getECMRequestWithCPPersonalization(ecmSlot, catId));
      return;
    }
    if (shouldLoad(api[`ecm${ecmSlot.contentId.toLowerCase()}`])) {
      dispatch(getECMRequest(ecmSlot, catId, '', productId));
    }
  }

  renderGet() {
    const {
      ecmContent,
      ecmSlot,
      improvePerformanceOfStyleToggle,
      countryCodeLocale,
      dtPersonalizePreview,
      personalize,
    } = this.props;

    const countryCodePersonalize = dtPersonalizePreview && get(JSON.parse(dtPersonalizePreview), 'countryCode', '');
    const countryCode = (personalize && countryCodePersonalize)
      ? countryCodePersonalize : countryCodeLocale;

    switch (ecmContent[ecmSlot.contentId].layoutType) {
      case 'simple':
        return (
          <EcmSimple
            ecmContent={ecmContent[ecmSlot.contentId]}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          />
        );
      case 'complex':
        return (
          <EcmComplex
            ecmContent={ecmContent[ecmSlot.contentId]}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            countryCode={countryCode}
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const { ecmContent, ecmSlot, defaultValue } = this.props;
    const containerId = `ecm-container-${ecmSlot.contentId}`;

    if (this.state.fallback) {
      return (
        <div className="ecm-fallback">
          <EcmFallback fallback={ecmSlot.fallback} />
        </div>
      );
    }

    if (ecmContent[ecmSlot.contentId] && ecmContent[ecmSlot.contentId].layoutType) {
      return (
        <div className="ecm-container" id={containerId}>
          {this.renderGet()}
        </div>
      );
    }
    if (defaultValue) {
      return (
        <div>
          {defaultValue}
        </div>
      );
    }
    return false;
  }
}

const mapStateToProps = (state) => ({
  api: state.api,
  ecmContent: state.ecm.ecmContent,
  coherentPathPersonalization: (state.toggles.COHERENT_PATH_PERSONALIZATION && state.locale.countryCode === 'US'),
  ecmPreview: get(state.api, 'requestContext.ECMPreview', ''),
  improvePerformanceOfStyleToggle: get(state, 'toggles.IMPROVE_PERFORMANCE_OF_STYLE', false),
  countryCodeLocale: state.locale.countryCode,
  personalize: get(state, 'session.personalize', ''),
  dtPersonalizePreview: get(state, 'session.dt_personalize_preview', ''),
});

export default connect(mapStateToProps)(EcmGet);

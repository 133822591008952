import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import favIcon from 'assets/images/favorite.svg';
import favIconActive from 'assets/images/favorite-active.svg';
import favIconHover from 'assets/images/favorites_over.svg';
import { addFavorite, removeFavorite } from 'plp/components/ProductListPage/actions';
import { clearQRFromUtag } from 'srp/actions/actions';
import { isTouchDevice } from 'client-utils/utilities-page';
import screenReaderAnnouncer from 'storefront/components/ScreenReaderAlert/screenReaderAnnouncer-actions';
import './favoriteButton.scss';

import './inline-favoriteButton.scss';

class FavoriteButton extends Component {
  constructor(props) {
    super();
    this.state = {
      icon: props.isFavorite ? favIconActive : favIcon,
      text: '',
    };

    this.toggleFavorite = this.toggleFavorite.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isFavorite !== nextProps.isFavorite) {
      let text;
      let icon;
      if (nextProps.isFavorite) {
        icon = favIconActive;
        text = 'Added to favorites';
        this.props.dispatch(
          screenReaderAnnouncer('Added')
        );
        setTimeout(() => {
          this.setState({ text: '' });
        }, 1500);
      } else {
        icon = favIcon;
        text = '';
        this.props.dispatch(
          screenReaderAnnouncer('Removed')
        );
      }
      this.setState({ icon, text });
    }
  }

  mouseOver() {
    if (!this.props.isFavorite && !isTouchDevice()) {
      this.setState({
        icon: favIconHover,
        text: 'Add to favorites',
      });
    }
  }

  mouseOut() {
    if (!this.props.isFavorite && !isTouchDevice()) {
      this.setState({
        icon: favIcon,
        text: '',
      });
    }
  }

  toggleFavorite(e) {
    e.preventDefault();
    if (!this.props.isFavorite) {
      this.props.dispatch(
        addFavorite(
          this.props.productId,
          this.props.cmosCatalogId,
          this.props.cmosItem,
          false,
          this.props.isDisplayAsGroups
        )
      );
    } else {
      this.props.dispatch(
        removeFavorite(
          this.props.productId,
          this.props.cmosCatalogId,
          this.props.cmosItem,
          false,
          this.props.isDisplayAsGroups
        )
      );
    }
    this.props.dispatch(
      clearQRFromUtag()
    );
  }

  toolTip() {
    return (
      <div
        aria-hidden="true"
        className={classnames('favorite-tooltip favorite-tooltip-text-right')}
      >
        {this.state.text}
      </div>
    );
  }

  renderToolTip() {
    if (this.state.text !== '') {
      return this.toolTip();
    }
    return null;
  }

  render() {
    return (
      <div className="fav-button-container">
        <div className={
          classnames('favorite-container-with-ql__modern favorite-button-top-left')
        }
        />
        {this.renderToolTip()}
        <button
          aria-label={this.props.isFavorite ? 'Remove from favorites' : 'Add to favorites'}
          className={
          classnames('fav-product-btn fav-product-btn-top-left')
        }
          onClick={this.toggleFavorite}
          onMouseOut={this.mouseOut}
          onMouseOver={this.mouseOver}
          onFocus={this.mouseOver}
          onBlur={this.mouseOut}
        >
          <img
            className={
            classnames('product-favorite-icon product-favorite-icon-top-left')
          }
            alt="Favorite"
            src={this.state.icon}
          />
        </button>
      </div>
    );
  }
}

export default connect()(FavoriteButton);

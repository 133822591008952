/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { openModal } from 'client/common/components/Modal/actions';
import {
  openPopOut, getModalContent, setActiveEntryId,
} from '../../actions/index';

export class DumbEntryHyperlink extends Component {
  componentDidMount() {
    const { modalContentApi } = this.props;
    const entryId = this.getEntryId();

    if (entryId && shouldLoad(modalContentApi)) {
      this.props.dispatch(getModalContent(entryId));
    }
  }

  onPopoutAnchorClick(e) {
    const { dispatch, modalContent, fullWidthModal } = this.props;
    e.preventDefault();

    if (modalContent) {
      dispatch(setActiveEntryId(this.getEntryId()));

      dispatch(
        openModal({
          type: 'InfoModal',
          fullWidth: fullWidthModal,
        }),
      );
    } else {
      dispatch(openPopOut());
    }
  }

  getEntryId() {
    const { node } = this.props;

    return node?.data?.target?.sys?.id || '';
  }

  render() {
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a href="#" onClick={(e) => this.onPopoutAnchorClick(e)}>{this.props.children}</a>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const contentId = ownProps.node?.data?.target?.sys?.id;

  return {
    cmsProductsApi: state.api[`cms_product_carousel_${contentId}`.toLowerCase()],
    isMobileDevice: get(state, 'device.isMobilePhone', false),
    modalContent: state.cms.modal?.[contentId],
    modalContentApi: state.api[`modal_content_${contentId}`.toLowerCase()],
  };
};

export default connect(mapStateToProps)(DumbEntryHyperlink);

import React from 'react';
import { connect } from 'react-redux';
import './spinner.scss';

const Spinner = ({ active = false }) => (active && <div className="spinner" />);

const mapStateToProps = (state) => ({
  active: state.spinner.active,
});
export default connect(mapStateToProps)(Spinner);

import React, { PureComponent } from 'react';
import './genderSelect.scss';

// eslint-disable-next-line react/prefer-stateless-function
class GenderSelect extends PureComponent {
  render() {
    const { onGenderSelected, selectedGender } = this.props;
    return (
      <div className="gender-select-container">
        <ul className="gender-select">
          <li>
            <button
              onClick={() => onGenderSelected('W')}
              className={selectedGender === 'W' ? 'active' : undefined}
              aria-label="WOMEN"
              tabIndex="0"
            >
              WOMEN
            </button>
          </li>
          <li>
            <button
              onClick={() => onGenderSelected('M')}
              className={selectedGender === 'M' ? 'active' : undefined}
              aria-label="MEN"
              tabIndex="0"
            >
              MEN
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default GenderSelect;

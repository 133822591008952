import { addBodyClass, removeBodyClass } from 'client/utilities/bodyClass';
import get from 'lodash/get';
import React from 'react';
import { connect } from 'react-redux';
import { setCollapsibleSearchModal } from '../search-actions';
import { getPlaceholderValue, getSearchTerm } from '../search-reducers';
import './collapsibleSearchBar.scss';
import SearchModal from './SearchModal/searchModal';

const CollapsibleSearchBar = ({
  collapsedSearchExperience,
  searchPlaceHolder,
  searchTerm,
  isSearchModalOpen,
  setCollapsibleSearchModal,
}) => {
  const openSearchModal = (e) => {
    e.preventDefault();
    addBodyClass('search-modal-open');
    setCollapsibleSearchModal(true);
  };

  const closeSearchModal = (e) => {
    e.preventDefault();
    removeBodyClass('search-modal-open');
    setCollapsibleSearchModal(false);
  };

  return (
    <>
      <div
        className="collapsible-searchbar-container desktop-tablet-only"
      >
        <input
          type="button"
          name="search bar icon"
          title="search bar icon"
          aria-label="collapsible-search-bar"
          className="collapsible-searchbar-icon"
          onClick={(e) => openSearchModal(e)}
        />
        <input
          type="text"
          title="Search"
          autoComplete="off"
          id="brSearchInput"
          name="collapsible search bar"
          className="collapsible-searchbar"
          aria-label="collapsible-search-bar"
          value={searchTerm}
          placeholder={searchPlaceHolder || 'Search'}
          onClick={(e) => openSearchModal(e)}
          onFocus={(e) => openSearchModal(e)}
        />
      </div>
      {isSearchModalOpen
        && (
        <SearchModal
          collapsedSearchExperience={collapsedSearchExperience}
          closeSearchModal={closeSearchModal}
        />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    searchPlaceHolder: getPlaceholderValue(state),
    searchTerm: getSearchTerm(state),
    isSearchModalOpen: get(state, 'search.isSearchModalOpen', false),
  };
};

const mapDispatchToProps = {
  setCollapsibleSearchModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapsibleSearchBar);

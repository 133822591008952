import styled from 'styled-components';

export const ScrollToTopButton = styled.button`
  position: fixed;
  cursor: pointer;
  z-index: 50;
  right: 30px;
  bottom: ${(props) => (props.isMobilePhone ? '15px' : '65px')};
  background: #fff;
  border: 1px solid #000000;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  @media screen and (min-width: 768px) {
    bottom: 35px;
  }
`;

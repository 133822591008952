import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import cookie from 'react-cookie';
import IdleTimer from 'react-idle-timer';
import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { getGuestTokens, isGuestValid } from '@nmg/auth';
import logger from 'server-utils/logger';
import { getEnv } from 'client-utils/amplifyUtils';
import { saveToLocalStorage } from 'client-utils/utilities-localstorage';
import Slider from 'react-slick';
import './abandonCartModal.scss';
import { Divider } from 'client/components';


const AbandonCartModal = ({ showRecommendations }) => {
  const [cartItems, setCartItems] = useState(null);
  const [recommendedProducts, setrecommendedProducts] = useState([]);
  const [productRecTitle, setProductRectTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [hasSeenModal, setHasSeenModal] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [modalAlreadyShown, setModalAlreadyShown] = useState(false);
  const idleTimerRef = useRef(null);


  function referralSourceClickedCart(source, linkType, path) {
    if (typeof window !== 'undefined') {
      const nmObj = window?.nm;
      if (nmObj?.om?.appendCookie && nmObj?.omPreviousPageCookieName) {
        const prevLink = JSON.stringify({ prev_page_link: source, prev_link_type: linkType });
        nmObj.om.appendCookie(nmObj.omPreviousPageCookieName, prevLink, 0, path);
      } else {
        logger.error('Could not set prev_page_link');
      }
    }
  }

  const setLocalStorageWithExpiry = (key, value, expiryInHours) => {
    const now = new Date();
    const expiryTime = now.getTime() + expiryInHours * 60 * 60 * 1000;
    const item = { value, expiryTime: expiryTime.toString() };
    saveToLocalStorage(key, JSON.stringify(item));
  };

  const getLocalStorageWithExpiry = (key) => {
    const itemString = localStorage.getItem(key);
    if (!itemString) {
      return null;
    }

    const item = JSON.parse(itemString);
    const now = new Date().getTime();

    if (now > parseInt(item.expiryTime, 10)) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  };

  const getValidRequestToken = async () => {
    try {
      const userData = await Auth.currentSession();
      const token = userData?.getIdToken()?.getJwtToken();
      /* eslint-disable camelcase */
      const userId = userData?.getIdToken()?.payload?.preferred_username;

      return { token, userId };
    } catch (e) {
      logger.error('no user token, attempting guest token');
      const env = getEnv();
      const brand = NMConfig.BRAND_NAME;

      try {
        await isGuestValid(env, brand || 'NM');
      } catch (error) {
        logger.error('error validating guest', error);
      }
      const { AccessToken: token, Sub: userId } = getGuestTokens([
        'AccessToken',
        'Sub',
      ]);

      return { token, userId };
    }
  };

  const carouselSettings = () => {
    return {
      arrows: true,
      infinite: false,
      slidesToShow: showRecommendations ? 3 : 2,
      slidesToScroll: showRecommendations ? 3 : 2,
    };
  };

  const productRecsSettings = {
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const displayPrice = (product) => {
    const {
      promotionalDollarOff,
      promotionalPercentOff,
      itemPrice,
    } = product;
    const { priceAdornments, onSale, retailPrice } = product.productDetails;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    let formattedRetailPrice = formatter.format(retailPrice);
    let formattedItemPrice = formatter.format(itemPrice);
    if (parseInt(formattedRetailPrice.split('.')[1], 10) === 0) formattedRetailPrice = formattedRetailPrice.split('.')[0];
    if (parseInt(formattedItemPrice.split('.')[1], 10) === 0) formattedItemPrice = formattedItemPrice.split('.')[0];

    /* eslint-disable no-nested-ternary */
    const promoPrices = () => (promotionalDollarOff > 0 && promotionalPercentOff > 0 ? (
      <>
        {Number(promotionalPercentOff).toFixed()}
        <span>% Off: </span>
        <span>$ Off: </span>
      </>
    ) : promotionalDollarOff > 0 ? (
      <>
        <span>$ Off: </span>
      </>
    ) : promotionalPercentOff > 0 ? (
      <>
        {Number(promotionalPercentOff).toFixed()}
        <span>% Off: </span>
      </>
    ) : (<></>));

    const adornmentPrice = () => {
      priceAdornments.reverse();
      return priceAdornments.map((priceAdornment, index) => (
          <div className={`price ${index !== 0 ? 'discount' : 'new'}`}>
            {parseInt(formatter.format(priceAdornment.price).split('.')[1], 10) === 0 ? formatter.format(priceAdornment.price).split('.')[0] : formatter.format(priceAdornment.price)}
          </div>
      ));
    };

    if (promotionalDollarOff > 0 || promotionalPercentOff > 0) {
      return (
        <>
          <div>
            {' '}
            {priceAdornments?.length ? adornmentPrice() : formattedRetailPrice}
            {' '}
          </div>
          <div className="price-discount">
            <div>
              {promoPrices()}
              {' '}
              {formattedItemPrice}
            </div>
          </div>
        </>
      );
    }

    if (priceAdornments?.length) {
      return (
        <>
          {adornmentPrice()}
        </>
      );
    }

    if (!onSale) {
      return (
        <>
          <div className="price">
            {formattedRetailPrice}
          </div>
        </>
      );
    }

    return <></>;
  };

  const renderProduct = (product) => {
    const productDetails = product.productDetails;
    const productImage = product.imageUrl;
    const designerName = productDetails?.designerName;
    const productName = productDetails?.displayName;

    return (
      <div className="cart-item-tile">
        <img className="cart-item-tile__img" src={productImage} alt={productName} />
        <div className="cart-item-tile__designer">{designerName}</div>
        <div className="cart-item-tile__product">{productName}</div>
        <div className="cart-item-tile__price">
          {displayPrice(product)}
        </div>
      </div>
    );
  };

  const onProductSelected = async (product) => {
    try {
      await referralSourceClickedCart('You May Also Like - Abandon Cart', 'product');
      window.open(product.details?.canonicalUrl, '_blank').focus();
    } catch (error) {
      logger.error('Error handling redirection in product recommendations', error);
    }
  };

  const renderProductRecs = (product) => {
    const productImage = product.media?.main?.dynamic?.url;
    const designerName = product.designer?.name;
    const productName = product.name;
    product.promotionalPercentOff = product.promotions?.length && product.promotions[0].percentOff;
    product.itemPrice = product.price?.promotionalPrice;
    product.productDetails = {
      priceAdornments: product.price?.adornments,
      onSale: product.onSale,
      retailPrice: product.price?.retailPrice,
    };
    return (
      <button className="cart-item-tile" id={product.id} onClick={() => { onProductSelected(product); }}>
        <img className="cart-item-tile__img" src={productImage} alt={productName} />
        <div className="cart-item-tile__designer">{designerName}</div>
        <div className="cart-item-tile__product">{productName}</div>
        <div className="cart-item-tile__price">
          {displayPrice(product)}
        </div>
      </button>
    );
  };

  const fetchProductRecs = async (products) => {
    const filteredProduct = products.reduce(
      (a, b) => (parseFloat(a.itemPrice) > parseFloat(b.itemPrice) ? a : b)
    );
    const ucaProfileDataCookie = cookie.load('ucaProfileData') || {};
    const { universal_customer_id, logged_in_status } = ucaProfileDataCookie;
    try {
      const reqParams = {
        channel: 'NMO',
        placement: 'ABANDON_CART_MODAL',
        type: 'PDP_SECOND_CAROUSEL',
        variantId: filteredProduct.productDetails?.selectedSku?.colorName ? `${filteredProduct.productId}-${filteredProduct.productDetails?.selectedSku?.colorName || ''}` : filteredProduct.productId,
        subProductId: filteredProduct.productId,
        prodId: filteredProduct.productDetails?.pimStyle,
        gender: filteredProduct.productDetails?.genderCode,
        ucaId: universal_customer_id,
        isGuest: !logged_in_status,
        customerId: universal_customer_id,
        brand: filteredProduct.productDetails?.designerName,
        displayable: filteredProduct.productDetails?.displayable,
      };
      const { data } = await axios.get(NMConfig.API_STYLYZE_ABANDON_CART,
        { params: reqParams, timeout: 3000 });
      setProductRectTitle(data?.stylyze?.masterApiLabel || '');
      const displayableProducts = data?.products?.filter((prod) => prod.displayable);
      if (displayableProducts.length > 0) setrecommendedProducts(displayableProducts.slice(0, 12));
    } catch (error) {
      logger.error('error fetching recommended product details', error);
    }
  };

  const fetchCartDetails = async () => {
    try {
      const { token, userId } = await getValidRequestToken();
      if (!token || !userId) {
        logger.error('Invalid token or userId. Cannot fetch cart details.');
        return;
      }
      const lambdaHost = window?.sessionStorage?.getItem('lambdaHost');
      const fastlyHost = window?.sessionStorage?.getItem('fastlyUCAHost') || lambdaHost;
      const baseURL = `${fastlyHost}/oco-cart-items/v3/${userId}/cart`;
      const { data } = await axios.post(baseURL, {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            sourceApp: 'WN',
          },
        });
      const products = data?.shoppingCart?.commerceItems?.shipments[0]?.items;
      setCartItems(products);
      if (showRecommendations && products.length > 0) fetchProductRecs(products);
    } catch (error) {
      logger.error('error fetching cart details', error);
    }
  };


  const handleIdle = () => {
    setIsIdle(true);
  };

  const checkModalSeenStatus = () => {
    const seenModal = localStorage.getItem('abandonCartModalSeen');
    setHasSeenModal(seenModal === 'true');
  };

  const handleGoToShoppingBag = async () => {
    try {
      await referralSourceClickedCart('abandon cart modal', 'shopping bag', '/cart');
      window.location.href = '/cart/';
    } catch (error) {
      logger.error('Error handling shopping bag cta:', error);
    }
  };


  const displayModalAfterDelay = () => {
    checkModalSeenStatus();

    const lastModalTimestamp = getLocalStorageWithExpiry('abandonCartModalTimestamp');
    const nowTimestamp = new Date().getTime();

    const isExpired = !lastModalTimestamp
      || nowTimestamp - parseInt(lastModalTimestamp, 10) > 24 * 60 * 60 * 1000;

    if (!hasSeenModal && isExpired) {
      setTimeout(() => {
        window?.utag?.link?.({
          ...window.utag_data_dt,
          event_name: 'abandon carted modal',
        });
        setModalVisible(true);
        setLocalStorageWithExpiry('abandonCartModalSeen', 'true', 24);
        setLocalStorageWithExpiry('abandonCartModalTimestamp', nowTimestamp.toString(), 24);
      }, 5000);
    }
  };


  const handleEvent = (event) => {
    switch (event.type) {
      case 'mousemove':
        if (event.clientY < 10 && !hasSeenModal && !modalAlreadyShown) {
          setModalAlreadyShown(true);
          displayModalAfterDelay();
        }
        break;
      case 'visibilitychange':
      case 'mouseleave':
        if (!hasSeenModal && !modalAlreadyShown) {
          setModalAlreadyShown(true);
          displayModalAfterDelay();
        }
        break;
      default:
    }
  };

  const hasDomLoaded = typeof window !== 'undefined';

  useEffect(() => {
    if (hasDomLoaded && !cartItems?.length) {
      fetchCartDetails();
    }
  }, [hasDomLoaded]);

  useEffect(() => {

  }, []);

  useEffect(() => {
    checkModalSeenStatus();
    if (cartItems?.length) {
      document.addEventListener('mousemove', handleEvent);
      document.addEventListener('visibilitychange', handleEvent);
      document.addEventListener('mouseleave', handleEvent);
    }
    return () => {
      if (cartItems?.length) {
        document.removeEventListener('mousemove', handleEvent);
        document.removeEventListener('visibilitychange', handleEvent);
        document.removeEventListener('mouseleave', handleEvent);
      }
    };
  }, [hasSeenModal, cartItems, modalAlreadyShown]);

  useEffect(() => {
    if (isIdle && !hasSeenModal && cartItems?.length > 0 && !modalAlreadyShown) {
      setModalAlreadyShown(true);
      displayModalAfterDelay();
    }
  }, [isIdle, hasSeenModal, cartItems, modalAlreadyShown]);

  return (
    <IdleTimer ref={idleTimerRef} timeout={300 * 1000} onIdle={handleIdle}>
      {modalVisible && (
        <div className="abandon-cart-backdrop" />
      )}
      {modalVisible && cartItems && (
        <div className={classNames('abandon-cart-modal',
          { recs: showRecommendations, 'no-recs': showRecommendations && recommendedProducts.length < 3 })}
        >
          <button
            className="close-button"
            onClick={() => {
              window?.utag?.link?.({
                ...window.utag_data_dt,
                event_name: 'carted modal close',
              });
              setModalVisible(false);
            }}
          />
          <div className="modal-content">
            <div className="top-modal-content">
              {showRecommendations ? (
                <div className="text-section">
                  <p className="dont-forget-header">Don&apos;t Forget!</p>
                  <p className="dont-forget-text">
                    You’ve got
                    {' '}
                    {cartItems.length === 1 ? ' an item ' : ` some items (${cartItems?.length}) `}
                    in your shopping bag. Complete your order now!
                  </p>
                </div>
              ) : (
                <div className="text-section">
                  <p className="dont-forget-header">Don&apos;t Forget!</p>
                  <p className="dont-forget-text">You’ve got some items in your shopping bag. Complete your order now!</p>
                  <p className="total-text">
                    Total (
                    {cartItems?.length}
                    {' '}
                    items)
                  </p>
                </div>
              )}
              <div className="carousel-section">
                <Slider {...carouselSettings()}>
                  {cartItems?.map((product) => renderProduct(product))}
                </Slider>
              </div>
            </div>
            <div className={showRecommendations ? 'dont-forget-cta' : 'bottom-modal-content'}>
              <button
                className="cancel-button"
                onClick={() => {
                  window?.utag?.link?.({
                    ...window.utag_data_dt,
                    event_name: 'carted modal close',
                  });
                  setModalVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                className="shopping-bag-button"
                onClick={handleGoToShoppingBag}
              >
                Go to Shopping Bag
              </button>
            </div>
            {showRecommendations && recommendedProducts.length >= 3 && <Divider />}
            {showRecommendations && recommendedProducts.length >= 3 && (
              <div className="recommended-products">
                <p className="title">{productRecTitle}</p>
                <div className="carousel-section">
                  <Slider {...productRecsSettings}>
                    {recommendedProducts?.map(
                      (product) => renderProductRecs(product)
                    )}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </IdleTimer>
  );
};

export default AbandonCartModal;

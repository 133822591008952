import React from 'react';
import get from 'lodash/get';
// eslint-disable-next-line import/no-cycle
import { calculateTextOverlayPositionProps } from '../utils';
import './badgesAsset.scss';

const BADGE_MARGIN = '20';

function BadgeAsset(props) {
  const { cmsContentItem = {} } = props;
  const {
    name,
    badge: [{ url: badgeSrc } = {}] = [],
    badgeMobile: [{ url: badgeMobileSrc } = {}] = [],
    badgePosition,
    badgePositionMobile,
  } = get(cmsContentItem, 'fields', {});

  const badgePositionProps = calculateTextOverlayPositionProps(
    badgePosition, BADGE_MARGIN, BADGE_MARGIN, true
  );
  const badgeMobilePositionProps = calculateTextOverlayPositionProps(
    badgePositionMobile || badgePosition, BADGE_MARGIN, BADGE_MARGIN, true
  );

  return (
    <>
      <img
        className="badge-image"
        style={badgePositionProps}
        src={badgeSrc}
        alt={name}
        title={name}
      />
      <img
        className="badge-image-mobile"
        style={badgeMobilePositionProps}
        src={badgeMobileSrc || badgeSrc}
        alt={name}
        title={name}
      />
    </>
  );
}

export default BadgeAsset;

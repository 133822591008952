import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames';
import './caption.scss';

const CN = 'asset-caption';

const Caption = ({
  style, badgeSrc, caption, credit, className, isMobile,
}) => (
  <div
    style={style}
    className={classNames(className, CN, {
      [`${CN}--mobile`]: isMobile,
    })}
  >
    {badgeSrc && <img className={`${CN}__badge`} src={badgeSrc} alt="" />}
    {caption && (
      <div className={`${CN}__caption`}>
        {documentToReactComponents(caption)}
      </div>
    )}
    {credit && (
      <div className={`${CN}__credit`}>{documentToReactComponents(credit)}</div>
    )}
  </div>
);


export default Caption;

import React, { Component } from 'react';
import get from 'lodash/get';
import EcmText from '../EcmText';
import ConnectedEcmAdaptiveImage from '../EcmAdaptiveImage';

export default class EcmSimple extends Component {
  renderComponent() {
    const { ecmContent, ecmSlot, improvePerformanceOfStyleToggle } = this.props;
    const { component } = ecmContent;
    const buttonOverLay = get(component, 'calltoactionbuttons', {});

    if (component && component.componentType) {
      switch (component.componentType) {
        case 'text':
          return (
            <EcmText
              text={component}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        case 'adaptive-image':
          return (
            <ConnectedEcmAdaptiveImage
              buttonOverlayElements={buttonOverLay}
              picture={component}
              ecmSlot={ecmSlot}
              improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            />
          );
        default:
          return <div />;
      }
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <div className="ecm-container__simple grid-100 mobile-grid-100 tablet-grid-100 grid-parent">
        {this.renderComponent()}
      </div>
    );
  }
}

import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { ecmGetAdaptiveStyleBlock, ecmGetOptimizedAdaptiveStyleBlock } from '../../utilities';
import ConnectedEcmSiteTickerItems from './ecmSiteTickerItems';

const EcmSiteTicker = ({
  dataObj,
  ecmSlot,
  improvePerformanceOfStyleToggle,
}) => {
  const ecmStyle = get(ecmSlot, 'className', '');
  const ecmComponentId = get(dataObj, 'componentId', '');
  const ecmProperties = get(dataObj, 'properties', {});
  const ecmObjectStyle = improvePerformanceOfStyleToggle ? `ecm-${ecmComponentId}` : `ecm-container__siteticker__${ecmComponentId}`;
  const classes = improvePerformanceOfStyleToggle ? classNames('ecm-container__siteticker optst', ecmStyle, ecmObjectStyle) : classNames('ecm-container__siteticker', ecmStyle, ecmObjectStyle);
  const adaptiveStyleBlock = improvePerformanceOfStyleToggle
    ? ecmGetOptimizedAdaptiveStyleBlock(ecmProperties, ecmObjectStyle)
    : ecmGetAdaptiveStyleBlock(ecmProperties, ecmObjectStyle);
  if (improvePerformanceOfStyleToggle) {
    return (
      <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
        {adaptiveStyleBlock}
        <div className={classes}>
          <ConnectedEcmSiteTickerItems
            dataObj={dataObj}
            ecmSlot={ecmSlot}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
      <div className={classes}>
        {adaptiveStyleBlock}
        <ConnectedEcmSiteTickerItems
          dataObj={dataObj}
          ecmSlot={ecmSlot}
          improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
        />
      </div>
    </div>
  );
};

export default EcmSiteTicker;

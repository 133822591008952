import React, { PureComponent } from 'react';
import get from 'lodash/get';

class EcmImage extends PureComponent {
  componentDidMount() {
    const img = get(this, 'image', '');
    if (this.image && this.image.complete && this.image.naturalWidth === 0) {
      this.handleError(img);
    }

    if (this.image && !this.image.complete) {
      setTimeout(() => {
        if (this.image && this.image.naturalWidth === 0) {
          this.handleError(img);
        }
      }, 500);
    }
  }

  handleError = (img) => {
    const url = img ? get(img, 'srcset', '') : '';
    setTimeout(() => {
      if (window && window.QuantumMetricAPI) {
        window.QuantumMetricAPI.sendEvent(505, 0, `src: ${url} failed loading.`);
      }
    }, 3000);
    return null;
  }

  render() {
    const {
      desktopImg, tabletImg, mobileImg, alt, title,
    } = this.props;

    return (
      <picture>
        <source media="(min-width: 1025px)" srcSet={desktopImg} />
        <source media="(min-width: 768px) and (max-width: 1024px)" srcSet={tabletImg} />
        <source media="(min-width: 1px) and (max-width: 767px)" srcSet={mobileImg} />
        <img
          srcSet={desktopImg}
          alt={alt}
          title={title}
          ref={(ref) => { this.image = ref; }}
          onError={(e) => { this.handleError(get(e, 'target', '')); }}
        />
      </picture>
    );
  }
}

export default EcmImage;

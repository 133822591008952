import React from 'react';
import ConnectedEcmColumn from 'ecm/components/EcmColumn';

const EcmLayoutRow = ({
  ecmContent,
  ecmSlot,
  improvePerformanceOfStyleToggle,
  countryCode,
}) => {
  return (
    <div className="grid-100 tablet-grid-100 mobile-grid-100 grid-parent">
      <ConnectedEcmColumn
        ecmContent={ecmContent}
        ecmSlot={ecmSlot}
        improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
        countryCode={countryCode}
      />
    </div>
  );
};

export default EcmLayoutRow;

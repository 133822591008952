/* eslint import/no-cycle: 0 */
import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import InputRange from 'react-input-range';
import { setDynamicRailHovered, setDynamicRailNotHovered } from 'cms/actions';
import RailCircle from '../../../assets/images/rail-circle.svg';
import GrabbingRailCircle from '../../../assets/images/grabbing-rail-circle.svg';
import ExternalLink from '../../../assets/images/external-link.svg';
import './dynamicHorizontalRail.scss';

const CN = 'dynamic-rail';
const cursorWidth = 60;
const cursorOffset = 10;

function DynamicHorizontalRail(props) {
  const {
    children,
    isMobilePhone,
    isTablet,
    isLinkHovered,
    setDynamicRailHovered,
    setDynamicRailNotHovered,
    verticallyAlign,
    dhrHeightToggle,
  } = props;
  const rangeRef = React.useRef(null);
  const wrapperRef = React.useRef(null);
  const [maxInpWidth, setMaxInpWidth] = React.useState(0);
  const [isDown, setIsDown] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [pos, setPos] = React.useState({
    left: 0,
    x: 0,
  });
  const [mouseIsOver, setMouseIsOver] = React.useState(false);
  const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
  const [isScrolling, setIsScrolling] = React.useState(false);
  const prevScrollX = React.useRef(0);
  const [screenWidth, setScreenWidth] = React.useState(null);

  const onChange = (value) => {
    wrapperRef.current.scrollLeft = value;
    setValue(value);
  };

  const renderSlides = () => {
    return React.Children.map(children, (child) => {
      return child;
    });
  };

  const onResize = () => {
    const max = wrapperRef.current.scrollWidth - wrapperRef.current.offsetWidth;
    setMaxInpWidth(max);
    setScreenWidth(window.innerWidth);

    wrapperRef.current.scrollLeft = rangeRef.current.value;
  };

  const A11y = () => {
    const slider = document.querySelectorAll('.input-range__slider');
    slider.forEach((s) => {
      s.setAttribute('tabindex', -1);
      s.setAttribute('aria-label', 'Choose a value');
    });
  };

  React.useEffect(() => {
    window.addEventListener('resize', onResize);

    const max = wrapperRef.current.scrollWidth - wrapperRef.current.offsetWidth;
    setMaxInpWidth(max);

    setScreenWidth(window.innerWidth);
    A11y();

    return () => {
      window.removeEventListener('resize', onResize);
      setDynamicRailNotHovered();
    };
  }, []);

  const onMouseDown = (e) => {
    setIsDown(true);
    setPos({
      left: e.currentTarget.scrollLeft,
      x: e.clientX,
    });
  };

  const onMouseUp = () => {
    setIsDown(false);
    setPos({
      left: 0,
      x: 0,
    });
  };

  const onMouseLeave = () => {
    setMouseIsOver(false);
    setIsDown(false);
    setPos({
      left: 0,
      x: 0,
    });
    setIsScrolling(false);
    setDynamicRailNotHovered();
  };

  const onMouseMove = (e) => {
    if (screenWidth <= e.pageX + cursorWidth + 3 * cursorOffset) {
      setMousePos({ x: e.pageX - cursorWidth, y: e.pageY + cursorOffset });
    } else {
      setMousePos({ x: e.pageX + cursorOffset, y: e.pageY + cursorOffset });
    }

    if (isDown) {
      const dx = e.clientX - pos.x;

      wrapperRef.current.scrollLeft = pos.left - dx;
      setValue(wrapperRef.current.scrollLeft);
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  const onMouseOver = () => {
    setMouseIsOver(true);
  };

  const onMouseEnter = () => {
    setDynamicRailHovered();
    setIsScrolling(false);
  };

  const onScroll = ({ target }) => {
    if (isMobilePhone || isTablet) {
      setValue(target.scrollLeft);
    } else {
      const currScrollX = target.scrollLeft;
      if (currScrollX !== prevScrollX.current) {
        setIsScrolling(true);
        prevScrollX.current = currScrollX;
      } else {
        setIsScrolling(false);
      }
    }
  };

  const onClickHandler = (e) => {
    const { target, clientX } = e;
    const rect = target.getBoundingClientRect();
    const cursorPosition = clientX - rect.left;
    const elemWidth = rect.width;
    const rangeWidth = rangeRef.current.node.clientWidth;

    if (elemWidth !== 96 && cursorPosition >= 0 && cursorPosition <= 48) {
      setValue(0);
      wrapperRef.current.scrollLeft = 0;
    }

    if (elemWidth !== 96 && cursorPosition >= rangeWidth + 48) {
      setValue(maxInpWidth);
      wrapperRef.current.scrollLeft = maxInpWidth;
    }
  };

  const onClick = (e) => {
    if (isScrolling) {
      e.stopPropagation();
      e.preventDefault();
      setIsScrolling(false);
    }
  };

  const renderCursor = () => {
    if ((isDown && !isLinkHovered) || isScrolling) {
      return GrabbingRailCircle;
    }

    if (isLinkHovered) {
      return ExternalLink;
    }

    return RailCircle;
  };

  return (
    <div className={`${CN}-container`}>
      <div
        className={CN + (verticallyAlign ? ` ${CN}--${verticallyAlign.toLowerCase()}` : '') + (isScrolling ? ` ${CN}--scrolling` : '')}
        ref={wrapperRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onScroll={onScroll}
        onMouseOverCapture={onMouseOver}
        onClickCapture={onClick}
        style={{ maxHeight: dhrHeightToggle ? 1080 : 662 }}
      >
        {renderSlides()}
        {mouseIsOver && !isMobilePhone && !isTablet && (
          <div
            className={`${CN}-cursor`}
            style={{
              background: `no-repeat url(${renderCursor()})`,
              left: `${mousePos.x}px`,
              top: `${mousePos.y}px`,
            }}
          />
        )}
      </div>
      <div className="input-range-container" onClick={onClickHandler}>
        <InputRange
          ref={rangeRef}
          maxValue={maxInpWidth}
          minValue={0}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone'),
    isTablet: get(state, 'device.isTablet'),
    isLinkHovered: get(state, 'cms.dynamicRail.hoveredLink'),
    dhrHeightToggle: get(state, 'toggles.DHR_HEIGHT', false),
  };
};

const mapDispatchToProps = ({
  setDynamicRailHovered,
  setDynamicRailNotHovered,
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicHorizontalRail);

import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import favIconActive from 'assets/images/favorite-active.svg';
import favIcon from 'assets/images/Favorite_Icon.svg';
import giftFinderIcon from 'assets/images/gift/Sparkle_GiftFinder_Paths.svg';
import getLinkDomain from 'client/utilities/getlinkDomain';
import BrandLogo from '../BrandLogo';
import SearchBoxDesktop from '../Search/SearchBox/searchBox';
import SearchBox from '../Search/SearchBox/newSearchBox';
import SearchBoxMobile from '../Search/SearchBoxMobile/searchBoxMobile';
import MiniCart from '../MiniCart/miniCart';
import { ConnectedSignInRegister, ConnectedYourAccount } from '../AccountHeaderMenuItem/AccountHeaderMenuItem';
import MobileNav from '../../Navigation/MobileNav/MobileNav';
import YourNeimansCTA from '../YourNeimansCTA/YourNeimansCTA';
import GenderSelect from '../GenderSelect/genderSelect';
import './cleanHeader.scss';
import CollapsibleSearchBar from '../Search/SearchBox/collapsibleSearchBar';
// eslint-disable-next-line import/no-named-as-default
import CollapseSearchBarMobile from '../Search/SearchBoxMobile/collapseSearchBarMobile';
// eslint-disable-next-line import/no-named-as-default
import CollapseSearchBarMobileV2 from '../Search/SearchBoxMobile/collapseSearchBarMobileV2';
import AbandonCartModal from '../AbandonCartModal/abandonCartModal';
import {
  getAvailableStoresInfo, getMappedStoreInfo, getStoreList, filterActiveStores,
} from '../../../../common/components/YourNeimans/components/utilities';

const MobileScrollMenu = Loadable({
  loader: () => import(/* webpackChunkName: 'MobileScrollMenu' */ '../MobileScrollMenu/MobileScrollMenu'),
  loading: () => false,
});

const OUR_STORES = 'Our Stores';
const MY_ORDERS = 'My Orders';
const MATCH_WITH_STYLE_ADVISOR = 'Match with a Style Advisor';
const getLink = getLinkDomain();

const CleanHeader = ({
  isMobilePhone,
  isTablet,
  toggleSearchDisplay,
  onGenderSelected,
  hasOpenedSearch,
  isSearchExposed,
  tickerComponent,
  isStickyActive,
  addFocus,
  pageId,
  isTop,
  screenSizeDesktopInMobile,
  panelToggle,
  specialOffersPrompt,
  giftFinderToggle,
  holidayLogoToggle,
  brand,
  showGenderToggle,
  selectedGender,
  typeAheadExperience,
  isMenDarkMode,
  isDomestic,
  numNotifications,
  isImprovedPanelToggle,
  hcAssistanceToggle,
  globalNavUpdateToggle,
  offersData,
  isHNRightToggle,
  isDRToggleOn,
  mobileScrollMenu,
  user,
  isAuthenticated,
  isCollapsedSearchBarToggle,
  styleUpdateToggle,
  isNmNewHeader,
  abandonCartToggle,
  recommendationsToggle,
  stores,
  selectedStoreName,
  isFtcPage,
  clientLocation,
}) => {
  const [storeName, setStoreName] = useState('');

  const renderStickyHeader = isMobilePhone && isStickyActive;
  const renderCollapsibleSearchBar = (!isMobilePhone || screenSizeDesktopInMobile)
    && isCollapsedSearchBarToggle;
  const renderMobileCollapseSearch = isMobilePhone && (isCollapsedSearchBarToggle && pageId !== 'PAGE_ID_EDITORIAL');
  const editorialPageCollapseSearch = isMobilePhone && (isCollapsedSearchBarToggle && pageId === 'PAGE_ID_EDITORIAL');

  const authenticated = user
  && user.securityStatus
  && (user.securityStatus.toLowerCase() === 'authenticated' || user.securityStatus.toLowerCase() === 'anonymous')
  && user.name
  && isAuthenticated;
  const userOrdersPage = authenticated ? 'order-history' : 'guest-order-history';
  const marginForNmNewHeader = () => {
    if ((!typeAheadExperience && !renderCollapsibleSearchBar) && isNmNewHeader && !isMobilePhone) {
      return { marginBottom: '30px' };
    } if (typeAheadExperience && isNmNewHeader && !isMobilePhone) {
      return { marginBottom: '40px' };
    }
    return null;
  };
  const paddingForNmNewHeader = isNmNewHeader && !isMobilePhone ? { paddingTop: '48px' } : null;

  const minimalGlobalNav = Boolean(globalNavUpdateToggle);
  const favoriteIcon = isNmNewHeader ? favIcon : favIconActive;

  useEffect(() => {
    const { selectedStore, favoriteStore, cleanZip } = getAvailableStoresInfo();
    (async function getStoreData() {
      const mappedStoreData = await getMappedStoreInfo(selectedStore, favoriteStore, cleanZip);
      const geoLocatedStores = clientLocation && await getStoreList(clientLocation.zip);
      const filteredStores = geoLocatedStores?.data && filterActiveStores(geoLocatedStores.data);
      const getStoreName = mappedStoreData.selectedStore || mappedStoreData.favoriteStore
        || selectedStoreName || (filteredStores && filteredStores[0]?.name);
      if (isNmNewHeader) {
        setStoreName(getStoreName);
      }
    }());
  }, [stores, selectedStoreName]);

  const AccountHeaderMenuItem = authenticated
    ? <ConnectedYourAccount firstName={user.name} isFullWidth />
    : <ConnectedSignInRegister isFullWidth />;

  const brandLogo = isMobilePhone ? (
    <span className={
      classnames('brand-logo-clean-header-mobile',
        {
          'shift-right': renderStickyHeader,
        }, {
          'logo-shift-left': renderStickyHeader && isHNRightToggle,
        })}
    >
      <BrandLogo holidayLogoToggle={holidayLogoToggle} brand={brand} />
    </span>
  )
    : <span className={classnames('brand-logo-clean-header', { 'new-searchbar-center': renderCollapsibleSearchBar })}><BrandLogo holidayLogoToggle={holidayLogoToggle} brand={brand} /></span>;

  const favoriteIconContnainer = !isMobilePhone && (
    <div className="favorite">
      {brand === 'HC'
        ? (
          <li className="your-neimans-cta">
            <a
              href="/account/account.jsp"
              className="your_account_hc"
            >
              YOUR ACCOUNT
            </a>
          </li>
        )
        : (
          <a href="/myfavorites/myFavoriteItems.jsp?view=favItems&forOmniLN=1&favin=1">
            <img alt="my favorite icon" src={favoriteIcon} className="favo-icon" />
          </a>
        )
      }
    </div>
  );

  const handleStoreClick = () => {
    window.YourNeimans && window.YourNeimans.setData({
      isOpen: true,
      pageStack: ['storeListPage'],
    });
  };

  const showCTA = isImprovedPanelToggle ? isAuthenticated !== null : true;

  const iconWrapper = isMobilePhone
    ? (
      <div className={classnames(
        `sticky-header-${(renderMobileCollapseSearch || editorialPageCollapseSearch) ? 'icon-alignment' : 'icon-wrapper'}`,
        isHNRightToggle && 'wrapper-icon'
      )}
      >
        {
          (renderMobileCollapseSearch && !isTop)
          && (
            <div className="sticky-header-search-icon-btn">
              <button
                name="search-icon"
                title="search icon"
                aria-label="Search"
                className={classnames(
                  'sticky-header-search-icon',
                  isHNRightToggle && 'align-search-icon'
                )}
                onClick={toggleSearchDisplay}
              />
            </div>
          )
        }
        {editorialPageCollapseSearch && <CollapseSearchBarMobileV2 />}
        {panelToggle
          && showCTA && (
            <YourNeimansCTA
              brand={brand}
              firstName={authenticated && user.name}
              isMobilePhone={isMobilePhone}
              numNotifications={numNotifications}
              hcAssistanceToggle={hcAssistanceToggle}
              specialOffersPrompt={specialOffersPrompt}
              minimalGlobalNav={minimalGlobalNav}
              offersData={offersData}
              isHNRightToggle={isHNRightToggle}
              isDRToggleOn={isDRToggleOn}
              isNmNewHeader={isNmNewHeader}
            />
        )
        }
        {
          (typeAheadExperience || renderMobileCollapseSearch
            || editorialPageCollapseSearch) ? null : !isTop
          && (
            <div className="sticky-header-search-icon-btn">
              <button
                name="search-icon"
                title="search icon"
                aria-label="Search"
                className={classnames(
                  'sticky-header-search-icon',
                  isHNRightToggle && 'align-search-icon'
                )}
                onClick={toggleSearchDisplay}
              />
            </div>
          )
        }
        <MiniCart isNmNewHeader={isNmNewHeader} />
      </div>
    )
    : <MiniCart isNmNewHeader={isNmNewHeader} />;

  const searchBoxMobileContainer = (isSearchExposed) => (
    <div className={classnames({ 'hide-search-box': !isSearchExposed }, { 'ta-search-mobile': typeAheadExperience })}>
      <SearchBoxMobile
        isSearchExposed={isSearchExposed}
        hasOpenedSearch={hasOpenedSearch}
        addFocus={addFocus}
      />
    </div>
  );

  const collapseSearchBoxMobile = (isSearchExposed) => (
    <div className={classnames({ 'hide-search-box': !isSearchExposed }, { 'ta-search-mobile': true })}>
      <CollapseSearchBarMobile />
    </div>
  );

  const searchBoxComponent = () => {
    if (renderCollapsibleSearchBar) {
      return <CollapsibleSearchBar collapsedSearchExperience={isCollapsedSearchBarToggle} />;
    }

    return typeAheadExperience
      ? <SearchBox />
      : <SearchBoxDesktop isMenDarkMode={isMenDarkMode} />;
  };
  const showStyleUpdate = isFtcPage || (styleUpdateToggle && brand === 'NM' && isDomestic);
  return (
    <Fragment>
      <div
        className={classnames({
          'sticky-header': isMobilePhone,
          active: renderStickyHeader,
          shadow:
            !isTop && (pageId !== 'PAGE_ID_PLP' && pageId !== 'PAGE_ID_SRP'),
          'dark-header': isMenDarkMode,
          'topnav-scrollmenu': isMobilePhone && mobileScrollMenu && brand === 'NM',
        })}
      >
        {isTop && renderStickyHeader && tickerComponent}
        <div
          id="clean-header-section"
          className={classnames(
            'clean-header-container',
            showStyleUpdate && 'style-update'
          )}
          style={{ ...marginForNmNewHeader() }}
        >
          {isNmNewHeader && !isMobilePhone && (
            <div className={classnames(
              'hide-on-mobile',
              'nm-new-header-container',
            )}
            >
              <button type="button" aria-label={storeName || OUR_STORES} onClick={(e) => handleStoreClick(e)}>
                {storeName || OUR_STORES}
              </button>
            </div>
          )}
          <div
            className={classnames(
              'clean-header-section__left-aligned desktop-tablet-only',
              {
                'clean-header-sec-gobal-nav': isDomestic && minimalGlobalNav,
                'new-searchbar-align': renderCollapsibleSearchBar,
              }
            )}
          >
            {brand !== 'HC' && showGenderToggle && (
              <GenderSelect
                onGenderSelected={(gender) => onGenderSelected(gender)}
                selectedGender={selectedGender}
              />
            )}
          </div>
          {giftFinderToggle && (
            <a href="/quiz/gift-finder" className="hide-on-mobile">
              <img src={giftFinderIcon} className="gift-finder-icon" />
            </a>
          )}
          <MobileNav />
          {brandLogo}
          <div className={classnames(
            'clean-header-section__right-aligned',
            { 'collapsible-search-bar': renderCollapsibleSearchBar },
            { 'menu-shift-left': (isMobilePhone && isHNRightToggle) }
          )}
          >
            {isNmNewHeader && !isMobilePhone && (
              <div className={classnames(
                'hide-on-mobile',
                'nm-new-header-links',
              )}
              >
                {getLink
                  && (
                    <a href={`${getLink}/${userOrdersPage}`} aria-label="My Orders" role="button">
                      {MY_ORDERS}
                    </a>
                  )
                }
                {getLink
                  && (
                    <a href={`${getLink}/c/cat78570743`} aria-label="Match with a Style Advisor" role="button">
                      {MATCH_WITH_STYLE_ADVISOR}
                    </a>
                  )
                }
              </div>
            )}
            <div
              className="clean-header-utility-menu"
              style={paddingForNmNewHeader}
            >
              {isDomestic
                && (panelToggle
                  ? !isMobilePhone
                    && showCTA && (
                      <YourNeimansCTA
                        brand={brand}
                        firstName={authenticated && user.name}
                        isMobilePhone={isMobilePhone}
                        numNotifications={numNotifications}
                        hcAssistanceToggle={hcAssistanceToggle}
                        specialOffersPrompt={specialOffersPrompt}
                        minimalGlobalNav={minimalGlobalNav}
                        offersData={offersData}
                        isDRToggleOn={isDRToggleOn}
                        isNmNewHeader={isNmNewHeader}
                      />
                  )
                  : !isMobilePhone && AccountHeaderMenuItem)}
              {isDomestic && favoriteIconContnainer}
              {pageId !== 'PAGE_ID_INTL_CART' && iconWrapper}
            </div>
            {(!isMobilePhone || screenSizeDesktopInMobile)
              && searchBoxComponent()}
          </div>
        </div>
        {(!renderMobileCollapseSearch && !editorialPageCollapseSearch)
         && searchBoxMobileContainer(typeAheadExperience ? renderStickyHeader : isSearchExposed)}
        {renderMobileCollapseSearch && collapseSearchBoxMobile(isSearchExposed)}
        {isMobilePhone && mobileScrollMenu && brand === 'NM' && (
          <MobileScrollMenu />
        )}
      </div>
      {abandonCartToggle && !isMobilePhone && !isTablet && isDomestic
      && <AbandonCartModal showRecommendations={recommendationsToggle} />}
    </Fragment>
  );
};

CleanHeader.propTypes = {
  isMobilePhone: PropTypes.bool,
  isTablet: PropTypes.bool,
  toggleSearchDisplay: PropTypes.func,
  onGenderSelected: PropTypes.func,
  hasOpenedSearch: PropTypes.bool,
  isSearchExposed: PropTypes.bool,
  tickerComponent: PropTypes.shape({ current: PropTypes.any }),
  isStickyActive: PropTypes.bool,
  addFocus: PropTypes.bool,
  pageId: PropTypes.string,
  isTop: PropTypes.bool,
  selectedGender: PropTypes.string,
  isMenDarkMode: PropTypes.bool,
  user: PropTypes.object,
  isNmNewHeader: PropTypes.bool,
};

export default CleanHeader;

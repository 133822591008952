import { useReducer, useCallback } from 'react';
import { productPanelReducer } from './productPanelReducer';

const initialState = [];

export const useProductPanel = () => {
  const [outfittingProducts, dispatch] = useReducer(productPanelReducer, initialState);

  const setOutfittingProducts = useCallback((products) => {
    dispatch({ type: 'SET_OUTFITTING_PRODUCTS', payload: products });
  }, []);

  const updateOutfittingQuantity = useCallback((productId, quantity) => {
    dispatch({ type: 'UPDATE_QUANTITY', payload: quantity, meta: { productId } });
  }, []);

  const updateOutfittingSize = useCallback((productId, size) => {
    dispatch({ type: 'SET_SELECTED_SIZE', payload: size, meta: { productId } });
  }, []);

  const updateOutfittingBuckleFinish = useCallback((productId, buckleFinish) => {
    dispatch({ type: 'SET_SELECTED_BUCKLE_FINISH', payload: buckleFinish, meta: { productId } });
  }, []);

  const updateOutfittingColor = useCallback((productId, color) => {
    dispatch({ type: 'SET_SELECTED_COLOR', payload: color, meta: { productId } });
  }, []);

  const updateOutfittingHoveredColor = useCallback((productId, color) => {
    dispatch({ type: 'SET_HOVERED_COLOR', payload: color, meta: { productId } });
  }, []);

  const updateOutfittingFavorite = useCallback((productId, isFavorite) => {
    dispatch({ type: 'SET_FAVORITE', payload: isFavorite, meta: { productId } });
  }, []);

  const updateAddToBagError = useCallback((productId, error) => {
    dispatch({ type: 'ADD_TO_BAG_ERROR', payload: error, meta: { productId } });
  }, []);

  return {
    outfittingProducts,
    setOutfittingProducts,
    updateOutfittingQuantity,
    updateOutfittingSize,
    updateOutfittingBuckleFinish,
    updateOutfittingColor,
    updateOutfittingHoveredColor,
    updateOutfittingFavorite,
    updateAddToBagError,
  };
};

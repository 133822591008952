import { openModal } from 'shared/components/Modal/actions';

export const openWelcomeMat = (data, wcMatProps = {}) => {
  return (dispatch) => {
    dispatch(
      openModal({
        type: 'WelcomeMatPopup',
        ...data,
        url: '/category/intl/mat/r_welcome_mat.html',
        props: { ...wcMatProps },
        className: 'welcome-mat',
      }),
    );
  };
};

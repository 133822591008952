import React from 'react';
import classNames from 'classnames';
import CloseIcon from 'shared/components/CloseIcon/closeIcon';
import Column from '../Common/Column/column';
import buildArrayOfCategoriesByColumnBreakFlg from '../drawerUtility';
import './standardDrawer.scss';

const StandardDrawer = ({
  silo,
  style,
  closeFunction,
  hidden,
  delayHoverOnSilos = false,
  getPromoContent,
}) => {
  const arrayOfCategoriesGrouped = buildArrayOfCategoriesByColumnBreakFlg(silo.categories);
  const arrayOfColumns = arrayOfCategoriesGrouped
    .map((categories, index) => Column({ key: index, categories }));
  const promoBannerJsx = getPromoContent() ? <div className="drawer-promo-container grid-100 tablet-100">{getPromoContent()}</div> : false;
  const columnCount = promoBannerJsx ? arrayOfColumns.length + 1 : arrayOfColumns.length;

  if (delayHoverOnSilos) {
    return (
      <section
        className={classNames('silo-group delay-hover-on-silos', { 'drawer-hidden': hidden })}
        data-silo-columns={columnCount}
      >
        {arrayOfColumns}
        {promoBannerJsx}
        <div className="gradient-cover" />
        <div className="drawer-close">
          <CloseIcon onClick={() => closeFunction()} />
        </div>
      </section>
    );
  }
  return (
    <section
      style={style}
      className={classNames('silo-group', { 'drawer-hidden': hidden })}
      data-silo-columns={columnCount}
    >
      {arrayOfColumns}
      {promoBannerJsx}
      <div className="gradient-cover" />
      <div className="drawer-close">
        <CloseIcon onClick={() => closeFunction()} />
      </div>
    </section>
  );
};

export default StandardDrawer;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import fullWidthNMLogo from 'assets/images/neiman-marcus-logo-full-width.svg';
import sparkleLogo from 'assets/images/gift/HolidayLogo21.svg';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import './styles.scss';

/* eslint-disable global-require */
let horchowLogo = '';
if (process.env.BRAND_NAME === 'HC') {
  horchowLogo = require('hcAssets/images/horchow.png');
}
/* eslint-enable global-require */

// eslint-disable-next-line react/prefer-stateless-function
class BrandLogo extends PureComponent {
  getUrl = () => {
    return this.props.selectedGender === 'M' ? '/mens' : '/';
  }

  render() {
    const { holidayLogoToggle, brand } = this.props;
    let imageLogo = '';
    if (brand === 'HC') {
      imageLogo = horchowLogo;
    } else if (holidayLogoToggle) {
      imageLogo = sparkleLogo;
    } else {
      imageLogo = String(fullWidthNMLogo);
    }

    return (
      <div className="header-brand-site-logo-container">
        <a className="header-brand-site-logo-container__link" href={this.getUrl()}>
          <img
            className={`header-brand-site-logo-container__site-logo ${holidayLogoToggle && 'holidayLogo'}`}
            id={`${brand === 'HC' ? 'hcLogo' : ''}`}
            src={imageLogo}
            alt={imageLogo === horchowLogo ? 'Horchow' : 'Neiman Marcus'}
            aria-label={`${brand === 'HC' ? 'Horchow Logo link to homepage' : 'Neiman Marcus Logo link to homepage'}`}
          />
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedGender: getGenderWithOverride(state),
  };
};

export default connect(mapStateToProps)(BrandLogo);

export const searchFormSubmit = (e, string = '') => {
  if (string.trim().length === 0) {
    e.preventDefault();
  }
};

export const genderSelectHandler = (
  isGenderTypeaheadAvailable, dtGender, selectedGender, setGender
) => {
  if (isGenderTypeaheadAvailable && (dtGender !== selectedGender)) setGender(selectedGender);
};

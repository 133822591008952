const tabbableNode = /input|select|textarea|button|object/;

function hidesContents(element) {
  const zeroSize = element.offsetWidth <= 0 && element.offsetHeight <= 0;

  // If the node is empty, this is good enough
  if (zeroSize && !element.innerHTML) return true;

  // Otherwise we need to check some styles
  const style = window.getComputedStyle(element);
  return zeroSize
    ? style.getPropertyValue('overflow') !== 'visible'
    : style.getPropertyValue('display') === 'none';
}

function visible(element) {
  let parentElement = element;
  while (parentElement) {
    if (parentElement === document.body) break;
    if (hidesContents(parentElement)) return false;
    parentElement = parentElement.parentNode;
  }
  return true;
}

function hasClass(element, classes) {
  return (` ${element.className} `).indexOf(` ${classes} `) > -1;
}

function focusable(element, isTabIndexNotNaN) {
  const nodeName = element.nodeName.toLowerCase();
  const classes = hasClass(element, 'slick-disabled');
  let res = ((tabbableNode.test(nodeName) && !element.disabled)
    || (nodeName === 'a' ? element.href || isTabIndexNotNaN : isTabIndexNotNaN));
  if (classes) {
    res = !classes;
  }
  return res && visible(element);
}

function tabbable(element) {
  let tabIndex;
  if (element.hasAttribute('tabindex')) {
    tabIndex = element.getAttribute('tabindex');
  }
  tabIndex = Number.parseInt(tabIndex, 10);
  const isTabIndexNaN = Number.isNaN(tabIndex);
  return (isTabIndexNaN || tabIndex >= 0) && focusable(element, !isTabIndexNaN);
}

export default function findTabbableDescendants(element) {
  return [].slice.call(element.querySelectorAll('*'), 0).filter(tabbable);
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import renderHTML from 'react-render-html';
import { getRefreshableHtmlContent } from 'storefront/components/RefreshableContent/refreshableContent-actions';

class EcmFallback extends Component {
  componentDidMount() {
    const { fallback } = this.props;
    this.props.fetchFallback(fallback, 'ecmFallback');
  }

  shouldComponentUpdate(nextProps) {
    const oldContent = get(this.props, 'refreshableHtmlContent.ecmFallback', '');
    const newContent = get(nextProps, 'refreshableHtmlContent.ecmFallback', '');
    return oldContent !== newContent;
  }

  render() {
    const { refreshableHtmlContent = {} } = this.props;

    if (isEmpty(refreshableHtmlContent.ecmFallback)) {
      return false;
    }

    return (
      <div>
        { renderHTML(refreshableHtmlContent.ecmFallback) }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  refreshableHtmlContent: state.refreshableContent.refreshableHtmlContent,
  ecmContent: state.ecm.ecmContent,
});

const mapDispatchToProps = ({
  fetchFallback: getRefreshableHtmlContent,
});

export default connect(mapStateToProps, mapDispatchToProps)(EcmFallback);

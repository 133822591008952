/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { CLOUDINARY_IMG_NOT_AVAILABLE } from 'client-utils/utilities-cloudinary';
import './transitionImages.scss';

export class TransitionCrossFadeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visited: false,
      showImage: props.src,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!this.state.visited && newProps.isHover) {
      this.setState({ visited: true });
    }
    if (newProps.src === newProps.altImage) return;

    if (!newProps.isSwatchHover) {
      this.setState({ showImage: newProps.src });
    }
  }

  render() {
    const {
      alt,
      altImage,
      altImageLoadFailed,
      imageErrorHandler,
      isHover,
      isSwatchHover,
      mainImageLoadFailed,
      src,
      title,
    } = this.props;

    const showAltImage = () => (((!altImageLoadFailed && !mainImageLoadFailed) && altImage
              && this.state.visited) || isSwatchHover);

    const shouldFadeOutImage = () => {
      return (!altImageLoadFailed && !mainImageLoadFailed) && isHover && altImage;
    };

    const showErrorImage = () => {
      return (
        <img
          src={CLOUDINARY_IMG_NOT_AVAILABLE}
          alt="No image available"
          title="No image available"
          name="mainImage"
          data-top-image
        />
      );
    };

    return (
      <Fragment>
        {showAltImage()
          && (
          <img
            src={isSwatchHover ? src : altImage}
            className={classnames({
              'bottom-image': true,
              'image-fade-out': !shouldFadeOutImage(),
            })}
            alt={alt}
            title={title}
            onError={(event) => { imageErrorHandler(event); }}
            name="mainImage"
          />
          )
        }
        {
          mainImageLoadFailed ? showErrorImage()
            : (
              <img
                src={isSwatchHover ? src : this.state.showImage}
                alt={alt}
                title={title}
                className={classnames({ 'image-fade-out': shouldFadeOutImage() })}
                onError={(event) => { imageErrorHandler(event); }}
                name="mainImage"
                data-top-image
              />
            )
      }
      </Fragment>
    );
  }
}

export default TransitionCrossFadeImage;

/* eslint import/no-cycle: 0 */
import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import window from 'window-or-global';
import { Tabs, Tab } from '@material-ui/core';
import ProductComponent from '../productComponent/productComponent';
import './MultiProductGrid.scss';

function MultiProductGrid({ multiGrid, cmsContentId, trackTags = {} }) {
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (window.performance?.navigation?.type === 2) {
      setActive(Number(window.sessionStorage.getItem(cmsContentId)) || 0);
    } else {
      setActive(0);
    }
  }, []);

  const handleChange = (e, newValue) => {
    setActive(newValue);
    window.sessionStorage.setItem(cmsContentId, newValue);
  };

  const renderTabs = () => {
    const tabs = multiGrid.map(({ fields }) => fields.title);

    return (
      <div className="tabs-bar">
        <div className="tabs-wrapper">
          <Tabs
            classes={{
              root: 'root',
              scroller: 'scroller',
              indicator: 'indicator',
              flexContainer: 'flex-container',
            }}
            variant="fullWidth"
            value={active}
            onChange={handleChange}
            TabIndicatorProps={{
              children: <span />,
            }}
          >
            {active !== null && tabs.map((title, index) => {
              return (
                <Tab
                  disableRipple
                  disableFocusRipple
                  value={index}
                  label={title}
                  classes={{
                    root: 'tab-root',
                    wrapper: 'tab-wrapper',
                  }}
                />
              );
            })}
          </Tabs>
        </div>
      </div>
    );
  };

  const renderMultiGrid = useMemo(() => {
    return (
      multiGrid.map((grid, index) => {
        const activeClass = active === index ? 'active' : 'hide';
        return (
          <div key={`tab${index}`} className={classnames('tab_content', activeClass)}>
            <ProductComponent
              trackTags={trackTags}
              cmsContentItem={grid}
              key={index}
            />
          </div>
        );
      })
    );
  }, [active]);

  return (
    <div className="multi-grid">
      {renderTabs()}
      {renderMultiGrid}
    </div>
  );
}

export default MultiProductGrid;

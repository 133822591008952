/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { getCmsProducts } from 'cms/actions';
import ProductTile from './ProductTile';

export class SingleProductComponent extends Component {
  UNSAFE_componentWillMount() {
    const { cmsProductsApi, productId, contentId } = this.props;
    if (shouldLoad(cmsProductsApi)) {
      this.props.dispatch(getCmsProducts(productId, contentId));
    }
  }


  render() {
    const {
      cmsProducts = [],
      isMobilePhone = false,
      displayOptions,
    } = this.props;

    const product = cmsProducts[0];

    if (!product) {
      return <div />;
    }

    return (
      <ProductTile
        id={product.id}
        designer={product.designerName}
        name={product.name}
        price={product.price}
        imageUrl={product.imageUrl}
        url={product.url}
        promotions={product.promotions}
        displayable={product.displayable}
        isMobilePhone={isMobilePhone}
        displayOptions={displayOptions}
        isSingleProduct
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  cmsProductsApi: state.api[`cms_products_${ownProps.contentId}`.toLowerCase()],
  cmsProducts: state.cms.products?.[ownProps.contentId],
});

export default connect(mapStateToProps)(SingleProductComponent);

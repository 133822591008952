import React from 'react';

class AffirmScript extends React.Component {
  componentDidMount() {
    if (typeof document !== 'undefined') {
      const existingScript = document.getElementById('affirmScript');
      if (!existingScript) {
        const affirmScript = document.createElement('script');
        affirmScript.async = true;
        affirmScript.id = 'affirmScript';
        affirmScript.src = `/assets/scripts/Affirm-${NMConfig.AFFIRM_ENV}.js`;
        document.head.appendChild(affirmScript);
      }
    }
  }

  render() {
    return null;
  }
}

export default AffirmScript;

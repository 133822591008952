import { getCloudinaryImageSetIfEnabled } from 'client-utils/utilities-cloudinary';

export function buildRVProducts(products) {
  return products.reduce((filteredProducts, product) => {
    if (product?.displayable) {
      const imageSet = getCloudinaryImageSetIfEnabled(product?.media?.main);
      const imageUrl = imageSet?.medium?.url
        || '/assets/images/no-image.c9a49578722aabed021ab4821bf0e705.jpeg';

      filteredProducts.push({
        id: product.id,
        designerName: product.designerName,
        name: product.title || '',
        url: product.canonicalUrl,
        price: product?.price,
        imageUrl,
        title: product?.title,
        promotions: product?.promotions,
        isGroup: !!product.isGroup,
        childProducts: [],
        displayable: product.displayable,
      });
    }

    return filteredProducts;
  }, []);
}

export const featuredDesigners = [
  {
    id: 'fd1',
    catId: 'cat10230739',
    level: 1,
    name: 'Alexander McQueen',
    url: '/c/designers-alexander-mcqueen-cat10230739?navpath=cat000000_cat000730_cat10230739',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd2',
    catId: 'cat43240829',
    level: 1,
    name: 'Balenciaga',
    url: '/c/designers-balenciaga-cat43240829?navpath=cat000000_cat000730_cat43240829',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd3',
    catId: 'cat25560734',
    level: 1,
    name: 'Brunello Cucinelli',
    url: '/c/designers-brunello-cucinelli-cat25560734?navpath=cat000000_cat000730_cat25560734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd4',
    catId: 'cat000227',
    level: 1,
    name: 'Burberry',
    url: '/c/designers-burberry-cat000227?navpath=cat000000_cat000730_cat000227',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd5',
    catId: 'cat50610733',
    level: 1,
    name: 'Canada Goose',
    url: '/c/designers-canada-goose-cat50610733?navpath=cat000000_cat000730_cat50610733',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd6',
    catId: 'cat55920979',
    level: 1,
    name: 'CHANEL',
    url: '/c/designers-chanel-cat55920979?navpath=cat000000_cat000730_cat55920979',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd7',
    catId: 'cat39620738',
    level: 1,
    name: 'Christian Louboutin',
    url: '/c/designers-christian-louboutin-shoes-cat39620738?menupath=featuredd_cat43240842_cat39620738&navAction=menu&navpath=cat000000_cat000730_cat43240842_cat39620738',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd8',
    catId: 'cat7210731',
    level: 1,
    name: 'Dolce & Gabbana',
    url: '/c/designers-dolce-gabbana-cat7210731?navpath=cat000000_cat000730_cat7210731',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd9',
    catId: 'cat67960734',
    level: 1,
    name: 'Gucci',
    url: '/c/designers-gucci-shop-all-cat67960734?menupath=featuredd_cat2880735_cat67960734&navAction=menu&navpath=cat000000_cat000730_cat2880735_cat67960734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd10',
    catId: 'cat000377',
    level: 1,
    name: 'La Mer',
    url: '/c/designers-la-mer-cat000377?navpath=cat000000_cat000730_cat000377',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd11',
    catId: 'cat43460881',
    level: 1,
    name: 'Moncler',
    url: '/c/designers-moncler-cat43460881?navpath=cat000000_cat000730_cat43460881',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd12',
    catId: 'cat43240884',
    level: 1,
    name: 'Prada',
    url: '/c/designers-prada-cat43240884?navpath=cat000000_cat000730_cat43240884',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd13',
    catId: 'cat6340738',
    level: 1,
    name: 'Saint Laurent',
    url: '/c/designers-saint-laurent-cat6340738?navpath=cat000000_cat000730_cat6340738',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd14',
    catId: 'cat43890732',
    level: 1,
    name: 'TOM FORD',
    url: '/c/designers-tom-ford-cat43890732?navpath=cat000000_cat000730_cat43890732',
    attributes: {},
    categories: [],
    children: 0,
  },

  {
    id: 'fd15',
    catId: 'cat6060749',
    level: 1,
    name: 'Valentino',
    url: '/c/designers-valentino-cat6060749?navpath=cat000000_cat000730_cat6060749',
    attributes: {},
    categories: [],
    children: 0,
  },
];

export const featuredDesignersMen = [
  {
    id: 'fd1',
    catId: 'cat41150738',
    level: 1,
    name: 'Alexander McQueen',
    url:
      '/c/designers-alexander-mcqueen-mens-cat41150738',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd2',
    catId: 'cat64230769',
    level: 1,
    name: 'Balenciaga',
    url:
      '/c/designers-balenciaga-mens-cat64230769',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd3',
    catId: 'cat47690775',
    level: 1,
    name: 'Brunello Cucinelli',
    url:
      '/c/designers-brunello-cucinelli-mens-cat47690775',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd4',
    catId: 'cat57030762',
    level: 1,
    name: 'Burberry',
    url:
      '/c/designers-burberry-mens-cat57030762',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd5',
    catId: 'cat51080734',
    level: 1,
    name: 'Canada Goose',
    url:
      '/c/designers-canada-goose-mens-cat51080734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd6',
    catId: 'cat73490737',
    level: 1,
    name: 'Christian Louboutin',
    url:
      '/c/designers-christian-louboutin-mens-cat73490737',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd7',
    catId: 'cat7370756',
    level: 1,
    name: 'Dolce & Gabbana',
    url:
      '/c/designers-dolce-gabbana-mens-cat7370756',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd9',
    catId: 'cat46930736',
    level: 1,
    name: 'Fendi',
    url:
      '/c/designers-fendi-mens-cat46930736',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd10',
    catId: 'cat50560737',
    level: 1,
    name: 'Givenchy',
    url:
      '/c/designers-givenchy-mens-cat50560737',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd17',
    catId: 'cat6170734',
    level: 1,
    name: 'GUCCI',
    url: '/c/designers-gucci-mens-cat6170734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd11',
    catId: 'cat25730734',
    level: 1,
    name: 'Moncler',
    url:
      '/c/designers-moncler-mens-cat25730734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd12',
    catId: 'cat60840745',
    level: 1,
    name: 'Off-White',
    url:
      '/c/designers-off-white-mens-cat60840745',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd13',
    catId: 'cat13550745',
    level: 1,
    name: 'Salvatore Ferragamo',
    url:
      '/c/designers-salvatore-ferragamo-mens-cat13550745',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd16',
    catId: 'cat3990738',
    level: 1,
    name: 'Theory',
    url:
      '/c/designers-theory-mens-cat3990738',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd14',
    catId: 'cat43890734',
    level: 1,
    name: 'TOM FORD',
    url:
      '/c/designers-tom-ford-mens-cat43890734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd15',
    catId: 'cat19420731',
    level: 1,
    name: 'Versace',
    url:
      '/c/designers-versace-mens-cat19420731',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd8',
    catId: 'cat000495',
    level: 1,
    name: 'ZEGNA',
    url:
      '/c/designers-ermenegildo-zegna-cat000495',
    attributes: {},
    categories: [],
    children: 0,
  },
];

export const featuredDesignersWomen = [
  {
    id: 'fd0',
    catId: 'cat10230739',
    level: 1,
    name: 'Alexander McQueen',
    url: '/c/designers-alexander-mcqueen-cat10230739',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd1',
    catId: 'cat43240829',
    level: 1,
    name: 'Balenciaga',
    url: '/c/designers-balenciaga-cat43240829',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd2',
    catId: 'cat3650751',
    level: 1,
    name: 'Bottega Veneta\t',
    url: '/c/designers-bottega-veneta-cat3650751',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd3',
    catId: 'cat25560734',
    level: 1,
    name: 'Brunello Cucinelli',
    url: '/c/designers-brunello-cucinelli-cat25560734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd4',
    catId: 'cat57030758',
    level: 1,
    name: 'Burberry',
    url: '/c/designers-burberry-womens-cat57030758',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd5',
    catId: 'cat50610733',
    level: 1,
    name: 'Canada Goose',
    url: '/c/designers-canada-goose-cat50610733',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd6',
    catId: 'cat55920979',
    level: 1,
    name: 'CHANEL',
    url: '/c/designers-chanel-cat55920979',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd7',
    catId: 'cat82060734',
    level: 1,
    name: 'Christian Louboutin',
    url: '/c/designers-christian-louboutin-womens-cat82060734',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd8',
    catId: 'cat7210731',
    level: 1,
    name: 'Dolce & Gabbana',
    url: '/c/designers-dolce-gabbana-cat7210731',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd15',
    catId: 'cat6170735',
    level: 1,
    name: 'GUCCI',
    url: '/c/designers-gucci-womens-cat6170735',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd9',
    catId: 'cat000377',
    level: 1,
    name: 'La Mer',
    url: '/c/designers-la-mer-cat000377',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd10',
    catId: 'cat43460881',
    level: 1,
    name: 'Moncler',
    url: '/c/designers-moncler-cat43460881',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd11',
    catId: 'cat43240884',
    level: 1,
    name: 'Prada',
    url: '/c/designers-prada-cat43240884',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd12',
    catId: 'cat6340738',
    level: 1,
    name: 'Saint Laurent',
    url: '/c/designers-saint-laurent-cat6340738',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd13',
    catId: 'cat43890733',
    level: 1,
    name: 'TOM FORD',
    url: '/c/designers-tom-ford-womens-cat43890733',
    attributes: {},
    categories: [],
    children: 0,
  },
  {
    id: 'fd14',
    catId: 'cat6060749',
    level: 1,
    name: 'Valentino',
    url: '/c/designers-valentino-cat6060749',
    attributes: {},
    categories: [],
    children: 0,
  },
];

import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import emptyShoppingBagSVG from 'assets/images/shoppingbag.svg';
import itemsInShoppingBagSVG from 'assets/images/itemsinbag.svg';
import cartIcon from 'assets/images/Cart_Icon.svg';

import './mobileMiniCart.scss';

const EmptyShoppingBag = ({ titleText, isNmNewHeader }) => (
  <img
    className="mobile-header-shopping-bag-icon"
    src={String(isNmNewHeader ? cartIcon : emptyShoppingBagSVG)}
    alt={titleText}
  />
);
const ItemsInShoppingBag = ({ items, titleText }) => {
  return (
    <div className="items-in-shopping-bag-container">
      <span aria-hidden="true" className="number-of-items-mobile-shopping-bag">
        {items}
      </span>
      <img
        className="mobile-header-shopping-bag-icon-with-items"
        src={String(itemsInShoppingBagSVG)}
        alt={titleText}
      />
    </div>
  );
};

const MobileMiniCart = ({
  noOfItemsInCart, internationalToggle, isDomestic, countryCode, addToCartV2Toggle, isNmNewHeader,
}) => {
  const shoppingBagTitleText = noOfItemsInCart === 0 || noOfItemsInCart > 1
    ? `Shopping Bag containing ${noOfItemsInCart} items`
    : 'Shopping Bag contains 1 item';
  const cartUrl = addToCartV2Toggle && isDomestic
    ? NMConfig.CHECKOUT_PAGE_URL_DT
    : '/checkout/cart.jsp?perCatId=&catqo=';

  return (
    <div className="mobile-only mobile-header-shopping-bag-icon-container">
      <a
        id="miniCartButtonMobile"
        href={internationalToggle && !isDomestic ? `/en-${countryCode.toLowerCase()}/international/cart` : cartUrl}
        onClick={() => {
          window.location.href = `${internationalToggle && !isDomestic ? `/en-${countryCode.toLowerCase()}/international/cart` : cartUrl}`;
        }}
        role="button"
      >
        {noOfItemsInCart ? (
          <ItemsInShoppingBag items={noOfItemsInCart} titleText={shoppingBagTitleText} />
        ) : (
          <EmptyShoppingBag titleText={shoppingBagTitleText} isNmNewHeader={isNmNewHeader} />
        )}
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  noOfItemsInCart: state.miniCart.noOfItemsInCart,
  isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
  countryCode: get(state, 'locale.countryCode', 'US'),
  internationalToggle: get(state, 'toggles.INTERNATIONAL', false),
  addToCartV2Toggle: get(state, 'toggles.ADD_TO_CART_V2', false),
});

export default connect(mapStateToProps)(MobileMiniCart);

/* eslint import/no-cycle: 0 */
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import classNames from 'classnames';
import { PAGE_ID_PLP } from 'shared/actions/actions-page';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import RenderContentItem from '../renderContentItem';
import './visualNav.scss';

const CN = 'visual-nav';

export function ConnectedVisualNav(props) {
  const {
    pageId,
    cmsContentItem: {
      fields: { slides, trackingTags: ownTrackTags = {} },
    },
    trackTags: propsTrackTags = {},
    isMobilePhone,
    isTablet,
  } = props;
  const trackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  let initalSlidesToShow = 6;
  if (isTablet) {
    initalSlidesToShow = 4;
  } else if (isMobilePhone) {
    initalSlidesToShow = 2.5;
  }
  const settings = {
    arrows: true,
    className: classNames(CN, 'updated-style'),
    infinite: false,
    slidesToShow: initalSlidesToShow,
    swipeToSlide: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          draggable: false,
        },
      },
    ],
  };

  if (isMobilePhone) {
    settings.slidesToScroll = 2;
    settings.arrows = false;
    settings.swipeToSlide = false;
  }

  const parentType = 'NewVisualNav';

  return (
    (pageId === PAGE_ID_PLP || typeof window !== 'undefined') && (
      <Slider {...settings}>
        {slides.map((slide) => (
          <RenderContentItem
            trackTags={trackTags}
            cmsContentItem={slide}
            key={slide.sys.id}
            parentType={parentType}
          />
        ))}
      </Slider>
    )
  );
}

const mapStateToProps = (state) => ({
  pageId: get(state, 'page.pageId', ''),
  isMobilePhone: get(state, 'device.isMobilePhone', false),
  isTablet: get(state, 'device.isTablet', false),
});

export default connect(mapStateToProps)(ConnectedVisualNav);

import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import facebookIcon from 'assets/images/social-facebook.svg';
import twitterIcon from 'assets/images/social-twitter.svg';
import emailIcon from 'assets/images/social-email.svg';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import './socialSharing.scss';

function getTitle(currentStory) {
  const v2Title = currentStory?.fields?.metaData?.fields?.seoTitle;
  const v1Title = currentStory?.fields?.metaContentAsset?.fields?.title || 'Neiman Marcus';
  const title = v2Title || v1Title;
  return title;
}

const SocialSharing = (props) => {
  const { currentStory, session } = props;
  const url = session?.url;
  const title = getTitle(currentStory);

  return (
    <div className="social-sharing-container">
      <p className="social-sharing-title">Share This Story</p>
      <div className="social-sharing-links">
        <div className="social-button">
          <FacebookShareButton
            url={url}
            quote={title}
          >
            <img src={facebookIcon} />
          </FacebookShareButton>
        </div>
        <div className="social-button">
          <TwitterShareButton
            url={url}
            title={title}
          >
            <img src={twitterIcon} />

          </TwitterShareButton>
        </div>
        <div className="social-button">
          <EmailShareButton
            url={url}
            body={title}
            subject={title}
          >
            <img src={emailIcon} />
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentStory: get(state, 'cms.entries[0]', {}),
  session: state.session,
});

export default connect(mapStateToProps)(SocialSharing);

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import React from 'react';
import EcmText from 'ecm/components/EcmText';

export default function EcmGetImageWithTextTextBlocks(
  dataObj,
  ecmSlot,
  improvePerformanceOfStyleToggle,
) {
  const ecmProperties = get(dataObj, 'properties', {});
  const dektopTextPositionValue = get(ecmProperties, 'desktop.textPosition', 'bottom');
  const tabletTextPositionValue = isEmpty(get(ecmProperties, 'tablet.textPosition')) ? dektopTextPositionValue : get(ecmProperties, 'tablet.textPosition');
  const mobileTextPositionValue = isEmpty(get(ecmProperties, 'mobile.textPosition')) ? dektopTextPositionValue : get(ecmProperties, 'mobile.textPosition');
  const desktopVerticalTextPositionValue = get(ecmProperties, 'desktop.verticalTextPosition', 'middle');
  const tabletVerticalTextPositionValue = isEmpty(get(ecmProperties, 'tablet.verticalTextPosition')) ? desktopVerticalTextPositionValue : get(ecmProperties, 'tablet.verticalTextPosition');
  const mobileVerticalTextPositionValue = isEmpty(get(ecmProperties, 'mobile.verticalTextPosition')) ? desktopVerticalTextPositionValue : get(ecmProperties, 'mobile.verticalTextPosition');
  const dektopTextPosition = dektopTextPositionValue === 'left' || dektopTextPositionValue === 'right'
    ? `desktop-text-position-${dektopTextPositionValue} desktop-text-position-${dektopTextPositionValue}-${desktopVerticalTextPositionValue}`
    : `desktop-text-position-${dektopTextPositionValue}`;
  const tabletTextPosition = tabletTextPositionValue === 'left' || tabletTextPositionValue === 'right'
    ? `tablet-text-position-${tabletTextPositionValue} tablet-text-position-${tabletTextPositionValue}-${tabletVerticalTextPositionValue}`
    : `tablet-text-position-${tabletTextPositionValue}`;
  const mobileTextPosition = mobileTextPositionValue === 'left' || mobileTextPositionValue === 'right'
    ? `mobile-text-position-${mobileTextPositionValue} mobile-text-position-${mobileTextPositionValue}-${mobileVerticalTextPositionValue}`
    : `mobile-text-position-${mobileTextPositionValue}`;
  const desktopText = get(dataObj, 'text.properties.text') === null || isEmpty(get(dataObj, 'text.properties.text')) ? '' : get(dataObj, 'text.properties.text');
  let tabletText = get(dataObj, 'text.properties.tabletText') === null || isEmpty(get(dataObj, 'text.properties.tabletText')) ? '' : get(dataObj, 'text.properties.tabletText');
  let mobileText = get(dataObj, 'text.properties.mobileText') === null || isEmpty(get(dataObj, 'text.properties.mobileText')) ? '' : get(dataObj, 'text.properties.mobileText');
  let textBefore = '';
  let textAfter = '';
  let desktopTextBefore = '';
  let tabletTextBefore = '';
  let mobileTextBefore = '';
  let desktopTextAfter = '';
  let tabletTextAfter = '';
  let mobileTextAfter = '';
  if (!isEmpty(desktopText)) {
    if (isEmpty(tabletText) && isEmpty(mobileText)) {
      if (
        mobileTextPositionValue === 'left'
        || tabletTextPositionValue === 'left'
        || dektopTextPositionValue === 'left'
        || mobileTextPositionValue === 'top'
        || tabletTextPositionValue === 'top'
        || dektopTextPositionValue === 'top') {
        textBefore = <div className="textwithimage-text-before"><EcmText text={get(dataObj, 'text', {})} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
      if (
        mobileTextPositionValue === 'right'
        || tabletTextPositionValue === 'right'
        || dektopTextPositionValue === 'right'
        || mobileTextPositionValue === 'bottom'
        || tabletTextPositionValue === 'bottom'
        || dektopTextPositionValue === 'bottom') {
        textAfter = <div className="textwithimage-text-after"><EcmText text={get(dataObj, 'text', {})} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
    } else {
      tabletText = isEmpty(tabletText) ? desktopText : tabletText;
      mobileText = isEmpty(mobileText) ? tabletText : mobileText;
      const textObj = get(dataObj, 'text', {});
      const tabletTextPropertiesObj = { ...get(textObj, 'properties'), text: tabletText };
      const tabletTextObj = { ...textObj, properties: tabletTextPropertiesObj };
      const mobileTextPropertiesObj = { ...get(textObj, 'properties'), text: mobileText };
      const mobileTextObj = { ...textObj, properties: mobileTextPropertiesObj };
      if (
        dektopTextPositionValue === 'left'
        || dektopTextPositionValue === 'top') {
        desktopTextBefore = <div className="textwithimage-desktop-text-before"><EcmText text={textObj} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
      if (
        dektopTextPositionValue === 'right'
        || dektopTextPositionValue === 'bottom') {
        desktopTextAfter = <div className="textwithimage-desktop-text-after"><EcmText text={textObj} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
      if (
        tabletTextPositionValue === 'left'
        || tabletTextPositionValue === 'top') {
        tabletTextBefore = <div className="textwithimage-tablet-text-before"><EcmText text={tabletTextObj} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
      if (
        tabletTextPositionValue === 'right'
        || tabletTextPositionValue === 'bottom') {
        tabletTextAfter = <div className="textwithimage-tablet-text-after"><EcmText text={tabletTextObj} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
      if (
        mobileTextPositionValue === 'left'
        || mobileTextPositionValue === 'top') {
        mobileTextBefore = <div className="textwithimage-mobile-text-before"><EcmText text={mobileTextObj} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
      if (
        mobileTextPositionValue === 'right'
        || mobileTextPositionValue === 'bottom') {
        mobileTextAfter = <div className="textwithimage-mobile-text-after"><EcmText text={mobileTextObj} ecmSlot={ecmSlot} formatAdaptiveStyleBlock={false} improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle} /></div>;
      }
    }
  }
  return ({
    dektopTextPosition,
    tabletTextPosition,
    mobileTextPosition,
    textBefore,
    textAfter,
    desktopTextBefore,
    tabletTextBefore,
    mobileTextBefore,
    desktopTextAfter,
    tabletTextAfter,
    mobileTextAfter,
  });
}

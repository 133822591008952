/* eslint import/no-cycle: 0 */
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { shouldLoad } from 'universal/http-client';
import { openModal } from 'client/common/components/Modal/actions';
import videoPlayButton from 'assets/images/video-play-button.svg';
import classnames from 'classnames';
import {
  getModalContent,
  setActiveEntryId,
} from '../../actions/index';
import './videoPlayButton.scss';


const VideoPlayButton = (props) => {
  const {
    cmsContentItem = {},
    openModal,
    getModalContent,
    setActiveEntryId,
    isMobile,
  } = props;
  const fields = get(cmsContentItem, 'fields', {});
  const {
    videoAsset,
  } = fields;


  const onClickHandler = (e) => {
    e.preventDefault();
    const { modalContentApi } = props;
    const entryId = get(videoAsset, 'sys.id', '');
    if (entryId && shouldLoad(modalContentApi)) {
      getModalContent(entryId);
    }
    setActiveEntryId(entryId);
    openModal({
      type: 'InfoModal',
      fullWidth: true,
    });
  };

  return (
    <span onClick={onClickHandler}>
      <img
        src={videoPlayButton}
        className={classnames('play-button',
          { 'is-mobile': isMobile })}
      />
    </span>
  );
};


const mapDispatchToProps = (dispatch) => {
  const actionsToBind = {
    openModal,
    getModalContent,
    setActiveEntryId,
  };
  const boundActions = bindActionCreators(actionsToBind, dispatch);

  return {
    ...boundActions,
    dispatch,
  };
};

const mapStateToProps = (state) => ({
  isMobile: get(state, 'device.isMobilePhone'),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayButton);

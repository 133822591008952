import React, { PureComponent } from 'react';

import './skipToContent.scss';

// eslint-disable-next-line react/prefer-stateless-function
class SkipToContent extends PureComponent {
  render() {
    return (<a className="skip-content" id="skipToMainContent" href="#mainContent">Skip To Main Content</a>);
  }
}

export default SkipToContent;

import React, { PureComponent } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
class NavSliderContainer extends PureComponent {
  render() {
    return <div id="navSliderContainer" tabIndex="-1" />;
  }
}

export default NavSliderContainer;

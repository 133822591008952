import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  setHideOnDesktop,
  setHideOnTablet,
  setHideOnMobile,
} from 'ecm/actions/actions-ecmcontent';
import { InView } from 'react-intersection-observer';
import EcmImageMap from '../EcmImageMap/ecmImageMap';
import EcmTextOverlays from '../EcmTextOverlays/ecmTextOverlays';
import EcmTextPositionedOverlays from '../EcmTextOverlays/ecmTextPositionedOverlays';
import EcmButtonOverlays from '../EcmButtonOverlays/EcmButtonOverlays';
import { ecmGetAdaptiveStyleBlock, ecmGetOptimizedAdaptiveStyleBlock, isOverlayPositioned } from '../../utilities';
import Image from '../EcmImage/EcmImage';

const wrapAnchor = (anchor, element, altHtml) => {
  let pictureElt = element;
  if (!isEmpty(anchor)) pictureElt = (<a href={anchor} aria-label={altHtml}>{pictureElt}</a>);
  return pictureElt;
};

export class EcmAdaptiveImage extends Component {
  componentDidMount() {
    const {
      picture,
      ecmSlot,
      setHideOnDesktop: setHideOnDesktopProp,
      setHideOnTablet: setHideOnTabletProp,
      setHideOnMobile: setHideOnMobileProp,
    } = this.props;
    if (get(picture, 'properties.images.desktop.hide', false)) {
      setHideOnDesktopProp(ecmSlot);
    }
    if (get(picture, 'properties.images.tablet.hide', false)) {
      setHideOnTabletProp(ecmSlot);
    }
    if (get(picture, 'properties.images.mobile.hide', false)) {
      setHideOnMobileProp(ecmSlot);
    }
  }

  render() {
    const {
      picture,
      ecmSlot,
      formatAdaptiveStyleBlock = true,
      textOverlayElements,
      buttonOverlayElements,
      improvePerformanceOfStyleToggle = false,
    } = this.props;

    const ecmStyle = get(ecmSlot, 'className', '');
    const ecmComponentId = get(picture, 'componentId', '');
    const creativeId = get(picture, 'creative_id');
    const ecmImages = get(picture, 'properties.images', '');
    const ecmPictureStyle = improvePerformanceOfStyleToggle ? `ecm-${ecmComponentId}` : `ecm-container__image__${ecmComponentId}`;
    const linkurl = get(picture, 'properties.linkurl', '');
    const classes = improvePerformanceOfStyleToggle
      ? classNames('ecm-container__image optst', ecmStyle, ecmPictureStyle)
      : classNames('ecm-container__image', ecmStyle, ecmPictureStyle);

    const adaptiveStyleBlockFunc = () => {
      if (formatAdaptiveStyleBlock) {
        return improvePerformanceOfStyleToggle
          ? ecmGetOptimizedAdaptiveStyleBlock(ecmImages, ecmPictureStyle)
          : ecmGetAdaptiveStyleBlock(ecmImages, ecmPictureStyle);
      }
      return '';
    };
    const adaptiveStyleBlock = adaptiveStyleBlockFunc();
    const defaultImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABkAAAAABCAQAAAAs0arfAAAAGklEQVR42u3BIQEAAAACIP1/2hcmoAEAADgZGQIAAgXe4QoAAAAASUVORK5CYII=';
    const titleHtml = picture.properties.title === null || isEmpty(picture.properties.title) ? '' : picture.properties.title;
    const altHtml = picture.properties.alttext === null || isEmpty(picture.properties.alttext) ? '' : picture.properties.alttext;
    const desktopImg = `${(get(ecmImages, 'desktop.imagesrc', '') === null || isEmpty(get(ecmImages, 'desktop.imagesrc', ''))) ? defaultImg : get(ecmImages, 'desktop.imagesrc', '')}`;
    const tabletImg = `${(get(ecmImages, 'tablet.imagesrc', '') === null || isEmpty(get(ecmImages, 'tablet.imagesrc', ''))) ? '' : get(ecmImages, 'tablet.imagesrc', '')}`;
    const mobileImg = `${(get(ecmImages, 'mobile.imagesrc', '') === null || isEmpty(get(ecmImages, 'mobile.imagesrc', ''))) ? '' : get(ecmImages, 'mobile.imagesrc', '')}`;
    const rawPictureElt = (
      <Image
        alt={altHtml}
        title={titleHtml}
        desktopImg={desktopImg}
        tabletImg={tabletImg}
        mobileImg={mobileImg}
      />
    );
    const pictureElt = wrapAnchor(linkurl, rawPictureElt);
    let lazyLoad = get(picture, 'properties.lazyLoadFlag') === 'true';
    const overlays = [];
    let hasAspectRatio = true;

    if (!isEmpty(desktopImg)) {
      const aspectRatio = parseFloat(
        ecmImages.desktop.imageHeight / ecmImages.desktop.imageWidth * 100
      );
      if (isNaN(aspectRatio)) {
        hasAspectRatio = false;
        lazyLoad = false;
      } else {
        let breakPointClasses;
        if (isEmpty(tabletImg)) {
          if (isEmpty(mobileImg)) {
            breakPointClasses = '';
          } else {
            breakPointClasses = 'hide-on-mobile';
          }
        } else {
          breakPointClasses = 'hide-on-tablet hide-on-mobile';
        }
        const imageStyles = { paddingTop: `${aspectRatio}%` };
        if (!lazyLoad) imageStyles.opacity = 0;
        const imgMarkup = <div className={classNames('ecm-container__image__picture__overlay', breakPointClasses)} style={imageStyles} />;
        overlays.push(imgMarkup);
      }
    }

    if (!isEmpty(tabletImg)) {
      const aspectRatio = parseFloat(
        ecmImages.tablet.imageHeight / ecmImages.tablet.imageWidth * 100
      );
      if (isNaN(aspectRatio)) {
        lazyLoad = false;
      } else {
        let breakPointClasses;
        if (isEmpty(mobileImg)) {
          breakPointClasses = 'hide-on-desktop';
        } else {
          breakPointClasses = 'hide-on-desktop hide-on-mobile';
        }
        const imageStyles = { paddingTop: `${aspectRatio}%` };
        if (!lazyLoad) imageStyles.opacity = 0;
        const imgMarkup = <div className={classNames('ecm-container__image__picture__overlay', breakPointClasses)} style={imageStyles} />;
        overlays.push(imgMarkup);
      }
    }

    if (!isEmpty(mobileImg)) {
      const aspectRatio = parseFloat(
        ecmImages.mobile.imageHeight / ecmImages.mobile.imageWidth * 100
      );
      if (isNaN(aspectRatio)) {
        lazyLoad = false;
      } else {
        const breakPointClasses = 'hide-on-desktop hide-on-tablet';
        const imageStyles = { paddingTop: `${aspectRatio}%` };
        if (!lazyLoad) imageStyles.opacity = 0;
        const imgMarkup = <div className={classNames('ecm-container__image__picture__overlay', breakPointClasses)} style={imageStyles} />;
        overlays.push(imgMarkup);
      }
    }

    const pictureInView = () => {
      const overlays = document.querySelectorAll(`.${ecmPictureStyle} .ecm-container__image__picture__overlay`);
      forEach(overlays, (overlay) => overlay.classList.add('item-fade'));
      if (window.picturefill) window.picturefill();
      return rawPictureElt;
    };

    const renderImage = () => {
      if (lazyLoad) {
        return (
          <InView
            rootMargin="100px"
            triggerOnce
          >
            {({ inView, ref }) => (
              <div className="ecm-container__image__picture__image" ref={ref}>
                {
                  (inView)
                    ? pictureInView()
                    : <img src={defaultImg} alt={altHtml} title={titleHtml} />
                }
              </div>
            )}
          </InView>
        );
      }
      return (
        <div className="ecm-container__image__picture__image">
          { rawPictureElt }
        </div>
      );
    };

    const renderOverlays = () => {
      const overlayElements = overlays.map((overlay) => overlay);
      return wrapAnchor(linkurl, overlayElements, altHtml);
    };

    const hasImageMaps = !isEmpty(get(ecmImages, 'desktop.map')) || !isEmpty(get(ecmImages, 'tablet.map')) || !isEmpty(get(ecmImages, 'mobile.map'));
    const imageMaps = hasImageMaps
      && <EcmImageMap picture={picture} />;

    const renderTextOverlays = (textOverlayElements) => {
      return isOverlayPositioned(textOverlayElements)
        ? (
          <EcmTextPositionedOverlays
            dataObj={textOverlayElements}
            ecmSlot={ecmSlot}
          />
        )
        : (
          <EcmTextOverlays
            dataObj={textOverlayElements}
            ecmSlot={ecmSlot}
          />
        );
    };

    if (improvePerformanceOfStyleToggle) {
      return (
        <div>
          { adaptiveStyleBlock }
          <div className={classes}>
            <div className="ecm-container__image__picture" creativeid={creativeId}>
              { !hasAspectRatio && pictureElt }
              { hasAspectRatio && renderImage() }
              { hasAspectRatio && renderOverlays() }
              { imageMaps }
              { textOverlayElements && renderTextOverlays(textOverlayElements) }
              {
                buttonOverlayElements
                && (
                  <EcmButtonOverlays
                    dataObj={buttonOverlayElements}
                    inheritedTabImage={
                      desktopImg === tabletImg || isEmpty(tabletImg)
                    }
                    inheritedMobileImage={
                      desktopImg === mobileImg || isEmpty(mobileImg)
                    }
                    ecmSlot={ecmSlot}
                  />
                )
              }
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={classes}>
          <div className="ecm-container__image__picture" creativeid={creativeId}>
            { !hasAspectRatio && pictureElt }
            { hasAspectRatio && renderImage() }
            { hasAspectRatio && renderOverlays() }
            { imageMaps }
            { textOverlayElements && renderTextOverlays(textOverlayElements) }
            {
              buttonOverlayElements
              && (
                <EcmButtonOverlays
                  dataObj={buttonOverlayElements}
                  inheritedTabImage={
                    desktopImg === tabletImg || isEmpty(tabletImg)
                  }
                  inheritedMobileImage={
                    desktopImg === mobileImg || isEmpty(mobileImg)
                  }
                  ecmSlot={ecmSlot}
                />
              )
            }
            { adaptiveStyleBlock }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = ({
  setHideOnDesktop,
  setHideOnTablet,
  setHideOnMobile,
});

export default connect(null, mapDispatchToProps)(EcmAdaptiveImage);

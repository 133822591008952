import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { ScrollToTopButton } from './ScrollToTop.styles';
import IconArrowUp from '../../../assets/images/arrow-up.svg';

const ScrollToTop = ({ isMobilePhone }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 300
        ? setShowScrollToTop(true)
        : setShowScrollToTop(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);
  }, []);

  const handleScrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  if (!showScrollToTop) return null;

  return (
    <ScrollToTopButton
      aria-label="Back to top"
      onClick={(e) => {
        handleScrollToTop(e);
      }}
      isMobilePhone={isMobilePhone}
    >
      {<img alt="Back to top" src={IconArrowUp} />}
    </ScrollToTopButton>
  );
};

const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone', false),
  };
};

export default connect(mapStateToProps)(ScrollToTop);

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pullAt from 'lodash/pullAt';
import { SearchSuggestions } from 'srp/components/atoms/SearchSuggestions/SearchSuggestions';
import { openCollapseSearchBoxModal } from 'client/common/actions/actions-page';
import {
  formatTopItems, isModalOpen, isCollapsedSearchBarOn,
} from 'srp/utils/srpUtils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { setGender, setTypeaheadUtag } from 'shared/components/App/app-actions';
import { GENDER_PLACEMENT } from 'universal/constants/genderPlacement';
import {
  loadTypeaheadUIConfig,
  setMobileTextBoxValue,
  setRecentlySearchedAnalytics,
} from '../search-actions';
import {
  getMobileSearchTerm, getTypeaheadUIConf, typeaheadSuggestionPayload,
} from '../search-reducers';
import { searchFormSubmit } from '../submitHelper';

import './collapseSearchBarMobile.scss';
import '../bloomreach.scss';

export class CollapseSearchBarMobile extends Component {
  constructor(props) {
    super(props);
    const {
      dtGender,
      typeaheadUIConf,
    } = props;

    this.state = {
      inputIsFocused: false,
      recentSearchHover: false,
      isIconClicked: false,
      selectedGender: dtGender,
      typeaheadUIConf,
      topProducts: {},
      typeaheadSuggestions: {},
      isSuggestionSelected: false,
      suggestions: [],
    };

    this.handleSelectionOnRecentSearch = this.handleSelectionOnRecentSearch.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);

    this.recentSearchRef = createRef(null);
    this.mobileBrSearchInput = createRef(null);
    this.modalBrSearchInput = createRef(null);
    this.searchIcon = null;

    this.setMobileBrSearchInput = (element, insideModal) => {
      if (insideModal) {
        this.modalBrSearchInput = element;
        this.modalBrSearchInput.focus();
      } else {
        this.mobileBrSearchInput = element;
      }
    };

    this.setSearchIcon = (element) => {
      this.searchIcon = element;
    };

    this.handleOnBlur = () => {
      this.setState({
        inputIsFocused: false,
        selectedGender: this.props.dtGender,
      });
      if (this.searchIcon) this.searchIcon.disabled = false;
    };

    this.setFocusAtEnd = (searchIcon) => {
      searchIcon.target.disabled = true;
      if (this.mobileBrSearchInput) {
        const searchTerm = this.mobileBrSearchInput.value;
        this.mobileBrSearchInput.value = '';
        this.mobileBrSearchInput.value = searchTerm;
        this.mobileBrSearchInput.focus();
      }
    };

    this.onRecentSearchHover = this.onRecentSearchHover.bind(this);
    this.openRecentSearchOnTouch = this.openRecentSearchOnTouch.bind(this);
    this.moveCursorToEnd = this.moveCursorToEnd.bind(this);
    this.initiateKeyboard = this.initiateKeyboard.bind(this);
  }

  componentDidMount() {
    const {
      searchTerm,
      setRecentlySearchedAnalytics,
      searchSuggestionsToggle,
      isSearchModalOpen,
      insideModal,
      topProducts,
      typeaheadSuggestionPayload,
    } = this.props;

    const storageKey = 'SRP_SEARCH_RECENT_KEYWORDS';
    const recentSearchCount = 7;

    if (searchSuggestionsToggle) {
      try {
        const recentSearches = typeof (localStorage) !== 'undefined'
          ? (JSON.parse(localStorage.getItem(storageKey)) || [])
          : false;
        if (recentSearches?.length >= recentSearchCount) {
          recentSearches.splice(recentSearchCount, recentSearches.length);
        }

        if (searchTerm !== '') {
          if (recentSearches) {
            const repeatedSearchValue = recentSearches.indexOf(searchTerm);
            if (repeatedSearchValue > -1) {
              pullAt(recentSearches, repeatedSearchValue);
            } else {
              setRecentlySearchedAnalytics(false);
            }
            recentSearches.unshift(searchTerm);
          }
        }
      } catch (e) {
        localStorage.removeItem(storageKey);
      }
    }
    if (isSearchModalOpen) {
      setTimeout(() => this.modalBrSearchInput.focus(), 20);
    }

    // Populates initial BR results if there's a previous term
    if (insideModal && searchTerm.length > 0) {
      if (typeof window !== 'undefined' && window.$ && window.BRAutosuggest) {
        window.BRAutosuggest.search(searchTerm);
      }
    }

    this.setState({
      topProducts,
      typeaheadSuggestions: typeaheadSuggestionPayload,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.typeaheadSuggestionPayload !== prevProps.typeaheadSuggestionPayload) {
      this.onSearchTermChange();
    }
  }

  onRecentSearchHover(hoverStatus) {
    this.setState((prevState) => {
      const resetGenderValue = hoverStatus === false
        ? this.props.dtGender
        : this.state.selectedGender;
      return {
        ...prevState,
        recentSearchHover: hoverStatus,
        selectedGender: resetGenderValue,
      };
    });
  }

  onSearchTermChange() {
    const { typeaheadSuggestionPayload } = this.props;
    this.setState({
      typeaheadSuggestions: { ...typeaheadSuggestionPayload },
    });
  }

  handleSelectionOnRecentSearch(value) {
    const { handleOnChange, setRecentlySearchedAnalytics } = this.props;
    const thisContext = this;
    if (value) {
      setRecentlySearchedAnalytics(true);
      handleOnChange(value);
      setTimeout(() => {
        if (thisContext.mobileSearchRef) {
          thisContext.mobileSearchRef.click();
        }
      }, 200);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        recentSearchHover: false,
        inputIsFocused: false,
      }));
    }
  }

  handleTextChange(value) {
    const {
      handleOnChange,
    } = this.props;
    handleOnChange(value);
  }

  openRecentSearchOnTouch() {
    this.setState((prevState) => ({
      ...prevState,
      inputIsFocused: true,
    }));
  }

  moveCursorToEnd(modalBrSearchInput) {
    const inputValue = modalBrSearchInput;
    if (inputValue && inputValue.setSelectionRange) {
      const inputLength = inputValue.value.length;
      inputValue.setSelectionRange(inputLength, inputLength);
    }
  }

  handleInputClick(e) {
    this.setState((prevState) => ({
      ...prevState,
      isIconClicked: true,
    }));

    if (e && e.target) {
      const tempInput = document.createElement('input');
      e.target.parentNode.insertBefore(tempInput, e.target);
      tempInput.classList.add('tempInput');
      tempInput.setAttribute('id', 'temporaryInput');
      tempInput.focus();
    }
  }

  initiateKeyboard() {
    const tempInput = document.getElementById('temporaryInput');
    setTimeout(() => {
      tempInput && tempInput.parentNode && tempInput.parentNode.removeChild(tempInput);
    }, 20);
  }

  render() {
    const {
      searchTerm,
      searchSuggestionsToggle,
      topProducts,
      isTypeaheadSuggestionEmpty,
      openCollapseSearchBoxModal,
      insideModal,
      setTypeaheadUtag,
      isCollapsedSearchBarToggle,
    } = this.props;
    const {
      inputIsFocused,
      recentSearchHover,
      isIconClicked,
      selectedGender,
      typeaheadUIConf,
      typeaheadSuggestions,
    } = this.state;

    const selectedTopProducts = topProducts;

    if (isIconClicked && !insideModal) {
      const modalClassName = 'collapse-search-box-modal';
      const modalProps = { testProp: true };
      openCollapseSearchBoxModal(modalClassName, modalProps);
      this.setState({ isIconClicked: false });
      document.getElementById('mobileBrSearchInput') && document.getElementById('mobileBrSearchInput').blur();
    }

    const collapseSearchBoxClassNames = 'mobile-only collapse-search-box--visible-sticky';

    return (
      <div className={`mobile-only collapse-search-container ${isIconClicked ? 'search-open' : ''}`}>
        <form
          action="/s/"
          className={classnames(collapseSearchBoxClassNames)}
          onSubmit={(e) => {
            searchFormSubmit(e, searchTerm);
          }}
          method="GET"
          style={{
            padding: insideModal ? '10px 10px 0' : '0 10px',
          }}
        >
          <input title="from-input" name="from" type="hidden" value="brSearch" />
          <div
            className={
              classnames({ 'input-search': insideModal })
            }
          >
            <input title="l-input" name="l" type="hidden" value={searchTerm} />
            <input
              type="button"
              ref={(input) => input && this.setSearchIcon && this.setSearchIcon(input)}
              name="search-icon"
              title="search icon"
              aria-label="Get Results"
              className={
                classnames(
                  { 'collapse-search-box__search-icon': !insideModal },
                  { 'collapse-search-box__collapse-icon': insideModal },
                )
              }
              onClick={(e) => { this.setFocusAtEnd(e); }}
              onFocus={() => {
                if (inputIsFocused) {
                  setTimeout(() => { this.modalBrSearchInput.focus(); }, 20);
                }
              }}
              style={insideModal ? { transform: 'TranslateX(-10px)' } : {}}
            />
            {!insideModal
              ? (
                <input
                  ref={(input) => input && this.setMobileBrSearchInput(input, false)}
                  title="search-term-input"
                  placeholder="What are you looking for?"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  aria-label="Search Box"
                  id="mobileBrSearchInput"
                  name="q"
                  className="collapse-search-box__text"
                  value={searchTerm}
                  onTouchStart={(e) => this.handleInputClick(e)}
                  onChange={(e) => this.handleTextChange(e.target.value)}
                  onBlur={() => this.handleOnBlur()}
                  onFocus={() => { this.setState({ inputIsFocused: true, isIconClicked: true }); }}
                />
              ) : (
                <input
                  ref={(input) => input && this.setMobileBrSearchInput(input, true)}
                  title="search-term-input"
                  placeholder="What are you looking for?"
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  aria-label="Search Box"
                  id="modalBrSearchInput"
                  name="q"
                  className="collapse-search-box__text-typeahead-inside"
                  value={searchTerm}
                  onChange={(e) => this.handleTextChange(e.target.value)}
                  onFocus={() => {
                    this.setState({ inputIsFocused: true, isIconClicked: true });
                    setTimeout(() => {
                      this.modalBrSearchInput
                      && this.modalBrSearchInput.focus();
                    }, 200);
                    this.moveCursorToEnd(this.modalBrSearchInput);
                  }}
                  onTouchEnd={() => {
                    this.openRecentSearchOnTouch();
                    setTimeout(() => {
                      this.modalBrSearchInput
                      && this.modalBrSearchInput.focus();
                    }, 200);
                  }}
                  onTouchStart={this.initiateKeyboard()}
                />
              )
            }
            <div className="brm-autosuggest-wrap-mobile"><div id="newBRTypeAhead" /></div>
          </div>
          {searchSuggestionsToggle
            && (inputIsFocused || recentSearchHover)
            && (
              <div className={`collapsesearch-suggestions-renderer  ${isIconClicked ? 'search-open' : ''}`}>
                <SearchSuggestions
                  closeRecentSearchOnClear={this.onRecentSearchHover}
                  handleSelection={this.handleSelectionOnRecentSearch}
                  mobileView
                  arrowIndex={-1}
                  forwardRef={this.recentSearchRef}
                  typeaheadData={typeaheadUIConf}
                  typeaheadSuggestionPayload={typeaheadSuggestions}
                  searchTerm={searchTerm}
                  topItems={
                    !isEmpty(selectedTopProducts)
                      ? formatTopItems(selectedTopProducts)
                      : []
                  }
                  noResults={isTypeaheadSuggestionEmpty}
                  selectedGender={selectedGender}
                  isGenderTypeahead={false}
                  setGender={this.props.setGender}
                  setTypeaheadUtag={setTypeaheadUtag}
                  storageKey="SRP_SEARCH_RECENT_KEYWORDS"
                  onListClick={() => { }}
                  brand="NM"
                  collapsedSearchExperience={isCollapsedSearchBarToggle}
                />
              </div>
            )
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const dtGender = getGenderWithOverride(state);
  return {
    searchTerm: getMobileSearchTerm(state),
    searchSuggestionsToggle: get(state, 'toggles.RECENTLY_SEARCHED', false),
    pageId: get(state, 'page.pageId', ''),
    routing: get(state, 'routing.locationBeforeTransitions', {}),
    typeaheadUIConf: getTypeaheadUIConf(state),
    isSearchModalOpen: isModalOpen(state, 'CollapseSearchModal'),
    typeaheadSuggestionPayload: typeaheadSuggestionPayload(state),
    isTypeaheadSuggestionEmpty: get(state, 'search.isTypeaheadSuggestionEmpty', false),
    topProducts: get(state, 'srp.search.topProducts', []),
    selectedGender: get(state, 'srp.search.ta_gender'),
    dtGender,
    isHNRightToggle: get(state, 'toggles.HN_RIGHT', false)
      || get(state, 'abTestsOpt.tl228.variation', 'a') === 'b',
    isCollapsedSearchBarToggle: isCollapsedSearchBarOn(state),
  };
};
const mapDispatchToProps = ({
  handleOnChange: setMobileTextBoxValue,
  setRecentlySearchedAnalytics,
  openCollapseSearchBoxModal,
  loadTypeaheadUIConfig,
  setGender: (gender) => setGender(gender, GENDER_PLACEMENT.HEADER),
  setTypeaheadUtag,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollapseSearchBarMobile);

import React from 'react';
import { connect } from 'react-redux';
import { isCountry } from 'client-utils/utilities-country';
import { UNITED_STATES } from 'storefront/components/constants';

export const DumbCountrySensitiveLink = (props) => {
  const {
    linkClass, urlPath, title, localeUrl, countryCode,
  } = props;
  const hrefLink = isCountry(countryCode, UNITED_STATES) ? urlPath : localeUrl + urlPath;
  return (<a className={linkClass} href={hrefLink}>{title}</a>);
};

const mapStateToProps = (state) => ({
  localeUrl: state.locale.localeUrl,
  countryCode: state.locale.countryCode,
});

export default connect(mapStateToProps)(DumbCountrySensitiveLink);

import React from 'react';
import EcmRow from '../EcmRow';

const EcmComplex = ({
  ecmContent,
  ecmSlot,
  improvePerformanceOfStyleToggle,
  countryCode,
}) => {
  return (
    <div className="ecm-container__complex grid-100 mobile-grid-100 tablet-grid-100">
      {
        ecmContent.rows && ecmContent.rows.map((row, index) => (
          <EcmRow
            ecmContent={row}
            ecmSlot={ecmSlot}
            key={index}
            improvePerformanceOfStyleToggle={improvePerformanceOfStyleToggle}
            countryCode={countryCode}
          />
        ))
      }
    </div>
  );
};

export default EcmComplex;

/* eslint import/no-cycle: 0 */
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import getUrl from 'url';
import get from 'lodash/get';
import { concatTrackTagsObjects, getTrackingTags } from 'cms/components/utils';
import RichText from '../richText/richText';
import ImageAsset from '../image/imageAsset';
import IWTHelper from './imageWithTextHelper';
import './imageWithText.scss';

export function DumbImageWithText(props) {
  const contentItemsFields = get(props.cmsContentItem, 'fields', {});
  const contentIdImage = get(contentItemsFields.image, 'sys.id');
  const contentIdText = get(contentItemsFields.text, 'sys.id');
  const ownTrackTags = get(contentItemsFields, 'trackingTags', []);
  const propsTrackTags = get(props, 'trackTags', {});
  const trackTags = concatTrackTagsObjects(propsTrackTags, getTrackingTags(ownTrackTags));
  const linkTo = contentItemsFields.image?.fields?.linkTo;
  const { pageUrl, parentType = '', neverLazyload = false } = props;

  if (!contentItemsFields.image) {
    return null;
  }

  let isSelected = false;
  const isParentNewVisNav = parentType === 'NewVisualNav';
  if (
    isParentNewVisNav
    && typeof pageUrl === 'string'
    && typeof linkTo === 'string'
  ) {
    const pageUrlObj = getUrl.parse(pageUrl, true);
    const linkToObj = getUrl.parse(linkTo, true);
    if (
      pageUrlObj.pathname === linkToObj.pathname
      && pageUrlObj.query.filterOptions === linkToObj.query.filterOptions
    ) {
      isSelected = true;
    }
  }

  const iwtStyles = IWTHelper(contentItemsFields);
  const iwtClasses = 'imagewithtext'.concat(props.isMobilePhone
    ? iwtStyles.iwtClassesMobile
    : classNames(iwtStyles.iwtClassesDesktop, iwtStyles.iwtClassesTablet));
  const imageClasses = props.isMobilePhone
    ? iwtStyles.imageClassesMobile
    : classNames(iwtStyles.imageClassesDesktop, iwtStyles.imageClassesTablet);
  const textClasses = props.isMobilePhone
    ? iwtStyles.textClassesMobile // eslint-disable-next-line max-len
    : classNames(iwtStyles.textClassesDesktop, iwtStyles.textClassesTablet);
  return (
    <div className={iwtClasses}>
      <div className={imageClasses}>
        <ImageAsset
          trackTags={trackTags}
          cmsContentItem={contentItemsFields.image}
          key={contentIdImage}
          neverLazyload={neverLazyload}
        />
      </div>
      <div className={classNames(textClasses, 'iwt-text', { 'visual-nav': isParentNewVisNav }, { selected: isSelected })}>
        <RichText
          cmsContentItem={contentItemsFields.text}
          key={contentIdText}
          trackTags={trackTags}
        />
      </div>
      {// eslint-disable-next-line max-len
      props.isMobilePhone
        ? iwtStyles.mobileImageTextStyle()
        : iwtStyles.desktopTabletImageTextStyle()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
  pageUrl: get(state, 'session.url', null),
});

export default connect(mapStateToProps)(DumbImageWithText);

import axios from 'axios';

export const calculateShipping = (orderDetails = []) => {
  const itemsArr = orderDetails.reduce((items, order) => items.concat(order.items), []);
  return itemsArr.reduce((total, item) => total + (+item.freight * +item.quantity), 0).toFixed(2);
};

export const ORDER_STATUSES = [
  'In Process',
  'Shipped',
  'Delivered',
  'Backordered',
  'Returned',
  'Cancellation in Progress',
  'Cancelled',
  'Ships from store',
  'n/a',
  'In Store Item',
  'storeReturn',
  'storeCancel',
  'Return in Process',
  'Return Cancelled',
];

export const mapAddressToItem = (order) => {
  const newOrderDetails = order.orderDetails.map((addressGroupedItems) => {
    const addressDetails = addressGroupedItems.shippingAddress;
    return addressGroupedItems.items.map((item) => {
      return {
        ...item,
        shippingAddress: addressDetails,
      };
    });
  });

  return {
    ...order,
    orderDetails: {
      items: newOrderDetails.flat(1),
    },
  };
};

export const getThrottledFunction = (cb, milliseconds = 1000) => {
  let wasCalled = false;
  return (...args) => {
    if (!wasCalled) {
      wasCalled = true;
      cb(...args);
      setTimeout(() => {
        wasCalled = false;
      }, milliseconds);
    }
  };
};

export const formatSubChannel = (subChannel) => {
  switch (subChannel.toLowerCase()) {
    case 'connect':
      return 'Style Advisor';
    default: return subChannel;
  }
};

export const parseUrlParams = (queryString = '') => {
  const query = queryString.substring(1);
  const queryArgs = query.split('&');
  return queryArgs.reduce((acc, curr) => {
    const [key, value] = curr.split('=');
    acc[key] = value;
    return acc;
  }, {});
};

export const shortenName = (name, numChars) => {
  const numSpaces = name.split('').slice(0, numChars).filter((char) => char === ' ').length;
  const cutoff = numChars + numSpaces;
  if (name.length > cutoff) return `${name.substring(0, numChars + numSpaces - 3)}...`;
  return name;
};

export const convertToKebab = (string) => {
  if (typeof string !== 'string') return string;
  return string.split(' ').map((word) => word.toLowerCase()).join('-');
};

export const getReceiptHTML = (jwtToken, dynUserId, orderId) => {
  const headers = {
    Authorization: `Bearer ${jwtToken}`,
  };
  return axios.get(
    `${NMConfig.API_DT_ORDER_HISTORY}/${dynUserId}/orders/${orderId}/e-receipt`,
    { headers }
  );
};

export const normalizeString = (string, delim = ' ') => {
  if (string) {
    const stringArr = string.split(delim);
    return stringArr.map(
      (phrase) => phrase[0].toUpperCase() + phrase.substring(1).toLowerCase()
    ).join(delim);
  }
  return '';
};

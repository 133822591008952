import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import get from 'lodash/get';
import { shouldLoad } from 'universal/http-client';
import { isTypeAheadOn } from 'srp/utils/srpUtils';
import { getGenderWithOverride } from 'client-utils/utilities-gender';
import { ABTEST_EXPOSE_TOP_NAV } from 'client/common/actions/actions-page';
import NewMobileNav from './NewMobileNav/NewMobileNav';
import ConnectedNavBar from './NavBar/navBar';
import './navigation.scss';
import { getSilosDesktop, fetchSilosForNewMobileNav } from './actions-navigation';

export const fetchNavigation = ({
  isMobile,
  apiNavigation,
  apiNavigationMobile,
  fetchNav,
  fetchNavMobile,
}) => {
  if (shouldLoad(apiNavigation)) {
    fetchNav();
  }

  if (isMobile && shouldLoad(apiNavigationMobile)) {
    fetchNavMobile();
  }
};

const Navigation = ({
  apiNavigation,
  apiNavigationMobile,
  fetchNav,
  sliderMenuVisible,
  fetchNavMobile,
  router,
  isMobile,
  enableDynamicNavigation,
  hpGenderToggle,
  srpGenderToggle,
  pageId,
  typeAheadExperience,
  isMenDarkModeToggle,
  selectedGender,
  brand,
  genderCodeToggle,
  styleUpdateToggle,
  globalNavigationUpdate,
  mobileScrollMenu,
  centerTopNav,
  isDomestic,
  isFtcPage,
}) => {
  if (genderCodeToggle) {
    fetchNavigation({
      isMobile,
      apiNavigation,
      apiNavigationMobile,
      fetchNav,
      fetchNavMobile,
    });
  }

  if (!genderCodeToggle) {
    if (enableDynamicNavigation) {
      if (!isMobile && shouldLoad(apiNavigation)) {
        fetchNav();
      }

      if (isMobile && shouldLoad(apiNavigationMobile)) {
        fetchNavMobile();
      }
    } else {
      if (shouldLoad(apiNavigation)) {
        fetchNav();
      }

      if (isMobile && shouldLoad(apiNavigationMobile)) {
        fetchNavMobile();
      }
    }
  }

  const shouldShowGenderNav = () => {
    let genderToggle = false;
    const srpPage = 'PAGE_ID_SRP';
    if (pageId === srpPage) {
      genderToggle = srpGenderToggle;
    } else {
      genderToggle = hpGenderToggle;
    }

    return genderToggle;
  };

  const hideLineOnMobile = typeAheadExperience || mobileScrollMenu ? 'hide-on-mobile' : '';
  const horizontalLine = brand !== 'HC' ? <hr className={`hr-class ${hideLineOnMobile}`} /> : '';
  const connectedNavBar = <ConnectedNavBar router={router} />;
  const componentsOrder = shouldShowGenderNav()
    ? [connectedNavBar, horizontalLine] : [horizontalLine, connectedNavBar];
  const isMenGender = hpGenderToggle && selectedGender === 'M';

  return (
    <div
      className={classNames(
        'navigation',
        {
          'men-nav': isMenGender,
          'dark-mode': isMenDarkModeToggle && isMenGender,
          'global-nav-update': isDomestic && Boolean(globalNavigationUpdate) && !centerTopNav,
          'style-update-ftc': isFtcPage && brand === 'NM',
          'style-update': styleUpdateToggle && brand === 'NM' && isDomestic,
          'full-width': centerTopNav && brand === 'NM',
        }
      )}
      id={`${isMobile ? 'mobilenav' : null}`}
    >
      {
        componentsOrder.map((Component) => (
          Component))
      }
      {sliderMenuVisible
        ? ReactDOM.createPortal(<NewMobileNav router={router} brand={brand} />, document.getElementById('navSliderContainer'))
        : false}
    </div>
  );
};

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US';

  return {
    apiNavigation: state.api.navigation,
    apiNavigationMobile: state.api.navigation_mobile,
    sliderMenuVisible: state.navigation.navSlider.sliderMenuVisible,
    isMobile: state.device.isMobile,
    enableDynamicNavigation: state.toggles.ACN_DYNAMIC_NAVIGATION,
    hpGenderToggle: get(state, 'toggles.HP_GENDER', false) && isDomestic,
    srpGenderToggle: get(state, 'toggles.SRP_GENDER', false) && isDomestic,
    pageId: state.page.pageId,
    typeAheadExperience: isTypeAheadOn(state),
    isMenDarkModeToggle: get(state, 'toggles.MEN_DARKMODE', false),
    selectedGender: getGenderWithOverride(state),
    brand: state.brand_name.env,
    genderCodeToggle: get(state, 'toggles.PDP_GENDER_CODE', false),
    styleUpdateToggle: get(state, 'toggles.2024_REBRAND', false) && isDomestic && get(state, 'page.pageId', '') === 'PAGE_ID_HOME',
    globalNavigationUpdate: get(state, 'toggles.GLOBAL_NAV_UPDATE', false) && isDomestic,
    centerTopNav: get(state, 'toggles.TOP_NAV_CENTER', false) && isDomestic,
    mobileScrollMenu: (get(state, 'toggles.EXPOSE_TOP_NAV', false) || get(state, `abTestsOpt.${ABTEST_EXPOSE_TOP_NAV}.variation`, 'a') === 'b') && isDomestic && get(state, 'page.pageId', '') === 'PAGE_ID_HOME',
    isDomestic,
    isFtcPage: get(state, 'page.pageId', '') === 'FTC_PAGE',
  };
};

const mapDispatchToProps = ({
  fetchNav: getSilosDesktop,
  fetchNavMobile: fetchSilosForNewMobileNav,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export default function IWTHelper(contentItemsFields) {
  const desktopTextPosition = get(contentItemsFields, 'desktopTextPosition');
  const desktopTextVerticalAlignment = get(contentItemsFields, 'desktopTextVerticalAlignment', 'Middle');
  let desktopImageWidth = get(contentItemsFields, 'desktopImageWidth');
  desktopImageWidth = desktopTextPosition === 'Bottom' ? 100 : desktopImageWidth;
  const tabletTextPosition = isEmpty(get(contentItemsFields, 'tabletTextPosition')) ? desktopTextPosition : get(contentItemsFields, 'tabletTextPosition');
  const tabletTextVerticalAlignment = isEmpty(get(contentItemsFields, 'tabletTextVerticalAlignment')) ? desktopTextVerticalAlignment : get(contentItemsFields, 'tabletTextVerticalAlignment');
  let tabletImageWidth = isNaN(get(contentItemsFields, 'tabletImageWidth')) ? desktopImageWidth : get(contentItemsFields, 'tabletImageWidth');
  tabletImageWidth = tabletTextPosition === 'Bottom' ? 100 : tabletImageWidth;
  const mobileTextPosition = isEmpty(get(contentItemsFields, 'mobileTextPosition')) ? tabletTextPosition : get(contentItemsFields, 'mobileTextPosition');
  const mobileTextVerticalAlignment = isEmpty(get(contentItemsFields, 'mobileTextVerticalAlignment')) ? tabletTextVerticalAlignment : get(contentItemsFields, 'mobileTextVerticalAlignment');
  let mobileImageWidth = isNaN(get(contentItemsFields, 'mobileImageWidth')) ? tabletImageWidth : get(contentItemsFields, 'mobileImageWidth');
  mobileImageWidth = mobileTextPosition === 'Bottom' ? 100 : mobileImageWidth;

  function getImageClassName(textPosition, breakpoint) {
    let className = 'imagewithtext';
    if (textPosition === 'Right') {
      className += `__image-before${breakpoint}`;
    } else if (textPosition === 'Bottom') {
      className += `__image-top${breakpoint}`;
    } else if (textPosition === 'Left') {
      className += `__image-after${breakpoint}`;
    }
    return className;
  }

  function getTextClassName(textAlignment, breakpoint) {
    let className = 'imagewithtext';
    if (textAlignment === 'Top') {
      className += `__text-top${breakpoint}`;
    } else if (textAlignment === 'Middle') {
      className += `__text-middle${breakpoint}`;
    } else if (textAlignment === 'Bottom') {
      className += `__text-bottom${breakpoint}`;
    }
    return className;
  }

  function getImageWithTextClassName(textPosition, breakpoint) {
    let className = '';
    if (textPosition === 'Left') {
      className += ` image-right${breakpoint}`;
    } else if (textPosition === 'Bottom') {
      className += ` image-top${breakpoint}`;
    }
    return className;
  }

  const iwtClassesDesktop = getImageWithTextClassName(desktopTextPosition, '_desktop');
  const iwtClassesTablet = getImageWithTextClassName(tabletTextPosition, '_tablet');
  const iwtClassesMobile = getImageWithTextClassName(mobileTextPosition, '');

  const imageClassesDesktop = getImageClassName(desktopTextPosition, '_desktop');
  const imageClassesTablet = getImageClassName(tabletTextPosition, '_tablet');
  const imageClassesMobile = getImageClassName(mobileTextPosition, '');

  const textClassesDesktop = getTextClassName(desktopTextVerticalAlignment, '_desktop');
  const textClassesTablet = getTextClassName(tabletTextVerticalAlignment, '_tablet');
  const textClassesMobile = getTextClassName(mobileTextVerticalAlignment, '');

  function mobileImageTextStyle() {
    const style = `
          .${imageClassesMobile} {
              flex: ${mobileImageWidth}%;
              min-width: ${mobileImageWidth}%;
          }
          .${textClassesMobile} {
              flex: calc(100% - ${mobileImageWidth}%);
          }
      `;
    return (<style dangerouslySetInnerHTML={{ __html: style }} />);
  }

  function desktopTabletImageTextStyle() {
    const desktopTabletstyle = `@media screen and (max-width: 1023px) {
          .${imageClassesTablet} {
              flex: ${tabletImageWidth}%;
              min-width: ${tabletImageWidth}%;
          }
          .${textClassesTablet} {
              flex: calc(100% - ${tabletImageWidth}%);
          }
      }
      @media screen and (min-width: 1024px) {
          .${imageClassesDesktop} {
              flex: ${desktopImageWidth}%;
              min-width: ${desktopImageWidth}%;
          }
          .${textClassesDesktop} {
              flex: calc(100% - ${desktopImageWidth}%);
          }
      }`;
    return (<style dangerouslySetInnerHTML={{ __html: desktopTabletstyle }} />);
  }

  return ({
    iwtClassesDesktop,
    iwtClassesTablet,
    iwtClassesMobile,
    imageClassesDesktop,
    imageClassesTablet,
    imageClassesMobile,
    textClassesDesktop,
    textClassesTablet,
    textClassesMobile,
    mobileImageTextStyle,
    desktopTabletImageTextStyle,
  });
}

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookie';
import get from 'lodash/get';
import RenderContentItem from './renderContentItem';
import { submitAttribution } from './utils';

export const DumbRenderContent = ({ cmsContent = [], placement = 'Main' }) => {
  useEffect(() => {
    if (window.location.pathname === '/editorial/horchow-home-decor-design-styles') {
      document.title = 'Home Decor Design Styles at Horchow';
    }
    const queryParams = window.location.search.split('&');
    const cvId = queryParams.find((p) => p.indexOf('cv_id=') >= 0);
    if (cvId) {
      cookie.remove('assocInfo', { path: '/' });
      setTimeout(() => {
        submitAttribution(cvId.substring(cvId.indexOf('=') + 1));
      }, 3000);
    }
  }, []);

  return (
    <>
      {
        cmsContent.map((item, index) => (
          <RenderContentItem cmsContentItem={item} key={index} placement={placement} />
        ))
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  cmsContent: get(state, 'cms.entries'),
});

export default connect(mapStateToProps)(DumbRenderContent);

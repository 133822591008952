import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import { isMobile } from 'client-utils/utilities-page';
import { handleProtectedClick, logout } from '../../../utilities/amplifyUtils';
import '../Header/AccountHeaderMenuItem/AccountHeaderMenuItem.scss';

const defaultLiClassName = 'mobilenav__menuitem';

export const sanitizeUserName = (userName) => {
  const capitalizeString = (s) => (s[0].toUpperCase() + s.substr(1));
  const restrictToElevenChars = (s) => s.substr(0, 11);
  const removePlusSign = (s) => s.replace(/\+/g, ' ');
  return removePlusSign(capitalizeString(restrictToElevenChars(userName)));
};

const SignOutLink = ({
  usersFirstName,
  signOutClassName,
  cleanHeaderNav,
  disableATGLogin,
}) => {
  return (
    <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
      <div className="horizontal-rule" />
      <div className="loggedin-user-signout-link">
        <span
          tabIndex="0"
          className={signOutClassName}
          onClick={() => logout(disableATGLogin)}
        >
          {'Not '}
          {sanitizeUserName(usersFirstName)}
          {'? Sign Out'}
        </span>
      </div>
    </li>
  );
};

const mapState = (state, ownProps) => {
  const disableATGLogin = get(state, 'toggles.DISABLE_ATG_LOGIN', false);

  return {
    usersFirstName: ownProps.usersFirstName,
    disableATGLogin,
  };
};

const ConnectedSignOutLink = connect(mapState)(SignOutLink);

const OrderHistoryLink = ({
  orderHistoryClassName,
}) => (
  <a className={orderHistoryClassName} href="/order-history">
    Order History
  </a>
);

export const AccountMenuItems = ({
  authenticatedUserName,
  mobileClass,
  ulClass,
  shouldShowSpecialOffersLink,
  isDomestic,
  cleanHeaderNav = false,
}) => (
  <ul className={ulClass}>
    { !isMobile()
      && (
      <li
        className={classnames(
          { [defaultLiClassName]: cleanHeaderNav },
          { 'row-height-adjust': cleanHeaderNav }
        )}
      >
        <span className="account-menu-title">Your Account</span>
      </li>
      )
    }
    <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
      <span
        className={mobileClass}
        onClick={() => handleProtectedClick(`${NMConfig.ACCOUNT_PAGE_URL}?fromLN=myNMNav`)}
      >
        {'Overview'}
      </span>
    </li>
    <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
      <OrderHistoryLink
        authenticatedUserName={authenticatedUserName}
        orderHistoryClassName={mobileClass}
      />
    </li>
    <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
      <span
        className={mobileClass}
        onClick={() => handleProtectedClick(`${NMConfig.ACCOUNT_PAGE_URL}?view=address&fromLN=myNMNav`)}
      >
        {'Address Book'}
      </span>
    </li>
    <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
      <span
        className={mobileClass}
        onClick={() => handleProtectedClick(`${NMConfig.ACCOUNT_PAGE_URL}?view=pay&fromLN=myNMNav`)}
      >
        {'Payment Information'}
      </span>
    </li>
    {
      (shouldShowSpecialOffersLink && isDomestic)
        ? (
          <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
            <a className={mobileClass} href="/specialoffers">Special Offers</a>
          </li>
        )
        : null
    }
    <li className={classnames({ [defaultLiClassName]: cleanHeaderNav })}>
      <a
        className={mobileClass}
        href="/services/NMCreditCard"
      >
        {'NM Credit Card'}
      </a>
    </li>
    {
      authenticatedUserName
        ? (
          <ConnectedSignOutLink
            signOutClassName={mobileClass}
            usersFirstName={authenticatedUserName}
            cleanHeaderNav={cleanHeaderNav}
          />
        )
        : undefined
    }
  </ul>
);

import get from 'lodash/get';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import { ecmGetOverlayClasses, ecmGetTextOverlayStyles } from '../../utilities';

const EcmTextOverlays = ({ dataObj }) => {
  const overlayClasses = ecmGetOverlayClasses(dataObj);
  return (
    <div className="ecm-container__textoverimage__textoverlays">
      {
        get(dataObj, 'desktop', []).map((textOverlay, index) => {
          if (!textOverlay.map) return false;
          const overlayStyle = {
            top: `${textOverlay.map.top}%`,
            left: `${textOverlay.map.left}%`,
            width: `${textOverlay.map.width}%`,
          };
          return (
            <Fragment key={index}>
              <div
                className={
                  classNames(textOverlay.componentId,
                    overlayClasses.desktopOverlayClasses)
                }
                key={index}
                style={overlayStyle}
                dangerouslySetInnerHTML={{ __html: textOverlay.text }}
              />
              {ecmGetTextOverlayStyles(textOverlay)}
            </Fragment>
          );
        })
      }
      {
        get(dataObj, 'tablet', []).map((textOverlay, index) => {
          if (!textOverlay.map) return false;
          const overlayStyle = {
            top: `${textOverlay.map.top}%`,
            left: `${textOverlay.map.left}%`,
            width: `${textOverlay.map.width}%`,
          };
          return (
            <Fragment key={index}>
              <div
                className={
                  classNames(textOverlay.componentId,
                    overlayClasses.tabletOverlayClasses)
                }
                key={index}
                style={overlayStyle}
                dangerouslySetInnerHTML={{ __html: textOverlay.text }}
              />
              {ecmGetTextOverlayStyles(textOverlay)}
            </Fragment>
          );
        })
      }
      {
        get(dataObj, 'mobile', []).map((textOverlay, index) => {
          if (!textOverlay.map) return false;
          const overlayStyle = {
            top: `${textOverlay.map.top}%`,
            left: `${textOverlay.map.left}%`,
            width: `${textOverlay.map.width}%`,
          };
          return (
            <Fragment key={index}>
              <div
                className={
                  classNames(textOverlay.componentId,
                    overlayClasses.mobileOverlayClasses)
                }
                key={index}
                style={overlayStyle}
                dangerouslySetInnerHTML={{ __html: textOverlay.text }}
              />
              {ecmGetTextOverlayStyles(textOverlay)}
            </Fragment>
          );
        })
      }
    </div>
  );
};

export default EcmTextOverlays;

export function getPercentage(number, total) {
  return Math.abs(number) / total * 100;
}

export function getScaleNumber(percentage) {
  const MAX_ZOOM_PERCENTAGE = 26;
  // eslint-disable-next-line compat/compat
  return Math.trunc(MAX_ZOOM_PERCENTAGE / 100 * percentage).toString().padStart(2, '0');
}

export function getTranslateYNumber(percentage, maxShift) {
  // eslint-disable-next-line compat/compat
  return Math.trunc(maxShift / 100 * percentage);
}

import isEmpty from 'lodash/isEmpty';
import React from 'react';

const JSONToCSSPropertyMap = {
  hoverBorderSize: 'border-width',
  hoverStateButtonColor: 'background-color',
  hoverStateBorderColor: 'border-color',
  hoverStateTextColor: 'color',
};

export const getCustomStylesForCTADesktop = (desktopStyles) => {
  if (isEmpty(desktopStyles)) {
    return null;
  }
  let individualStyles = '';
  desktopStyles.forEach((object) => {
    individualStyles += ` .${object.componentId}:hover{
      ${JSONToCSSPropertyMap.hoverBorderSize}: ${object.hoverBorderSize}px !important;
      ${JSONToCSSPropertyMap.hoverStateBorderColor}: ${object.hoverStateBorderColor} !important;
      ${JSONToCSSPropertyMap.hoverStateButtonColor}: ${object.hoverStateButtonColor} !important;
      ${JSONToCSSPropertyMap.hoverStateTextColor}: ${object.hoverStateTextColor} !important;
      cursor: pointer;
    }
    `;
  });

  const styles = `
    ${individualStyles}
  `;
  return (
    <style dangerouslySetInnerHTML={{ __html: styles }} />
  );
};

export const getCustomStylesForCTATablet = (tabletStyles) => {
  if (isEmpty(tabletStyles)) {
    return null;
  }
  let individualStyles = '';
  tabletStyles.forEach((object) => {
    individualStyles += ` .${object.componentId}:hover{
      ${JSONToCSSPropertyMap.hoverBorderSize}: ${object.hoverBorderSize}px !important;
      ${JSONToCSSPropertyMap.hoverStateBorderColor}: ${object.hoverStateBorderColor} !important;
      ${JSONToCSSPropertyMap.hoverStateButtonColor}: ${object.hoverStateButtonColor} !important;
      ${JSONToCSSPropertyMap.hoverStateTextColor}: ${object.hoverStateTextColor} !important;
      cursor: pointer;
    }
    `;
  });

  const styles = `@media screen and (max-width: 1024px) {
    ${individualStyles}
  }`;

  return (
    <style dangerouslySetInnerHTML={{ __html: styles }} />
  );
};

export const getCustomStylesForCTAMobile = (mobileStyles) => {
  if (isEmpty(mobileStyles)) {
    return null;
  }
  let individualStyles = '';
  mobileStyles.forEach((object) => {
    individualStyles += ` .${object.componentId}:hover{
      ${JSONToCSSPropertyMap.hoverBorderSize}: ${object.hoverBorderSize}px !important;
      ${JSONToCSSPropertyMap.hoverStateBorderColor}: ${object.hoverStateBorderColor} !important;
      ${JSONToCSSPropertyMap.hoverStateButtonColor}: ${object.hoverStateButtonColor} !important;
      ${JSONToCSSPropertyMap.hoverStateTextColor}: ${object.hoverStateTextColor} !important;
      cursor: pointer;
    }
    `;
  });

  const styles = `@media screen and (max-width: 767px) {
    ${individualStyles}
  }`;

  return (
    <style dangerouslySetInnerHTML={{ __html: styles }} />
  );
};

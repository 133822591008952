export default function buildArrayOfCategoriesByColumnBreakFlg(categories) {
  return categories.reduce((columns, category) => {
    if (category.attributes && category.attributes.flgColumnBreak) {
      // Create a new column for this category
      columns.push([]);
    }

    // Add category to last column
    columns[columns.length - 1].push(category);

    return columns;
  }, [[]]).filter((col) => col.length > 0);
}

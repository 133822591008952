import React from 'react';
import './Overlap.scss';
// eslint-disable-next-line import/no-cycle
import RenderContentItem from 'cms/components/renderContentItem';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  detectMobilePosition,
  FOREGROUND_COMPONENTS,
  getMarginValue,
} from 'cms/components/Overlap/utils';

function Overlap(props) {
  const {
    position,
    cmsContentAssets,
    isMobilePhone,
    borderColor,
    borderSize,
    verticalOverlapPercent: vp,
    horizontalOverlapPercent: hp,
  } = props;
  const [backgroundItem, foregroundItem] = [
    cmsContentAssets[0],
    cmsContentAssets[1],
  ];

  const fgItemClass = FOREGROUND_COMPONENTS[foregroundItem.contentType] ?? 'img-overlap';
  const calculatedPosition = isMobilePhone ? detectMobilePosition(position) : position;
  const getMargin = getMarginValue(calculatedPosition, vp, hp, isMobilePhone);
  const styles = {
    borderColor: borderColor || '#fff',
    borderWidth: borderSize ? `${borderSize}px` : '10px',
    backgroundColor: borderColor || '',
    ...getMargin,
  };

  return (
    <div
      className={`overlap-wrapper ${fgItemClass} ${calculatedPosition}`}
    >
      <div className="bg-item">
        <RenderContentItem cmsContentItem={backgroundItem} neverLazyload />
      </div>
      <div className="fg-item" style={styles}>
        <RenderContentItem cmsContentItem={foregroundItem} neverLazyload />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isMobilePhone: get(state, 'device.isMobilePhone'),
});

export default connect(mapStateToProps)(Overlap);

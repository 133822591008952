import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import { loseFocus, putComponentInFocus } from 'client/common/components/App/app-actions';
import arrowDown from 'assets/images/arrow-down.svg';
import { ESC_KEYCODE, ENTER_KEYCODE } from 'client-utils/keyCodes';
import { AccountMenuItems, sanitizeUserName } from 'storefront/components/AccountMenuItems/AccountMenuItems';
import { handleProtectedClick } from '../../../../utilities/amplifyUtils';

import './AccountHeaderMenuItem.scss';

const accountDropdownClasses = (isAuthenticated) => classnames(
  'desktop-tablet-only', {
    'loggedIn-user-account-menu-item': isAuthenticated,
    'anonymous-user-account-menu-item': !isAuthenticated,
  }
);

const onFocusOut = (toggleMenuOff) => (e) => {
  if (e.currentTarget !== e.relatedTarget.offsetParent) {
    toggleMenuOff('.account-header-menu-item');
  }
};

const AccountDropdownMenu = ({
  authenticatedUserName,
  toggleMenuOff,
  shouldShowSpecialOffersLink,
  isDomestic,
}) => (
  <div
    className={accountDropdownClasses(authenticatedUserName)}
    onBlur={onFocusOut(toggleMenuOff)}
  >
    <AccountMenuItems
      authenticatedUserName={authenticatedUserName}
      shouldShowSpecialOffersLink={shouldShowSpecialOffersLink}
      isDomestic={isDomestic}
    />
  </div>
);

const onAccountMenuKeyDown = (e, { toggleMenuOff, toggleMenuOn, isVisible }) => {
  if (isVisible && e.which !== ESC_KEYCODE) return;
  if (e.which === ESC_KEYCODE) {
    toggleMenuOff('.account-header-menu-item');
  } else if (e.which === ENTER_KEYCODE) {
    toggleMenuOn('.account-header-menu-item');
    e.preventDefault();
  }
};

const SignInRegister = (props) => (
  <div
    className="account-header-menu-item"
    onKeyDown={(e) => onAccountMenuKeyDown(e, props)}
  >
    <SignInRegisterDesktop {...props} />
    <SignInRegisterForDevice />
  </div>
);

const SignInRegisterDesktop = ({
  isVisible,
  toggleMenuOn,
  toggleMenuOff,
  shouldShowSpecialOffersLink,
  isDomestic,
}) => (
  <div
    className="desktop-only"
    onMouseEnter={() => { toggleMenuOn('.account-header-menu-item'); }}
    onMouseLeave={() => { toggleMenuOff('.account-header-menu-item'); }}
  >
    <a className="account-header-menu-item__signin-register-link" href="/login">
      Sign In / Register
      <span className="account-header-menu-item__down-arrow-container account-header-menu-item__down-arrow-container--anonymous">
        <img src={String(arrowDown)} className="account-header-menu-item__down-arrow" alt="" />
      </span>
    </a>
    {
      isVisible
        ? (
          <AccountDropdownMenu
            authenticatedUserName={false}
            toggleMenuOff={toggleMenuOff}
            shouldShowSpecialOffersLink={shouldShowSpecialOffersLink}
            isDomestic={isDomestic}
          />
        )
        : undefined
    }
  </div>
);

const SignInRegisterForDevice = () => (
  <div className="device-only">
    <a className="account-header-menu-item__signin-register-link desktop-tablet-only" href="/login">
      {'Sign In / Register'}
    </a>
    <a className="account-header-menu-item__signin-register-link mobile-only" href="/login">
      {'Sign In '}
      <br />
      {' /Register'}
    </a>
  </div>
);

const YourAccount = ({
  firstName,
  isVisible,
  toggleMenuOn,
  toggleMenuOff,
  shouldShowSpecialOffersLink,
  isDomestic,
}) => {
  const usersFirstName = sanitizeUserName(firstName);
  const touchToggle = isVisible
    ? () => toggleMenuOff('.account-header-menu-item')
    : () => toggleMenuOn('.account-header-menu-item');
  return (
    <div
      onMouseLeave={() => { toggleMenuOff('.account-header-menu-item'); }}
      className="account-header-menu-item"
    >
      <span
        onTouchStart={() => {
          toggleMenuOff('.account-header-menu-item');
          document.getElementsByClassName('account-header-menu-item__loggedin-user-link')[0].click();
        }}
        className="account-header-menu-item__loggedin-user-link"
        onClick={() => handleProtectedClick(NMConfig.ACCOUNT_PAGE_URL)}
      >
        {'Hello, '}
        {usersFirstName}
      </span>
      <button
        onKeyDown={(e) => onAccountMenuKeyDown(e, { isVisible, toggleMenuOn, toggleMenuOff })}
        onMouseEnter={() => { toggleMenuOn('.account-header-menu-item'); }}
        onTouchEnd={touchToggle}
        className="account-header-menu-item__your-account button-transparent-bg"
      >
        {'Your Account'}
        <span className="account-header-menu-item__down-arrow-container">
          <img src={String(arrowDown)} className="account-header-menu-item__down-arrow" alt="" />
        </span>
      </button>
      {
        isVisible
          ? (
            <AccountDropdownMenu
              authenticatedUserName={usersFirstName}
              toggleMenuOff={toggleMenuOff}
              shouldShowSpecialOffersLink={shouldShowSpecialOffersLink}
              isDomestic={isDomestic}
            />
          )
          : undefined
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  const isDomestic = get(state, 'locale.countryCode') === 'US'
    || get(state, 'locale.countryCode') === 'Unites_States';
  return {
    isDomestic,
    shouldShowSpecialOffersLink: get(state, 'toggles.WKY_LINK_TO_SPECIAL_OFFERS_PAGE', false),
    isVisible: (state.app.componentInFocus === '.account-header-menu-item'),
  };
};

const mapDispatchToProps = ({
  toggleMenuOn: putComponentInFocus,
  toggleMenuOff: loseFocus,
});

export const ConnectedSignInRegister = connect(
  mapStateToProps, mapDispatchToProps
)(SignInRegister);
export const ConnectedYourAccount = connect(
  mapStateToProps, mapDispatchToProps
)(YourAccount);

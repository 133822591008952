import React from 'react';
import classnames from 'classnames';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import { renderFormattedPriceWithDecimals } from 'client/services/currency';
import { unescape } from 'he';
import './priceAdornment.scss';

const PriceAdornment = ({
  currentPrice,
  label,
  price,
  color,
  currencyCode,
  showPriceInDecimals = false,
  showAdornLabel = true,
  hasPromoPrice = false,
  isPLP = false,
  plpSRPPricingToggle = false,
  nonStrikeplpSRPPricingToggle = false,
  isUIPRICTest,
}) => (
  <span
    className={classnames(
      'adornment',
      { currentPrice },
      { hasPromoPrice: currentPrice && hasPromoPrice },
      { 'price-enhancement': (isUIPRICTest || plpSRPPricingToggle) && !currentPrice },
    )}
    style={{ color }}
  >
    {label && label.length > 0 && showAdornLabel && (
      <span className="label">{`${unescape(label)} `}</span>
    )}
    {
      isPLP
        ? (
          <span className={classnames('price', { 'new-pricing': isUIPRICTest || plpSRPPricingToggle },
            { 'new-pricing': (isUIPRICTest || nonStrikeplpSRPPricingToggle) && currentPrice })}
          >
            {price}
          </span>
        )
        : (
          <span className={classnames('price', { 'new-pricing': (isUIPRICTest || nonStrikeplpSRPPricingToggle) && currentPrice })}>
            {showPriceInDecimals
              ? renderFormattedPriceWithDecimals(price, currencyCode)
              : renderFormattedPrice(price, currencyCode)}
          </span>
        )
    }
  </span>
);

export default PriceAdornment;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { ProductPanel } from '@nmg/nmo-ui-productpanel';
import '@nmg/nmo-ui-productpanel/build/index.css';
import { closeProductPanel, toggleProductPanelFavorite } from 'cms/actions';
import { addToBag } from 'client/pdp/pages/ProductPage/actions';
import axios from 'axios';
import { getRequestParams } from 'profile/components/MyFavorites/utilities';
import Auth from '@aws-amplify/auth';
import { splitProducts } from 'cms/components/ProductPanel/productPanelUtils';
import { useProductPanel } from './useProductPanel';

const ProductPanelWrapper = (props) => {
  const {
    isProductPanelOpen,
    productPanelData,
    productPanelYMALData,
    productPanelError,
    isLoading,
    numberOfProducts,
    numberOfProductsYMAL,
    device,
    cloudinaryDprToggle,
    isServeProperSizedScene7PDP,
    onlyXLeftThreshold,
    vendorQtyRestrictions,
    // productState,
    dispatch,
    storeNumber,
    title,
  } = props;

  const {
    outfittingProducts,
    setOutfittingProducts,
    updateOutfittingSize,
    updateOutfittingColor,
    updateOutfittingBuckleFinish,
    updateOutfittingHoveredColor,
    updateOutfittingFavorite,
    updateAddToBagError,
  } = useProductPanel();

  const isGuest = useRef(true);
  useEffect(() => {
    setOutfittingProducts([...productPanelData, ...productPanelYMALData]);
  }, [productPanelData, productPanelYMALData, setOutfittingProducts]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      // eslint-disable-next-line no-return-assign
      .then(() => {
        isGuest.current = false;
      })
      .catch((err) => err);
  }, []);

  const handleClose = () => {
    dispatch(closeProductPanel());
  };

  const handleLooksProductOptionsChange = (index, type, id) => {
    if (type === 'size') {
      updateOutfittingSize(id, index);
    } else if (type === 'color') {
      updateOutfittingColor(id, index);
    } else if (type === 'buckle finish') {
      updateOutfittingBuckleFinish(id, index);
    }
  };

  const handleLooksProductOptionsHover = (index, type, id) => {
    if (type === 'color') {
      updateOutfittingHoveredColor(id, index);
    }
  };

  const handleAddToBagError = (error, productId) => {
    updateAddToBagError(productId, error);
  };

  const handleClearError = (productId) => {
    updateAddToBagError(productId, '');
  };

  const toggleFavorite = async (productId, isFavorite, isGuest) => {
    const { id: userID, headers } = await getRequestParams(isGuest);
    const url = `${NMConfig.FAVORITE_ITEMS}/${isGuest ? 'guests' : 'accounts'}/${userID}/products`;

    if (isFavorite) {
      await axios.delete(`${url}/${productId}`, { headers });
    } else {
      await axios.post(url, { value: productId }, { headers });
      if (isGuest) {
        localStorage?.setItem(`${userID}.favoritesAdded`, 'true');
      }
    }
    return !isFavorite;
  };

  const handleToggleFavorite = async (product, isFavorite) => {
    const { id } = product;
    handleClearError(id);
    try {
      const nextIsFavorite = await toggleFavorite(id, isFavorite, isGuest.current);
      updateOutfittingFavorite(id, nextIsFavorite);
      dispatch(toggleProductPanelFavorite(product, nextIsFavorite));
    } catch (e) {
      updateAddToBagError(id, 'Sorry, we had a problem. Please refresh and try again.');
    }
  };

  const handleAddToBag = (product, selectedSku, skuMediaUrl, storeNumber) => {
    dispatch(addToBag(
      product,
      selectedSku,
      skuMediaUrl,
      storeNumber,
      (error) => updateAddToBagError(product?.id, error),
      true
    ));
  };

  const [products, productsYMAL] = splitProducts(
    outfittingProducts,
    productPanelData,
    productPanelYMALData
  );

  return (
    <ProductPanel
      title={title}
      isOpen={isProductPanelOpen}
      handleClose={handleClose}
      products={products}
      productsYMAL={productsYMAL}
      numberOfProducts={numberOfProducts}
      numberOfProductsYMAL={numberOfProductsYMAL}
      loading={isLoading}
      errorMessage={productPanelError}
      device={device}
      storeNumber={storeNumber}
      isServeProperSizedScene7PDP={isServeProperSizedScene7PDP}
      cloudinaryDprToggle={cloudinaryDprToggle}
      vendorQtyRestrictions={vendorQtyRestrictions}
      onlyXLeftThreshold={onlyXLeftThreshold}
      toggleFavorite={handleToggleFavorite}
      addToBag={handleAddToBag}
      showAddToBagError={handleAddToBagError}
      clearError={handleClearError}
      productOptionsOnHover={handleLooksProductOptionsHover}
      productOptionsOnClick={handleLooksProductOptionsChange}
    />
  );
};

ProductPanelWrapper.propTypes = {
  device: PropTypes.object,
  isProductPanelOpen: PropTypes.bool,
  productPanelData: PropTypes.arrayOf(PropTypes.object),
  productPanelYMALData: PropTypes.arrayOf(PropTypes.object),
  productPanelError: PropTypes.string,
  isLoading: PropTypes.bool,
  numberOfProducts: PropTypes.number,
  numberOfProductsYMAL: PropTypes.number,
  cloudinaryDprToggle: PropTypes.bool,
  isServeProperSizedScene7PDP: PropTypes.bool,
  onlyXLeftThreshold: PropTypes.number,
  vendorQtyRestrictions: PropTypes.shape({}),
  storeNumber: PropTypes.string,
};

ProductPanelWrapper.defaultProps = {
  isProductPanelOpen: false,
  productPanelData: [],
  productPanelYMALData: [],
  productPanelError: '',
  isLoading: false,
  numberOfProducts: 0,
  numberOfProductsYMAL: 0,
  device: {},
  cloudinaryDprToggle: false,
  isServeProperSizedScene7PDP: false,
  onlyXLeftThreshold: 2,
  vendorQtyRestrictions: {},
  storeNumber: '',
};

const mapStateToProps = (state) => {
  return {
    isProductPanelOpen: get(state, 'productPanel.open'),
    productPanelData: get(state, 'productPanel.products'),
    productPanelYMALData: get(state, 'productPanel.productsYMAL'),
    productPanelError: get(state, 'productPanel.error'),
    isLoading: get(state, 'productPanel.loading'),
    numberOfProducts: get(state, 'productPanel.numberOfProducts'),
    numberOfProductsYMAL: get(state, 'productPanel.numberOfProductsYMAL'),
    styledForYouPanel: get(state, 'productPanel.styledForYouCard'),
    storeNumber: get(state, 'productCatalog.productPickup.storeNumber', ''),
    device: state.device,
    cloudinaryDprToggle: get(state, 'toggles.CLOUDINARY_DPR', false),
    isServeProperSizedScene7PDP: get(
      state,
      'toggles.SERVE_PROPER_SIZED_SCENE7_PDP',
      false
    ),
    onlyXLeftThreshold: get(state, 'onlyXLeftThresholds.defaultThreshold', 2),
    vendorQtyRestrictions: get(
      state,
      'productCatalog.vendorQtyRestrictions',
      {}
    ),
    session: state.session,
  };
};

export default connect(mapStateToProps)(ProductPanelWrapper);
